import { List, Box } from '@mui/material';
import { Drawer } from '@dock/react';
import { DockColor } from '@dock/common';
import { SidebarSupportBox } from './SidebarSupportBox';
import { SidebarItems } from './SidebarItems';
import { NavbarRoutes } from './types';
import { SidebarLogo } from './SidebarLogo';
import { ExpandButton } from './ExpandButton';

type SidebarTypes = {
    isOpen: boolean;
    toggleDrawer: () => void;
    items: NavbarRoutes[];
};

export function Sidebar({ isOpen, toggleDrawer, items }: SidebarTypes) {
    return (
        <Drawer
            sx={{
                '.MuiDrawer-paper': {
                    backgroundColor: DockColor.PRIMARY_1100,
                    width: !isOpen ? '110px' : '280px',
                    position: 'initial',
                },
                position: 'relative',
            }}
            variant="permanent"
            open={isOpen}
        >
            <Box
                sx={{
                    padding: isOpen ? '24px 18px' : '24px 8px',
                }}
            >
                <SidebarLogo isOpen={isOpen} />
                <List
                    sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        [theme.breakpoints.up('xl')]: {
                            gap: '8px',
                        },
                        [theme.breakpoints.up('xxl')]: {
                            gap: '16px',
                        },
                    })}
                    component="nav"
                >
                    <SidebarItems isNavbarOpen={isOpen} items={items} />
                </List>
                <ExpandButton isOpen={isOpen} toggleDrawer={toggleDrawer} />
            </Box>
            {isOpen && <SidebarSupportBox />}
        </Drawer>
    );
}
