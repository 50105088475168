import { Column, OptionType, MultipleChoiceSearch, SimpleTable } from '@dock/react';
import {
    BankTransferInstrumentsSearchFields,
    useInstrumentsTableFilters,
} from './useInstrumentsTableFilters';
import { useBankTransferInstruments } from '../../../../services';
import { InstrumentsTableRow } from './InstrumentsTableRow';
import { transformBankTransferInstrumentsParams } from './transformBankTransferInstrumentsParams';
import bankTransfers from '../../../../lang/bankTransfers';
import commonLang from '../../../../lang/common';
import { TableFiltersWrapper } from '../../../../common';

export const instrumentsTableColumns: Column[] = [
    { key: 'bankTransferId', name: bankTransfers.BANK_TRANSFER_ID },
    {
        key: 'bankTransferHolderId',
        name: bankTransfers.BANK_TRANSFER_HOLDER_ID,
    },
    { key: 'bic', name: bankTransfers.BIC },
    { key: 'iban', name: bankTransfers.IBAN },
    { key: 'createdTime', name: bankTransfers.CREATED_TIME, sortable: true },
    { key: 'actions', name: '' },
];

const searchOptions: OptionType<BankTransferInstrumentsSearchFields>[] = [
    {
        label: bankTransfers.BANK_TRANSFER_ID,
        value: BankTransferInstrumentsSearchFields.ID,
    },
    {
        label: bankTransfers.ACCOUNT_ID,
        value: BankTransferInstrumentsSearchFields.ACCOUNT_ID,
    },
    {
        label: bankTransfers.IBAN,
        value: BankTransferInstrumentsSearchFields.IBAN,
    },
];

export function InstrumentsTable() {
    const { filters, handleSearch, handleSearchSelect, sorting, handleSortOrder } =
        useInstrumentsTableFilters();

    const { data, error, fetchNextPage, isFetching, isFetchingNextPage } =
        useBankTransferInstruments({
            params: transformBankTransferInstrumentsParams(filters),
        });

    return (
        <>
            <TableFiltersWrapper>
                <MultipleChoiceSearch
                    label={commonLang.SEARCH}
                    initValue={filters.text || ''}
                    handleSearch={handleSearch}
                    onChange={handleSearchSelect}
                    searchBy={filters.searchBy || BankTransferInstrumentsSearchFields.ID}
                    options={searchOptions}
                />
            </TableFiltersWrapper>

            <SimpleTable
                columns={instrumentsTableColumns}
                RowComponent={InstrumentsTableRow}
                isLoading={isFetching}
                rows={data}
                isLoadingNextPage={isFetchingNextPage}
                onReachingBottom={fetchNextPage}
                error={error}
                sorting={sorting}
                onSortClick={handleSortOrder}
            />
        </>
    );
}
