export const transactionDetailColumns = {
    id: 'Transaction ID',
    kind: 'Kind',
    amount: 'Amount',
    datetimes: 'Time created',
    externalReference: 'External reference',
    paymentId: 'Payment ID',
    description: 'Description',
};

export const transactionCounterPartyColumns = {
    accountPartyName: 'Account label',
    accountCounterPartyName: 'Account label',
    accountId: 'Account ID',
    instrumentId: 'Instrument ID',
};
