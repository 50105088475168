import { CardAuthorizationsTable } from './components/CardAuthorizations/CardAuthorizationsTable';
import { CardDetails } from './CardDetails';

export function CardDetailsAuthorizationsTable() {
    return (
        <CardDetails>
            <CardAuthorizationsTable />
        </CardDetails>
    );
}
