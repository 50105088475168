import {
    FilterReturnTypes,
    BaseFilterParams,
    getBasicEmptyFilters,
    useFilters,
    getTypedParamValue,
} from '../../../../common';

export type A2ATransactionFiltersURLParams = BaseFilterParams<A2ATransactionSearchFields>;

export enum A2ATransactionSearchFields {
    paymentId = 'paymentId',
    recipientId = 'recipientId',
    externalReference = 'externalReference',
}

export type SchedulePaymentPopUpFilterParams = Partial<
    Pick<A2ATransactionFiltersURLParams, 'minAmount' | 'maxAmount'>
>;

export const getEmptyFilters = (params: URLSearchParams): A2ATransactionFiltersURLParams => ({
    ...getBasicEmptyFilters({ params }),
    searchBy: getTypedParamValue(
        'searchBy',
        params,
        A2ATransactionSearchFields,
        A2ATransactionSearchFields.paymentId
    ),
});

type UseA2ATransferTxsFilter = FilterReturnTypes<
    A2ATransactionFiltersURLParams,
    SchedulePaymentPopUpFilterParams,
    A2ATransactionSearchFields
>;

const customFieldsExtractor = (x: A2ATransactionFiltersURLParams) => x;

export const useA2ASchedulePaymentFilters = (): UseA2ATransferTxsFilter =>
    useFilters(getEmptyFilters, customFieldsExtractor);
