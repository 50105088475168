import { A2ADetails } from './A2ADetails';
import { TransactionsScheduleTable } from './components/SchedulePaymentTable';

export function A2APaymentScheduleTransactionsTable() {
    return (
        <A2ADetails>
            <TransactionsScheduleTable />
        </A2ADetails>
    );
}
