import type { BankTransferBankTransferInstrument } from '@dock/types-dock-partner';
import { flow } from 'fp-ts/lib/function';
import { getData, RequestAndUnwrap, requestFlow, unwrapService } from '../../../../utilities';
import { handleErrors } from '../../../../errorService';

export const createBankTransferHandler = requestFlow(handleErrors)(`v0/bank_transfers`);

const fetchAndHandle = createBankTransferHandler<BankTransferBankTransferInstrument>(getData);

/**
 * @deprecated
 */
export const createBankTransferInstrument: RequestAndUnwrap<
    'post',
    BankTransferBankTransferInstrument
> = flow(fetchAndHandle, unwrapService<BankTransferBankTransferInstrument>);
