import { GenericServiceError } from '@dock/common';
import { getCardDetails } from '../../client';

export const fetchCardDetails = async (cardId: string) => {
    const cardDetailsOrError = await getCardDetails(cardId);

    if (cardDetailsOrError instanceof GenericServiceError) {
        throw cardDetailsOrError;
    }

    return cardDetailsOrError;
};
