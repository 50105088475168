import { KeysToSnakeCase } from '@dock/mock-server';
import { CreatePaymentData } from '@dock/types-dock-partner';
import commonLang from '../../../../../lang/common';

export const responseFieldsMap: Record<keyof KeysToSnakeCase<CreatePaymentData>, string> = {
    amount: commonLang.AMOUNT,
    description: commonLang.PRODUCT_ID,
    external_reference: commonLang.EXTERNAL_REFERENCE,
    recipient_id: commonLang.RECIPIENT_ID,
    sender_id: commonLang.SENDER_ID,
    id: commonLang.ID,
    datetimes: commonLang.DATETIMES,
    notes: commonLang.NOTES,
};
