import { useCallback } from 'react';
import type { IdempotencyKey } from '@dock/types-common';
import { Currency } from '@dock/types-common';
import { useParams } from 'react-router-dom';
import { Link } from '@mui/material';
import { A2ATransfersCreatePayment } from '@dock/types-dock-partner';
import { ActionModalWrapper } from '@dock/react';
import { usePayA2A } from '../../../../../services';
import { stepsConfig } from './stepsConfig';
import a2aDetailsLang from '../../../../../lang/a2aDetails';
import { BasicDetailsStep } from '../Form/BasicDetailsStep';
import {
    ExternalReferenceStep,
    SuccessStatusMessage,
    useCreateModal,
    useStatusModals,
    A2A_DETAILS_SCHEDULE_PAYMENTS_DETAILS_ROUTE,
    generateErrorText,
    generateIdempotencyKey,
    interpolateParams,
} from '../../../../../common';
import { responseFieldsMap } from './responseFieldsMap';

type CreateAccountModalProps = {
    handleClose: () => void;
    isOpen: boolean;
    setOpenFormModal: () => void;
};

type PayA2AFormType = A2ATransfersCreatePayment & IdempotencyKey;

export function PayA2ATransferModal({
    isOpen,
    handleClose,
    setOpenFormModal,
}: CreateAccountModalProps) {
    const defaultValues: PayA2AFormType = {
        amount: {
            amount: '',
            currency: '',
        },
        description: '',
        externalReference: '',
        recipientId: '',
        idempotencyKey: generateIdempotencyKey(),
    };
    const { showSuccessModal, showErrorModal } = useStatusModals();
    const { id: instrumentId = '' } = useParams();
    const { mutateAsync, isLoading } = usePayA2A(instrumentId);

    const {
        formValues,
        handleForm,
        stepper,
        handleErrorResponse,
        handleCloseModal,
        handleChangeData,
    } = useCreateModal<PayA2AFormType>({
        defaultValues,
        closeFormModal: handleClose,
        openFormModal: setOpenFormModal,
    });

    const { activeStep, handleBack, handleNext } = stepper;

    const { externalReference, amount, recipientId, description, idempotencyKey } = formValues;

    const handleChangeDataClick = () =>
        handleChangeData({ idempotencyKey: generateIdempotencyKey() });

    const handleFormSubmit = async (extReference: string) => {
        try {
            const response = await mutateAsync({
                payload: {
                    amount,
                    recipientId,
                    externalReference: extReference,
                    description: description || null,
                },
                idempotencyKey,
            });

            handleCloseModal();
            const href = interpolateParams(A2A_DETAILS_SCHEDULE_PAYMENTS_DETAILS_ROUTE, {
                id: instrumentId,
                transactionId: response.id,
            });
            showSuccessModal({
                messages: [
                    <SuccessStatusMessage
                        preLabel={a2aDetailsLang.PAY.SUCCESS_PRE}
                        postLabel={a2aDetailsLang.PAY.SUCCESS_POST}
                    >
                        <Link href={href}>{response.id}</Link>
                    </SuccessStatusMessage>,
                ],
            });
        } catch (e) {
            handleErrorResponse();

            showErrorModal({
                messages: [a2aDetailsLang.PAY.FAILED, generateErrorText(e, responseFieldsMap)],
                onChangeClick: handleChangeDataClick,
                onSubmitClick: () => handleFormSubmit(externalReference || ''),
            });
        }
    };

    const handleOnSubmitClick = async (value: string) => {
        handleForm({ externalReference: value });
        await handleFormSubmit(value);
    };

    const renderCorrectStep = useCallback(() => {
        if (activeStep === 0) {
            return (
                <BasicDetailsStep
                    handleNextStep={handleNext}
                    handleForm={handleForm}
                    defaultValues={{
                        amount: amount.amount,
                        currency: Currency.EUR,
                        recipientId,
                    }}
                    handleCloseModal={handleCloseModal}
                />
            );
        }

        return (
            <ExternalReferenceStep
                handleBackStep={handleBack}
                defaultValues={{ externalReference }}
                handleFormSubmit={handleOnSubmitClick}
                handleCloseModal={handleCloseModal}
                isLoading={isLoading}
                submitButtonText={a2aDetailsLang.PAY.SUBMIT_BUTTON}
            />
        );
    }, [activeStep, isLoading]);

    return (
        <ActionModalWrapper
            activeStep={activeStep}
            steps={stepsConfig}
            handleClose={handleCloseModal}
            isOpen={isOpen}
            title={a2aDetailsLang.PAY.TITLE}
            testId="payA2AModal"
        >
            {renderCorrectStep()}
        </ActionModalWrapper>
    );
}
