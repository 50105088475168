import axios from 'axios';
import type { AxiosInstance } from 'axios';

export const API_URL = 'http://localhost:8080/api';

export const TIMEOUT = 25000;

export const createHttpInstance = (url: string): AxiosInstance =>
    axios.create({
        baseURL: url,
        timeout: TIMEOUT,
    });

export const localhostInstance = createHttpInstance(API_URL);

export type HttpInstance = AxiosInstance;
