import React from 'react';
import { ListItemButton } from '@mui/material';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { DockColor } from '@dock/common';
import { SidebarItemIcon } from './SidebarItemIcon';
import { SidebarItemText } from './SidebarItemText';
import { NavbarRoutes } from './types';

type SidebarButtonItemProps = {
    isActive: boolean;
    item: NavbarRoutes;
    isNavbarOpen?: boolean | undefined;
    isOpen?: boolean;
    toggle?: () => void;
    // TODO: consider to change any to specific type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick?: (event: React.MouseEvent<any>) => void;
};

const getSidebarButtonItemStyles = (isActive: boolean, isNavbarOpen: boolean) => ({
    backgroundColor: isActive ? DockColor.NEUTRAL_600 : DockColor.PRIMARY_1100,
    '&:hover': {
        backgroundColor: DockColor.NEUTRAL_600,
    },
    borderRadius: '4px',
    padding: '12px 16px',
    ...(!isNavbarOpen && {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }),
});

export function SidebarButtonItem({
    isNavbarOpen = true,
    item,
    isActive,
    isOpen,
    toggle,
    onClick,
}: SidebarButtonItemProps) {
    const hasChildren = !!item?.children;
    const shouldRenderCollapsable = isNavbarOpen && hasChildren;

    return (
        <ListItemButton
            disabled={!!item.isDisabled}
            sx={getSidebarButtonItemStyles(isActive, isNavbarOpen)}
            onClick={isNavbarOpen ? toggle : onClick}
            data-testid={item.testId}
        >
            {item.IconElement && (
                <SidebarItemIcon isNavbarOpen={isNavbarOpen} Icon={<item.IconElement />} />
            )}
            <SidebarItemText shouldHideText={!isNavbarOpen} label={item.name} />
            {shouldRenderCollapsable &&
                (isOpen ? (
                    <ExpandMoreOutlinedIcon
                        sx={{
                            color: 'background.default',
                        }}
                    />
                ) : (
                    <ChevronRightOutlinedIcon
                        sx={{
                            color: 'background.default',
                        }}
                    />
                ))}
        </ListItemButton>
    );
}
