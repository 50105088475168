import {
    A2ATransfersPaymentEventError,
    A2ATransfersPaymentEventKind,
} from '@dock/types-dock-partner';
import lang from '../../../../lang/a2aTransactionsDetails';

export const A2APaymentEventKindMap: Record<
    Exclude<A2ATransfersPaymentEventKind, { ERROR: A2ATransfersPaymentEventError }>,
    string
> = {
    SCHEDULED: lang.EVENTS_KIND.SCHEDULED,
    PENDING: lang.EVENTS_KIND.PENDING,
    EXECUTED: lang.EVENTS_KIND.EXECUTED,
    COMPLETED: lang.EVENTS_KIND.COMPLETED,
    DISABLED: lang.EVENTS_KIND.DISABLED,
};

export const A2APaymentEventKindErrorMap: Record<A2ATransfersPaymentEventError, string> = {
    INSUFFICIENT_FUNDS: lang.EVENTS_KIND.INSUFFICIENT_FUNDS,
    INTERNAL: lang.EVENTS_KIND.INTERNAL,
    LOCKED: lang.EVENTS_KIND.LOCKED,
};
