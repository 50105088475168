import { Grid, Typography, Box } from '@mui/material';
import { ColoredAmountText, ContentTitle } from '@dock/react';
import { AccountsAccount } from '@dock/types-dock-partner';
import accountDetailsLang from '../../../lang/accountDetails';
import { ErrorBox } from './ErrorBox';
import { getBoxStyles } from '../../../common';

type MoneyBalanceProps = Pick<AccountsAccount, 'balances'> & {
    isLoading: boolean;
    accountId: string;
    isError: boolean;
};

export function MoneyBalance({ balances, accountId, isLoading, isError }: MoneyBalanceProps) {
    if (isError) {
        return <ErrorBox id={accountId} />;
    }

    return (
        <Box data-testid="moneyBalance" sx={({ palette }) => ({ ...getBoxStyles(palette) })}>
            <Typography variant="h3" sx={{ marginBottom: 3 }}>
                {accountDetailsLang.BALANCE}
            </Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <ContentTitle
                        title={accountDetailsLang.AVAILABLE_BALANCE}
                        content={<ColoredAmountText amount={balances.available.amount} />}
                        isLoading={isLoading}
                        contentTestId="availableBalance"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ContentTitle
                        title={accountDetailsLang.CURRENCY}
                        content={balances.total.currency}
                        isLoading={isLoading}
                        contentTestId="currency"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ContentTitle
                        title={accountDetailsLang.TOTAL_BALANCE}
                        content={<ColoredAmountText amount={balances.total.amount} />}
                        isLoading={isLoading}
                        contentTestId="totalBalance"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ContentTitle
                        title={accountDetailsLang.HOLD_BALANCE}
                        content={<ColoredAmountText amount={balances.hold.amount} />}
                        isLoading={isLoading}
                        contentTestId="holdBalance"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
