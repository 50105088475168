import type {
    CardsChangeCardStatusRequest,
    CardsChangeCardStatusResponse,
} from '@dock/types-dock-partner';

import { GenericServiceError } from '@dock/common';
import { updateCardStatus } from '../../../client';
import { generateIdempotencyKey } from '../../../../common';

export const changeCardStatus = async (
    cardId: string | undefined,
    payload: CardsChangeCardStatusRequest
): Promise<CardsChangeCardStatusResponse> => {
    const headers = {
        'idempotency-key': generateIdempotencyKey(),
    };

    const errorOrResponse = await updateCardStatus(cardId)({
        payload,
        headers,
    });

    if (errorOrResponse instanceof GenericServiceError) {
        throw errorOrResponse;
    }

    return errorOrResponse;
};
