import { TableRow, TableCell, Typography } from '@mui/material';
import {
    CardsVelocityControlTargetKind,
    type CardsVelocityControlListEntry,
} from '@dock/types-dock-partner';
import { transformDate } from '@dock/react-util';
import { TextWithCopyIcon } from '@dock/react';
import {
    LinkWithCopyIcon,
    CARD_DETAILS_TRANSACTIONS_ROUTE,
    interpolateParams,
    cardsVCTargetKindMap,
} from '../../../../../common';

export type SpendingControlCollapsableRowProps = Pick<
    CardsVelocityControlListEntry,
    'datetimes' | 'target' | 'label'
>;

export function SpendingControlCollapsableRow({
    datetimes,
    target,
    label,
}: SpendingControlCollapsableRowProps) {
    const isCard = target.kind === CardsVelocityControlTargetKind.CARD;

    return (
        <TableRow data-testid="collapsedRow">
            <TableCell sx={{ width: 350 }}>
                <Typography data-testid="categoryCell" variant="subtitle1">
                    {label}
                </Typography>
            </TableCell>
            <TableCell sx={{ width: 350 }}>
                <Typography data-testid="categoryCell" variant="subtitle1">
                    {cardsVCTargetKindMap[target.kind]}
                </Typography>
            </TableCell>
            <TableCell data-testid="cardIdCell">
                <TextWithCopyIcon text={target.id}>
                    {isCard ? (
                        <LinkWithCopyIcon
                            href={interpolateParams(CARD_DETAILS_TRANSACTIONS_ROUTE, {
                                id: target.id,
                            })}
                            text={target.id}
                        />
                    ) : (
                        <Typography>{target.id ?? '-'}</Typography>
                    )}
                </TextWithCopyIcon>
            </TableCell>
            <TableCell>
                <Typography data-testid="createdCell" variant="subtitle1">
                    {transformDate(new Date(datetimes.created))}
                </Typography>
            </TableCell>
        </TableRow>
    );
}
