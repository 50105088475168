import { HttpResponse } from '@dock/http-request';
import { ResponseWithContinuationToken } from '@dock/services';
import { CONTINUATION_TOKEN_RESPONSE_HEADER } from '@dock/common';

// TODO: move it to lib/ level after finishing the refactoring in service layer
export const getData = async <T>(response: HttpResponse): Promise<T> => response.data as T;

// TODO: move it to lib/ level after finishing the refactoring in service layer
export const getDataAndHeaders = async <T>(
    response: HttpResponse
): Promise<ResponseWithContinuationToken<T>> => ({
    data: response.data as T,
    continuationToken: response.headers[CONTINUATION_TOKEN_RESPONSE_HEADER],
});
