import { TableRow } from '@mui/material';
import React from 'react';
import { CustomVirtualisation } from './CustomVirtualisation';

type CustomVirtualisationWrapperProps = {
    children: React.ReactNode;
    testId: string;
    index: string;
    isDisableVirtualisation: boolean;
};

export function CustomVirtualisationWrapper({
    children,
    testId,
    index,
    isDisableVirtualisation = false,
}: CustomVirtualisationWrapperProps) {
    if (isDisableVirtualisation) {
        return <TableRow data-testid={testId}>{children}</TableRow>;
    }

    return (
        <CustomVirtualisation index={index} testId={testId}>
            {children}
        </CustomVirtualisation>
    );
}
