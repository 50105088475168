import TableCell from '@mui/material/TableCell';
import {
    ActionMenuItemProps,
    ActionsMenu,
    TextWithCopyIcon,
    TextWithCopyIconContent,
    ViewDetailsLink,
    NavLink,
} from '@dock/react';
import {
    TablesTypography,
    LinkWithCopyIcon,
    CustomVirtualisationWrapper,
    BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE,
    interpolateParams,
    ENV_VARS,
} from '../../../../common';

export type InstrumentsRowProps = {
    bankTransferId: string;
    bankTransferHolderId: string;
    bic: string;
    iban: string;
    createdTime: string;
    index: string;
};

export function InstrumentsTableRow({
    bankTransferHolderId,
    bankTransferId,
    bic,
    createdTime,
    iban,
    index,
}: InstrumentsRowProps) {
    const actions: ActionMenuItemProps[] = [
        {
            content: (
                <NavLink
                    to={interpolateParams(BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE, {
                        id: index,
                    })}
                    label={<ViewDetailsLink />}
                />
            ),
            id: '0',
        },
    ];

    return (
        <CustomVirtualisationWrapper
            index={index}
            testId="cardsRow"
            isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
        >
            <TableCell data-testid="bankTransferIdCell">
                <TextWithCopyIcon text={bankTransferId}>
                    <LinkWithCopyIcon
                        href={interpolateParams(BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE, {
                            id: bankTransferId,
                        })}
                        text={bankTransferId}
                        ellipsis="start"
                    />
                </TextWithCopyIcon>
            </TableCell>

            <TableCell data-testid="bankTransferHolderIdCell">
                <TextWithCopyIcon text={bankTransferHolderId}>
                    <TextWithCopyIconContent text={bankTransferHolderId} ellipsis="start" />
                </TextWithCopyIcon>
            </TableCell>

            <TableCell data-testid="bicCell">
                <TablesTypography content={bic} />
            </TableCell>

            <TableCell data-testid="ibanCell">
                <TextWithCopyIcon text={iban}>
                    <TextWithCopyIconContent text={iban} />
                </TextWithCopyIcon>
            </TableCell>

            <TableCell data-testid="createdTimeCell">
                <TablesTypography content={createdTime} />
            </TableCell>

            <TableCell data-testid="actionsCell">
                <ActionsMenu menuItems={actions} />
            </TableCell>
        </CustomVirtualisationWrapper>
    );
}
