import { Column, ColumnAlign } from '@dock/react';
import accountDetailsLang from '../../../../lang/accountDetails';

export const transactionTableColumns: Column[] = [
    {
        name: accountDetailsLang.TRANSACTIONS_TABLE.COLUMNS.VALUE_DATE,
        key: 'valueDate',
        sortable: true,
    },
    {
        name: accountDetailsLang.TRANSACTIONS_TABLE.COLUMNS.TRANSACTION_ID,
        key: 'transactionID',
    },
    {
        name: accountDetailsLang.TRANSACTIONS_TABLE.COLUMNS.PAYMENT_INSTRUMENT,
        key: 'paymentInstrument',
    },
    {
        name: accountDetailsLang.TRANSACTIONS_TABLE.COLUMNS.INSTRUMENT_ID,
        key: 'instrumentID',
    },
    {
        name: accountDetailsLang.TRANSACTIONS_TABLE.COLUMNS.INSTRUMENT_TX_ID,
        key: 'instrumentTxID',
    },
    {
        name: accountDetailsLang.TRANSACTIONS_TABLE.COLUMNS.COUNTERPARTY,
        key: 'counterparty',
    },
    {
        name: accountDetailsLang.TRANSACTIONS_TABLE.COLUMNS.AMOUNT,
        key: 'amount',
        align: ColumnAlign.RIGHT,
    },
    {
        name: accountDetailsLang.TRANSACTIONS_TABLE.COLUMNS.BALANCE,
        key: 'balance',
        align: ColumnAlign.RIGHT,
    },
    {
        name: accountDetailsLang.TRANSACTIONS_TABLE.COLUMNS.CURRENCY,
        key: 'currency',
    },
];
