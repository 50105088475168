import { useMutation } from '@tanstack/react-query';

import type { PayloadWithIdempotencyKey } from '@dock/types-common';
import type { CreateAccountData, AccountsCreateAccount } from '@dock/types-dock-partner';
import { PartnerServiceError } from '../../../common/PartnerServiceError';
import { queryClient } from '../../client';
import { ACCOUNTS_LIST_KEY } from '../../keys';
import { updateTokensOnMutate } from '../updateTokensOnMutate';
import { createAccount } from '../../api';
import { addHeader } from '../../addHeader';

export function useCreateAccount() {
    return useMutation<
        CreateAccountData,
        PartnerServiceError,
        PayloadWithIdempotencyKey<AccountsCreateAccount>
    >((variables) => createAccount(variables.payload, addHeader(variables.idempotencyKey)), {
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [ACCOUNTS_LIST_KEY],
            });
        },
        onMutate: updateTokensOnMutate,
    });
}
