import { useInfiniteQuery } from '@tanstack/react-query';
import { ResponseWithContinuationToken } from '@dock/services';
import {
    PagedServiceParams,
    ExtractorDataFromInfiniteData,
    extractDataFromInfiniteData,
} from '../../../common';

type BasicParams = object;

type UseHooksFetcher<TParams extends BasicParams> = {
    params: TParams;
    enabled?: boolean;
};

type HookFetcher<SingleResponse> = {
    queryKey: string;
    fetcher: <TParams extends BasicParams>(
        params: PagedServiceParams<TParams>
    ) => Promise<ResponseWithContinuationToken<SingleResponse[]>>;
    extractor?: (
        d: ExtractorDataFromInfiniteData<SingleResponse>
        // TODO: maybe return type from extractor should be parametrized
    ) => SingleResponse[];
};

export const useInfiniteQueryWith =
    <TSingleResponse>({
        fetcher,
        queryKey,
        extractor = extractDataFromInfiniteData,
    }: HookFetcher<TSingleResponse>) =>
    <TParams extends BasicParams>({ params }: UseHooksFetcher<TParams>) => {
        const {
            data,
            isError,
            isLoading,
            error,
            fetchNextPage,
            hasNextPage,
            isFetching,
            isFetchingNextPage,
        } = useInfiniteQuery(
            [queryKey, params],
            (lastPage) =>
                fetcher<TParams>({
                    pageParam: lastPage.pageParam,
                    params,
                }),
            {
                getNextPageParam: ({ continuationToken }) => continuationToken,
            }
        );

        const extractedData = extractor(data);

        return {
            data: extractedData,
            error,
            isError,
            isLoading,
            fetchNextPage,
            hasNextPage,
            isFetching,
            isFetchingNextPage,
        };
    };
