import { useState } from 'react';
import { useHandleStepper } from '@dock/react';

type UseCreatePageParams<TFormValues> = {
    defaultValues: TFormValues;
};

export const useCreatePage = <TFormValues>({ defaultValues }: UseCreatePageParams<TFormValues>) => {
    const { activeStep, handleBack, handleNext, handleReset } = useHandleStepper();

    const [formValues, setFormValues] = useState<TFormValues>(defaultValues);

    const handleForm = (values: Partial<TFormValues>) => {
        setFormValues((prevState) => ({ ...prevState, ...values }));
    };

    return {
        formValues,
        handleForm,
        stepper: {
            handleReset,
            activeStep,
            handleBack,
            handleNext,
        },
    };
};
