import { Accordion, AccordionTitle, useModal } from '@dock/react';
import { Box, Grid, Typography } from '@mui/material';
import type { CardsCardStatus, CardsFulfillmentStatus } from '@dock/types-dock-partner';
import { StatusChip } from '../../../common';
import { ChangeCardStatus } from './ChangeCardStatus';
import { physicalCardStatusMap } from './physicalCardStatusMap';
import { ChangeCardStatusModal } from './ChangeCardStatusModal';
import { cardStatusMap } from '../../Cards/components/CardsList/cardStatusMap';
import { physicalCardStatusColorMap } from './physicalCardStatusColorMap';
import { cardDetailsBL } from '../BL/cardDetails.BL';
import cardDetailsLang from '../../../lang/cardDetails';
import { cardStatusColorMap } from '../../Cards/components/CardsList/cardStatusColorMap';

type CardKindAccordionProps = {
    kind: string;
    cardStatus: CardsCardStatus;
    fulfillmentStatus: CardsFulfillmentStatus;
};

export function CardKindAccordion({ cardStatus, fulfillmentStatus, kind }: CardKindAccordionProps) {
    const { isOpen, setClose, setOpen } = useModal();

    return (
        <>
            <Accordion
                title={cardDetailsLang.CARD_ACCORDION.CARD_KIND_TITLE}
                testId="cardKindAccordion"
            >
                <Grid container rowSpacing="20px">
                    <Grid item xs={4}>
                        <AccordionTitle content="Kind" />
                        <Typography variant="h3">{kind}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <AccordionTitle
                            content={cardDetailsLang.CARD_ACCORDION.CARD_STATUS_TITLE}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '12px',
                                alignItems: 'center',
                            }}
                        >
                            <StatusChip
                                color={cardStatusColorMap[cardStatus]}
                                label={cardStatusMap[cardStatus] || cardStatus}
                            />
                            {cardDetailsBL.isChangeCardStatusAvailable() && (
                                <ChangeCardStatus setOpen={setOpen} cardStatus={cardStatus} />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={4}>
                        <AccordionTitle
                            content={cardDetailsLang.CARD_ACCORDION.FULFILLMENT_STATUS_TITLE}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '12px',
                                height: '40px',
                                alignItems: 'center',
                            }}
                        >
                            {fulfillmentStatus && (
                                <StatusChip
                                    label={
                                        physicalCardStatusMap[fulfillmentStatus] ||
                                        fulfillmentStatus
                                    }
                                    color={physicalCardStatusColorMap[fulfillmentStatus]}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Accordion>

            <ChangeCardStatusModal cardStatus={cardStatus} handleClose={setClose} isOpen={isOpen} />
        </>
    );
}
