import { transformDate } from '@dock/react-util';
import { BankTransferRoutingInfo, ListBankTransferInstrumentData } from '@dock/types-dock-partner';
import { InstrumentsRowProps } from '../../../pages/BankTransfer/components/InstrumentsTable/InstrumentsTableRow';

// TODO: this logic should be in the BL file because this requirements seems to business requirements
// We can display only Luxembourg IBANs
const findLuxembourgIban = (data: BankTransferRoutingInfo[]) =>
    data.find(({ iban }) => iban.startsWith('LU'));

export const bankTransfersInstrumentMapper = (
    data: ListBankTransferInstrumentData | undefined
): InstrumentsRowProps[] =>
    !data
        ? []
        : data.map(({ id, datetimes, holderId, routingInfos }): InstrumentsRowProps => {
              const routingInfo = findLuxembourgIban(routingInfos as BankTransferRoutingInfo[]);

              return {
                  bankTransferId: id,
                  index: id,
                  bankTransferHolderId: holderId,
                  bic: routingInfo?.bic || '',
                  iban: routingInfo?.iban || '',
                  createdTime: transformDate(new Date(datetimes.created)),
              };
          });
