import { Box, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useState } from 'react';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import commonLang from '../../../lang/common';

export function LegalItems() {
    const [isOpen, setIsOpen] = useState(false);

    const handleMenuOpen = () => {
        setIsOpen(true);
    };

    const handleMenuClose = () => {
        setIsOpen(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0.5em 0',
                    width: '100%',
                }}
                onClick={isOpen ? handleMenuClose : handleMenuOpen}
            >
                <ListItemIcon sx={{ color: ({ palette }) => palette.grey[800] }}>
                    <PrivacyTipOutlinedIcon />
                </ListItemIcon>

                <ListItemText>
                    <Typography variant="body1">{commonLang.LEGAL}</Typography>
                </ListItemText>

                <ListItemIcon>
                    {isOpen ? (
                        <ExpandLessOutlinedIcon
                            sx={{
                                minWidth: '40px',
                                color: ({ palette }) => palette.grey[800],
                            }}
                        />
                    ) : (
                        <ExpandMoreOutlinedIcon
                            sx={{
                                minWidth: '40px',
                                color: ({ palette }) => palette.grey[800],
                            }}
                        />
                    )}
                </ListItemIcon>
            </Box>

            {isOpen && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <ListItemButton
                        component="a"
                        href="https://www.dock.financial/de/datenschutz"
                        target="_blank"
                        sx={{ padding: '10px 0px 10px 35px' }}
                    >
                        <ListItemText
                            primary={
                                <Typography variant="body1">{commonLang.PRIVACY_NOTICE}</Typography>
                            }
                        />
                    </ListItemButton>

                    <ListItemButton
                        component="a"
                        href="https://www.dock.financial/de/impressum"
                        target="_blank"
                        sx={{ padding: '10px 0px 10px 35px' }}
                    >
                        <ListItemText
                            primary={
                                <Typography variant="body1">{commonLang.LEGAL_NOTICE}</Typography>
                            }
                        />
                    </ListItemButton>
                </Box>
            )}
        </Box>
    );
}
