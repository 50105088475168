import { useCallback } from 'react';
import { IdempotencyKey } from '@dock/types-common';
import { Link } from '@mui/material';
import { ActionModalWrapper } from '@dock/react';
import { useCreateNewCard } from '../../../../services';
import {
    generateErrorText,
    generateIdempotencyKey,
    interpolateParams,
    ExternalReferenceStep,
    SuccessStatusMessage,
    useCreateModal,
    useStatusModals,
    CARD_DETAILS_TRANSACTIONS_ROUTE,
} from '../../../../common';
import { BasicDetailsStep } from './BasicDetailsStep';
import { PersonalisationDetailsStep } from './PersonalisationDetailsStep';
import { stepsConfig } from './stepsConfig';
import cardsLang from '../../../../lang/cards';
import { CreateCardPayloadType, responseFieldsMap } from './responseFieldsMap';

type CreateNewCardModalProps = {
    handleClose: () => void;
    isOpen: boolean;
    setOpenFormModal: () => void;
};

export type CreateCardFormType = CreateCardPayloadType & IdempotencyKey;

export function CreateNewCardModal({
    handleClose,
    setOpenFormModal,
    isOpen,
}: CreateNewCardModalProps) {
    const defaultValues: CreateCardFormType = {
        accountId: '',
        cardHolderId: '',
        definitionId: '',
        tenantId: '',
        line2: '',
        line3: '',
        qrCodeContent: '',
        externalReference: '',
        idempotencyKey: generateIdempotencyKey(),
    };
    const { showSuccessModal, showErrorModal } = useStatusModals();
    const { createNewCardMutation, isLoading } = useCreateNewCard();

    const {
        formValues,
        handleForm,
        stepper,
        handleErrorResponse,
        handleCloseModal,
        handleChangeData,
    } = useCreateModal<CreateCardFormType>({
        defaultValues,
        closeFormModal: handleClose,
        openFormModal: setOpenFormModal,
    });

    const { activeStep, handleBack, handleNext } = stepper;

    const {
        accountId,
        cardHolderId,
        definitionId,
        tenantId,
        externalReference,
        line2,
        line3,
        qrCodeContent,
        idempotencyKey,
    } = formValues;

    const handleChangeDataClick = () =>
        handleChangeData({ idempotencyKey: generateIdempotencyKey() });

    const handleFormSubmit = async (externalReferenceValue: string) => {
        try {
            const response = await createNewCardMutation({
                payload: {
                    accountId,
                    cardHolderId,
                    tenantId,
                    definitionId,
                    personalization: {
                        line2,
                        line3,
                        qrCodeContent,
                    },
                    externalReference: externalReferenceValue,
                },
                idempotencyKey,
            });

            handleCloseModal();
            const href = interpolateParams(CARD_DETAILS_TRANSACTIONS_ROUTE, {
                id: response.id,
            });
            showSuccessModal({
                messages: [
                    <SuccessStatusMessage
                        preLabel={cardsLang.CREATE_NEW.SUCCESS_PRE}
                        postLabel={cardsLang.CREATE_NEW.SUCCESS_POST}
                    >
                        <Link href={href}>{response.id}</Link>
                    </SuccessStatusMessage>,
                ],
            });
        } catch (e) {
            handleErrorResponse();

            showErrorModal({
                messages: [cardsLang.CREATE_NEW.FAILED, generateErrorText(e, responseFieldsMap)],
                onChangeClick: handleChangeDataClick,
                onSubmitClick: () => handleFormSubmit(externalReference || ''),
            });
        }
    };

    const handleOnSubmitClick = async (value: string) => {
        handleForm({ externalReference: value });
        await handleFormSubmit(value);
    };

    const renderCorrectStep = useCallback(() => {
        if (activeStep === 0) {
            return (
                <BasicDetailsStep
                    handleNextStep={handleNext}
                    handleForm={handleForm}
                    defaultValues={{
                        accountId,
                        cardHolderId,
                        definitionId,
                        tenantId,
                    }}
                    handleCloseModal={handleCloseModal}
                />
            );
        }

        if (activeStep === 1) {
            return (
                <PersonalisationDetailsStep
                    handleNextStep={handleNext}
                    handleBackStep={handleBack}
                    handleForm={handleForm}
                    defaultValues={{
                        line2,
                        line3,
                        qrCodeContent,
                    }}
                    handleCloseModal={handleCloseModal}
                />
            );
        }

        return (
            <ExternalReferenceStep
                handleBackStep={handleBack}
                defaultValues={{ externalReference }}
                handleFormSubmit={handleOnSubmitClick}
                handleCloseModal={handleCloseModal}
                isLoading={isLoading}
                submitButtonText={cardsLang.CREATE_CARD}
            />
        );
    }, [activeStep, isLoading]);

    return (
        <ActionModalWrapper
            activeStep={activeStep}
            steps={stepsConfig}
            testId="createCardModal"
            handleClose={handleCloseModal}
            isOpen={isOpen}
            title={cardsLang.CREATE_NEW.TITLE}
        >
            {renderCorrectStep()}
        </ActionModalWrapper>
    );
}
