import type { BankTransferBankTransferTransaction } from '@dock/types-dock-partner';
import { flow } from 'fp-ts/lib/function';
import { handleErrors } from '../../../../errorService';
import {
    getDataAndHeaders,
    requestFlow,
    RequestAndUnwrap,
    unwrapService,
    ResponseWithContinuationToken,
} from '../../../../utilities';

export type ListTransactionsResponse = ResponseWithContinuationToken<
    BankTransferBankTransferTransaction[]
>;
export const listTransactionHandler = requestFlow(handleErrors)(`v0/bank_transfers/transactions`);

const fetchAndHandle = listTransactionHandler<ListTransactionsResponse>(getDataAndHeaders);

/**
 * @deprecated
 */
export const listTransactions: RequestAndUnwrap<'get', ListTransactionsResponse> = flow(
    fetchAndHandle,
    unwrapService<ListTransactionsResponse>
);
