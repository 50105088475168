import React from 'react';
import { Box, Typography, SxProps, Theme } from '@mui/material';
import { SuccessSvg, SecondaryButton } from '@dock/react';
import commonLang from '../../../lang/common';

type SuccessStatusContentProps = {
    handleClose: () => void;
    statusMessages: string[] | React.ReactElement[];
    buttonStyles?: SxProps<Theme>;
};

export function SuccessStatusContent({
    handleClose,
    statusMessages,
    buttonStyles = {},
}: SuccessStatusContentProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <SuccessSvg />

            <Typography component="h2" variant="h2">
                {commonLang.STATUS_MODALS.SUCCESS_TITLE}
            </Typography>

            {statusMessages.map((message, index) => (
                <Typography
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    sx={{
                        width: '80%',
                        textAlign: 'center',
                    }}
                >
                    {message}
                </Typography>
            ))}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 3,
                    width: '100%',
                    ...buttonStyles,
                }}
            >
                <SecondaryButton onClick={handleClose} testId="successButton">
                    {commonLang.STATUS_MODALS.OK}
                </SecondaryButton>
            </Box>
        </Box>
    );
}
