import {
    ACCOUNT_DETAILS_PAYMENT_INST_ROUTE,
    ACCOUNT_DETAILS_TRANSACTIONS_ROUTE,
    interpolateParams,
    TabConfig,
} from '../../common';
import accountLang from '../../lang/accounts';

export const getAccountsDetailsTabConfig = (id: string): TabConfig[] => [
    {
        label: accountLang.TABS.ACCOUNT_DETAILS.TRANSACTIONS,
        link: interpolateParams(ACCOUNT_DETAILS_TRANSACTIONS_ROUTE, {
            id,
        }),
    },
    {
        label: accountLang.TABS.ACCOUNT_DETAILS.PAYMENT_INSTRUMENTS,
        link: interpolateParams(ACCOUNT_DETAILS_PAYMENT_INST_ROUTE, {
            id,
        }),
    },
];
