import React from 'react';
import { WhiteBackgroundWrapper, PageContainer } from '@dock/react';
import { useParams } from 'react-router-dom';
import { BankTransferDetailsBox } from './components/BankTransferDetailsBox';
import { getDetailsTabConfig } from './getTabConfig';
import { useBankTransferInstrumentsList } from '../../services';
import { BreadcrumbsWrapper, NavLinkTabSwitcher } from '../../common';

type BankTransferDetailsProps = {
    children: React.ReactNode;
};

export function BankTransferDetails({ children }: BankTransferDetailsProps) {
    const { id = '' } = useParams();

    const { data, isFetching } = useBankTransferInstrumentsList({
        params: { id: [id] },
    });

    const tabs = getDetailsTabConfig(id);

    return (
        <PageContainer testId="bankTransferDetailsPage">
            <BreadcrumbsWrapper />
            <BankTransferDetailsBox isFetching={isFetching} transfer={data[0]} />

            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher tabs={tabs} />
                {children}
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
