import {
    CardsMethod,
    CardsTransactionStatus,
    CardsAuthorizationStatus,
    CardsTransactionKind,
    CardsAuthorizationEventStatus,
    CardsCardType,
    CardsAuthorizationEventKind,
    CardsAuthorizationEventOutcomeReason,
    CardsVelocityControlScope,
    CardsVelocityControlTargetKind,
    CardsVelocityControlWindowKind,
    A2ATransfersPaymentEventKindFilter,
} from '@dock/types-dock-partner';
import a2aPaymentEventLang from '../lang/a2aTransactionsDetails';
import cardDetailsLang from '../lang/cardDetails';
import cardsLang from '../lang/cards';
import createCardSCLang from '../lang/createCardSC';
import { ChipColors } from './enums';

export const transactionMethodsMap: Record<CardsMethod, string> = {
    POS: cardDetailsLang.CARD_TRANSACTION_METHODS.POS,
    ECOMMERCE: cardDetailsLang.CARD_TRANSACTION_METHODS.ECOMMERCE,
    ATM: cardDetailsLang.CARD_TRANSACTION_METHODS.ATM,
    MAIL: cardDetailsLang.CARD_TRANSACTION_METHODS.MAIL,
    PHONE: cardDetailsLang.CARD_TRANSACTION_METHODS.PHONE,
    MOTO: cardDetailsLang.CARD_TRANSACTION_METHODS.MOTO,
    OTHER: cardDetailsLang.CARD_TRANSACTION_METHODS.OTHER,
    UNAVAILABLE: cardDetailsLang.CARD_TRANSACTION_METHODS.UNAVAILABLE,
};

export const transactionStatusesMap: Record<CardsTransactionStatus, string> = {
    PENDING: cardDetailsLang.CARD_TRANSACTION_STATUS.PENDING,
    COMPLETED: cardDetailsLang.CARD_TRANSACTION_STATUS.COMPLETED,
};

export const transactionStatusColorMap: Record<CardsTransactionStatus, ChipColors> = {
    PENDING: ChipColors.warning,
    COMPLETED: ChipColors.success,
};

export const transactionKindMap: Record<CardsTransactionKind, string> = {
    CAPTURE: cardDetailsLang.CARD_TRANSACTION_KIND.CAPTURE,
    REFUND: cardDetailsLang.CARD_TRANSACTION_KIND.REFUND,
    CHARGEBACK: cardDetailsLang.CARD_TRANSACTION_KIND.CHARGEBACK,
    REPRESENTMENT: cardDetailsLang.CARD_TRANSACTION_KIND.REPRESENTMENT,
};

export const paymentEventKindMap: Record<A2ATransfersPaymentEventKindFilter, string> = {
    PENDING: a2aPaymentEventLang.EVENTS_KIND.PENDING,
    COMPLETED: a2aPaymentEventLang.EVENTS_KIND.COMPLETED,
    [A2ATransfersPaymentEventKindFilter.SCHEDULED]: a2aPaymentEventLang.EVENTS_KIND.SCHEDULED,
    [A2ATransfersPaymentEventKindFilter.EXECUTED]: a2aPaymentEventLang.EVENTS_KIND.EXECUTED,
    [A2ATransfersPaymentEventKindFilter.DISABLED]: a2aPaymentEventLang.EVENTS_KIND.DISABLED,
    [A2ATransfersPaymentEventKindFilter.ERROR]: a2aPaymentEventLang.EVENTS_KIND.ERROR,
};

export const cardAuthStatusesMap: Record<CardsAuthorizationStatus, string> = {
    PENDING: cardDetailsLang.CARD_AUTHORIZATION_STATUS.PENDING,
    DECLINED: cardDetailsLang.CARD_AUTHORIZATION_STATUS.DECLINED,
    APPROVED: cardDetailsLang.CARD_AUTHORIZATION_STATUS.APPROVED,
    EXPIRED: cardDetailsLang.CARD_AUTHORIZATION_STATUS.EXPIRED,
    CLOSED: cardDetailsLang.CARD_AUTHORIZATION_STATUS.CLOSED,
    REVERSED: cardDetailsLang.CARD_AUTHORIZATION_STATUS.REVERSED,
};

export const cardAuthStatusColorMap: Record<CardsAuthorizationStatus, ChipColors> = {
    PENDING: ChipColors.default,
    EXPIRED: ChipColors.default,
    REVERSED: ChipColors.default,
    CLOSED: ChipColors.default,
    DECLINED: ChipColors.error,
    APPROVED: ChipColors.success,
};

export const authorizationEventStatusMap: Record<CardsAuthorizationEventStatus, string> = {
    PENDING: cardDetailsLang.AUTHORIZATION_EVENT_STATUS.PENDING,
    DECLINED: cardDetailsLang.AUTHORIZATION_EVENT_STATUS.DECLINED,
    APPROVED: cardDetailsLang.AUTHORIZATION_EVENT_STATUS.APPROVED,
};

export const authorizationEventStatusColorMap: Record<CardsAuthorizationEventStatus, ChipColors> = {
    PENDING: ChipColors.warning,
    DECLINED: ChipColors.error,
    APPROVED: ChipColors.success,
};

export const cardTypeMap: Record<CardsCardType, string> = {
    VIRTUAL: cardsLang.VIRTUAL,
    PHYSICAL: cardsLang.PHYSICAL,
};

export const authorizationEventOutcomeReasonMap: Record<
    CardsAuthorizationEventOutcomeReason,
    string
> = {
    ACCOUNT_INSUFFICIENT_FUNDS: cardDetailsLang.OUTCOME_REASON.ACCOUNT_INSUFFICIENT_FUNDS,
    ADDRESS_VERIFICATION_SUCCESS: cardDetailsLang.OUTCOME_REASON.ADDRESS_VERIFICATION_SUCCESS,
    APPROVAL: cardDetailsLang.OUTCOME_REASON.APPROVAL,
    AUTHORIZATION_PARTIALLY_APPROVED:
        cardDetailsLang.OUTCOME_REASON.AUTHORIZATION_PARTIALLY_APPROVED,
    CARD_ACCOUNT_FUNDING_TRANSACTION_NOT_ALLOWED:
        cardDetailsLang.OUTCOME_REASON.CARD_ACCOUNT_FUNDING_TRANSACTION_NOT_ALLOWED,
    CARD_ACCOUNT_VERIFICATION_SUCCESS:
        cardDetailsLang.OUTCOME_REASON.CARD_ACCOUNT_VERIFICATION_SUCCESS,
    CARD_ACTIVATION_CHECK_SUCCESS: cardDetailsLang.OUTCOME_REASON.CARD_ACTIVATION_CHECK_SUCCESS,
    CARD_ADDRESS_VERIFICATION_SYSTEM_NO_INFO:
        cardDetailsLang.OUTCOME_REASON.CARD_ADDRESS_VERIFICATION_SYSTEM_NO_INFO,
    CARD_ATM_NOT_ALLOWED: cardDetailsLang.OUTCOME_REASON.CARD_ATM_NOT_ALLOWED,
    CARD_CHANNEL_OTHER_NOT_ALLOWED: cardDetailsLang.OUTCOME_REASON.CARD_CHANNEL_OTHER_NOT_ALLOWED,
    CARD_CVV_LIMIT_EXCEEDED: cardDetailsLang.OUTCOME_REASON.CARD_CVV_LIMIT_EXCEEDED,
    CARD_ECOM_TRANSACTION_NOT_ALLOWED:
        cardDetailsLang.OUTCOME_REASON.CARD_ECOM_TRANSACTION_NOT_ALLOWED,
    CARD_EXPIRATION_MISMATCH: cardDetailsLang.OUTCOME_REASON.CARD_EXPIRATION_MISMATCH,
    CARD_EXPIRED: cardDetailsLang.OUTCOME_REASON.CARD_EXPIRED,
    CARD_INCORRECT_PIN: cardDetailsLang.OUTCOME_REASON.CARD_INCORRECT_PIN,
    CARD_INVALID_CVV2: cardDetailsLang.OUTCOME_REASON.CARD_INVALID_CVV2,
    CARD_LOCKED: cardDetailsLang.OUTCOME_REASON.CARD_LOCKED,
    CARD_MAIL_ORDER_TRANSACTION_NOT_ALLOWED:
        cardDetailsLang.OUTCOME_REASON.CARD_MAIL_ORDER_TRANSACTION_NOT_ALLOWED,
    CARD_MCC_BLOCKED: cardDetailsLang.OUTCOME_REASON.CARD_MCC_BLOCKED,
    CARD_NETWORK_LOAD_NOT_ALLOWED: cardDetailsLang.OUTCOME_REASON.CARD_NETWORK_LOAD_NOT_ALLOWED,
    CARD_NETWORK_RESPONSE: cardDetailsLang.OUTCOME_REASON.CARD_NETWORK_RESPONSE,
    CARD_PHONE_ORDER_TRANSACTION_NOT_ALLOWED:
        cardDetailsLang.OUTCOME_REASON.CARD_PHONE_ORDER_TRANSACTION_NOT_ALLOWED,
    CARD_QUASI_CASH_TRANSACTION_NOT_ALLOWED:
        cardDetailsLang.OUTCOME_REASON.CARD_QUASI_CASH_TRANSACTION_NOT_ALLOWED,
    CARD_SPENDING_EXCEEDED: cardDetailsLang.OUTCOME_REASON.CARD_SPENDING_EXCEEDED,
    CARD_TERMINATED: cardDetailsLang.OUTCOME_REASON.CARD_TERMINATED,
    CARD_TRANSACTION_ALREADY_REVERSED:
        cardDetailsLang.OUTCOME_REASON.CARD_TRANSACTION_ALREADY_REVERSED,
    CARD_TRANSACTION_NOT_PERMITTED: cardDetailsLang.OUTCOME_REASON.CARD_TRANSACTION_NOT_PERMITTED,
    CARD_UNACTIVATED: cardDetailsLang.OUTCOME_REASON.CARD_UNACTIVATED,
    CARD_USAGE_COUNT_REACHED: cardDetailsLang.OUTCOME_REASON.CARD_USAGE_COUNT_REACHED,
    WALLET_TOKEN_PENDING: cardDetailsLang.OUTCOME_REASON.WALLET_TOKEN_PENDING,
    WALLET_TOKEN_LOCKED: cardDetailsLang.OUTCOME_REASON.WALLET_TOKEN_LOCKED,
    WALLET_TOKEN_TERMINATED: cardDetailsLang.OUTCOME_REASON.WALLET_TOKEN_TERMINATED,
    WALLET_TOKEN_EXPIRED: cardDetailsLang.OUTCOME_REASON.WALLET_TOKEN_EXPIRED,
    WALLET_TOKEN_NOT_ACTIVE: cardDetailsLang.OUTCOME_REASON.WALLET_TOKEN_NOT_ACTIVE,
    WALLET_TOKEN_NOT_FOUND: cardDetailsLang.OUTCOME_REASON.WALLET_TOKEN_NOT_FOUND,
    WALLET_TOKEN_NOT_PRESENT: cardDetailsLang.OUTCOME_REASON.WALLET_TOKEN_NOT_PRESENT,
    WALLET_TOKEN_SUSPENDED: cardDetailsLang.OUTCOME_REASON.WALLET_TOKEN_SUSPENDED,
    VELOCITY_CONTROL_DECLINED: cardDetailsLang.OUTCOME_REASON.VELOCITY_CONTROL_DECLINED,
};

export const authEventOutcomeReasonColorMap: Record<
    CardsAuthorizationEventOutcomeReason,
    ChipColors
> = {
    ACCOUNT_INSUFFICIENT_FUNDS: ChipColors.default,
    ADDRESS_VERIFICATION_SUCCESS: ChipColors.default,
    APPROVAL: ChipColors.default,
    AUTHORIZATION_PARTIALLY_APPROVED: ChipColors.default,
    CARD_ACCOUNT_FUNDING_TRANSACTION_NOT_ALLOWED: ChipColors.default,
    CARD_ACCOUNT_VERIFICATION_SUCCESS: ChipColors.default,
    CARD_ACTIVATION_CHECK_SUCCESS: ChipColors.default,
    CARD_ADDRESS_VERIFICATION_SYSTEM_NO_INFO: ChipColors.default,
    CARD_ATM_NOT_ALLOWED: ChipColors.default,
    CARD_CHANNEL_OTHER_NOT_ALLOWED: ChipColors.default,
    CARD_CVV_LIMIT_EXCEEDED: ChipColors.default,
    CARD_ECOM_TRANSACTION_NOT_ALLOWED: ChipColors.default,
    CARD_EXPIRATION_MISMATCH: ChipColors.default,
    CARD_EXPIRED: ChipColors.default,
    CARD_INCORRECT_PIN: ChipColors.default,
    CARD_INVALID_CVV2: ChipColors.default,
    CARD_LOCKED: ChipColors.default,
    CARD_MAIL_ORDER_TRANSACTION_NOT_ALLOWED: ChipColors.default,
    CARD_MCC_BLOCKED: ChipColors.default,
    CARD_NETWORK_LOAD_NOT_ALLOWED: ChipColors.default,
    CARD_NETWORK_RESPONSE: ChipColors.default,
    CARD_PHONE_ORDER_TRANSACTION_NOT_ALLOWED: ChipColors.default,
    CARD_QUASI_CASH_TRANSACTION_NOT_ALLOWED: ChipColors.default,
    CARD_SPENDING_EXCEEDED: ChipColors.default,
    CARD_TERMINATED: ChipColors.default,
    CARD_TRANSACTION_ALREADY_REVERSED: ChipColors.default,
    CARD_TRANSACTION_NOT_PERMITTED: ChipColors.default,
    CARD_UNACTIVATED: ChipColors.default,
    CARD_USAGE_COUNT_REACHED: ChipColors.default,
    WALLET_TOKEN_PENDING: ChipColors.warning,
    WALLET_TOKEN_LOCKED: ChipColors.info,
    WALLET_TOKEN_TERMINATED: ChipColors.default,
    WALLET_TOKEN_EXPIRED: ChipColors.default,
    WALLET_TOKEN_NOT_ACTIVE: ChipColors.default,
    WALLET_TOKEN_NOT_FOUND: ChipColors.default,
    WALLET_TOKEN_NOT_PRESENT: ChipColors.default,
    WALLET_TOKEN_SUSPENDED: ChipColors.default,
    VELOCITY_CONTROL_DECLINED: ChipColors.default,
};

export const authorizationEventKindMap: Record<CardsAuthorizationEventKind, string> = {
    CREATION: cardDetailsLang.KINDS.CREATION,
    INCREASE: cardDetailsLang.KINDS.INCREASE,
    ADVICE: cardDetailsLang.KINDS.ADVICE,
    REVERSAL: cardDetailsLang.KINDS.REVERSAL,
    EXPIRY: cardDetailsLang.KINDS.EXPIRY,
};

export const cardsVCScopeMap: Record<CardsVelocityControlScope, string> = {
    INDIVIDUAL: createCardSCLang.SCOPES.INDIVIDUAL,
};

export const cardsVCTargetIdMap: Record<CardsVelocityControlTargetKind, string> = {
    CARD: createCardSCLang.CARD_ID,
    CARD_PRODUCT_DEFINITION: createCardSCLang.CARD_PRODUCT_DEFINITION_ID,
};

export const cardsVCTargetKindMap: Record<CardsVelocityControlTargetKind, string> = {
    CARD: createCardSCLang.CARD,
    CARD_PRODUCT_DEFINITION: createCardSCLang.CARD_PRODUCT_DEFINITION,
};

export const cardsVCWindowKindMap: Record<CardsVelocityControlWindowKind, string> = {
    PER_TRANSACTION: createCardSCLang.WINDOW_KIND.PER_TRANSACTION,
    MONTHS: createCardSCLang.WINDOW_KIND.MONTHS,
    DAYS: createCardSCLang.WINDOW_KIND.DAYS,
    LIFETIME: createCardSCLang.WINDOW_KIND.LIFETIME,
};
