import type { A2ATransfersPayment, A2ATransfersCreatePayment } from '@dock/types-dock-partner';

import { GenericServiceError } from '@dock/common';
import { createA2APaymentHandler } from '../../../client';

export const payA2A = async (
    senderId: string,
    payload: A2ATransfersCreatePayment,
    idempotencyKey: string
): Promise<A2ATransfersPayment> => {
    const headers = {
        'idempotency-key': idempotencyKey,
    };

    const errorOrResponse = await createA2APaymentHandler(senderId)({
        payload,
        headers,
    });

    if (errorOrResponse instanceof GenericServiceError) {
        throw errorOrResponse;
    }

    return errorOrResponse;
};
