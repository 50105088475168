import {
    CardHolderListWithToken,
    CardsListWithToken,
    VelocityControlListWithToken,
} from '@dock/services';
import {
    ListCardHoldersParams,
    ListVelocityControlsParams,
    GetCardsParams,
} from '@dock/types-dock-partner';
import { getCardHoldersList, getCardsList, getVelocityControlList } from '../../client';
import { fetcher } from './fetcher';

export const fetchCardsList = fetcher<CardsListWithToken, GetCardsParams>(getCardsList);

export const fetchCardHoldersList = fetcher<CardHolderListWithToken, ListCardHoldersParams>(
    getCardHoldersList
);

export const fetchVelocityControlList = fetcher<
    VelocityControlListWithToken,
    ListVelocityControlsParams
>(getVelocityControlList);
