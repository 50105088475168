import { UsersCapabilityAction, UsersCapabilityObject } from '@dock/types-dock-partner';
import { hasCapability } from '../../../services/user/hasCapability';

export const a2aBL = {
    isReadA2ATransferAvailable: () =>
        hasCapability(UsersCapabilityObject.A2ATRANSFER, UsersCapabilityAction.READ),
    isReadA2AHolderTransferAvailable: () =>
        hasCapability(UsersCapabilityObject.A2AHOLDER, UsersCapabilityAction.READ),
    isCreateA2ATransferAvailable: () =>
        hasCapability(UsersCapabilityObject.A2ATRANSFER, UsersCapabilityAction.CREATE),
    isCreateA2AHolderTransferAvailable: () =>
        hasCapability(UsersCapabilityObject.A2AHOLDER, UsersCapabilityAction.CREATE),
};
