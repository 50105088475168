import TableCell from '@mui/material/TableCell';
import { AmountCell, CollapsableTableElement, useBoolean, ExpandRow } from '@dock/react';
import { transformDate } from '@dock/react-util';
import { CardsAuthorizationEvent } from '@dock/types-dock-partner';
import {
    StatusChip,
    TablesTypography,
    CustomVirtualisationWrapper,
    authorizationEventStatusMap,
    authorizationEventStatusColorMap,
    authEventOutcomeReasonColorMap,
    authorizationEventKindMap,
    authorizationEventOutcomeReasonMap,
    ENV_VARS,
} from '../../../common';
import { CollapsableAuthEventRow } from './CollapsableAuthEventRow';
import { collapsableRowColumns } from './authEventsTableColumns';

export type AuthEventRowProps = Pick<
    CardsAuthorizationEvent,
    'id' | 'kind' | 'outcomeReason' | 'status' | 'amounts' | 'datetimes' | 'currencyConversion'
>;

export function AuthEventRow({
    id,
    kind,
    outcomeReason,
    amounts,
    status,
    datetimes,
    currencyConversion,
}: AuthEventRowProps) {
    const [isOpen, handleCollapsableElement] = useBoolean(false);

    const type = authorizationEventKindMap[kind] || kind;
    const initiatedTime = transformDate(new Date(datetimes.initiated));
    const lastUpdateTime = transformDate(new Date(datetimes.completed));

    return (
        <>
            <CustomVirtualisationWrapper
                index={id}
                testId="authEventRow"
                isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
            >
                <TableCell>
                    <ExpandRow isOpen={isOpen} onClick={handleCollapsableElement} />
                </TableCell>
                <TableCell data-testid="typeCell">
                    <TablesTypography content={type} />
                </TableCell>
                <TableCell data-testid="statusCell">
                    <StatusChip
                        label={authorizationEventStatusMap[status] || status}
                        color={authorizationEventStatusColorMap[status]}
                    />
                </TableCell>
                <TableCell data-testid="reasonCell">
                    {(!!outcomeReason && (
                        <StatusChip
                            label={
                                authorizationEventOutcomeReasonMap[outcomeReason] || outcomeReason
                            }
                            color={authEventOutcomeReasonColorMap[outcomeReason]}
                        />
                    )) ||
                        ''}
                </TableCell>
                <AmountCell amount={amounts.requestedMerchant.amount} />
                <TableCell data-testid="currencyCell">
                    <TablesTypography content={amounts.requestedMerchant.currency} />
                </TableCell>
                <TableCell data-testid="initiatedTimeCell">
                    <TablesTypography content={initiatedTime} />
                </TableCell>
                <TableCell data-testid="lastUpdateTimeCell">
                    <TablesTypography content={lastUpdateTime} />
                </TableCell>
            </CustomVirtualisationWrapper>

            <CollapsableTableElement
                isOpen={isOpen}
                columns={collapsableRowColumns}
                RowComponent={
                    <CollapsableAuthEventRow
                        id={id}
                        amounts={amounts}
                        currencyConversion={currencyConversion}
                    />
                }
            />
        </>
    );
}
