import { ellipsisText, ellipsisTextStart, SimpleTooltip } from '@dock/react';
import { Typography, TypographyVariant } from '@mui/material';
import { Link } from 'react-router-dom';

type LinkWithCopyIconContentProps = {
    text: string;
    testId: string;
    href: string;
    variant: TypographyVariant;
    ellipsis?: 'start' | 'end';
};

export function LinkWithCopyIconContent({
    text,
    testId,
    href,
    variant,
    ellipsis,
}: LinkWithCopyIconContentProps) {
    const ellipsesStyles = ellipsis === 'end' ? ellipsisText : ellipsisTextStart;

    return (
        <SimpleTooltip title={text}>
            <Typography
                variant={variant}
                data-testid={testId}
                sx={{ ...(!!ellipsis && ellipsesStyles) }}
            >
                <Link style={{ color: 'inherit' }} to={href}>
                    {text}
                </Link>
            </Typography>
        </SimpleTooltip>
    );
}
