import {
    ACCESS_TOKEN,
    commonBL,
    getAuthAccessTokenFromStorage,
    getRefreshTokenFromStorage,
    REFRESH_TOKEN,
} from '@dock/common';
import { AuthToken, RenewData } from '@dock/types-dock-partner';
import { KeysToSnakeCase } from '@dock/mock-server';
import { rawFetchTokens } from './rawFetchTokens';

const setToLocalStorage = (refreshToken: AuthToken, accessToken: AuthToken) => {
    localStorage.setItem(REFRESH_TOKEN, JSON.stringify(refreshToken));
    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(accessToken));
};

const removeAuthTokensFromLocalStorage = () => {
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(ACCESS_TOKEN);
};

export const updateTokens = async () => {
    const refreshToken = getRefreshTokenFromStorage();
    const authAccessToken = getAuthAccessTokenFromStorage();

    if (!refreshToken) {
        removeAuthTokensFromLocalStorage();
        throw new Error('Empty refresh token');
    }

    if (refreshToken && commonBL.isTokenExpired(refreshToken)) {
        removeAuthTokensFromLocalStorage();
        throw new Error('Refresh token expired');
    }

    const rawResponse = await rawFetchTokens({
        refreshToken,
        authAccessToken,
    });

    if (!rawResponse.ok) {
        removeAuthTokensFromLocalStorage();
        throw new Error('Failed to renew');
    }

    const content = (await rawResponse.json()) as KeysToSnakeCase<RenewData>;

    setToLocalStorage(content.refresh_token, content.access_token);
};
