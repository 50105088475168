import { useState } from 'react';
import { useHandleStepper } from '@dock/react';

type UseCreateModalParams<TFormValues> = {
    defaultValues: TFormValues;
    closeFormModal: () => void;
    openFormModal: () => void;
};

export const useCreateModal = <TFormValues>({
    defaultValues,
    closeFormModal,
    openFormModal,
}: UseCreateModalParams<TFormValues>) => {
    const { activeStep, handleBack, handleNext, handleReset } = useHandleStepper();

    const [formValues, setFormValues] = useState<TFormValues>(defaultValues);

    const handleForm = (values: Partial<TFormValues>) => {
        setFormValues((prevState) => ({ ...prevState, ...values }));
    };

    const handleCloseModal = () => {
        handleReset();
        handleForm(defaultValues);
        closeFormModal();
    };

    const handleErrorResponse = () => {
        handleReset();
        closeFormModal();
    };

    const handleChangeData = (values: Partial<TFormValues>) => {
        openFormModal();
        handleForm(values);
    };

    return {
        formValues,
        handleForm,
        stepper: {
            activeStep,
            handleBack,
            handleNext,
        },
        handleErrorResponse,
        handleCloseModal,
        handleChangeData,
    };
};
