import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { changeCardStatusSchema, changeCardStatusSchemaKeys } from '@dock/validation';
import { useParams } from 'react-router-dom';
import type { CardsCardStatus, CardsChangeCardStatus } from '@dock/types-dock-partner';
import { useAlertNotifications, ControlledSingleSelect } from '@dock/react';
import { useChangeCardStatus } from '../../../../services';
import { getDefaultCardStatus } from './defaultCardStatuses';
import { ChangeStatusModalButtons } from '../../../../common';
import { cardDetailsBL } from '../../BL/cardDetails.BL';
import commonLang from '../../../../lang/common';
import cardDetailsLang from '../../../../lang/cardDetails';

type ChangeStateCardFormValues = {
    [changeCardStatusSchemaKeys.cardStatus]: CardsChangeCardStatus;
};

type ChangeStateCardFormProps = {
    onClose: () => void;
    currentCardStatus: CardsCardStatus;
};

export function ChangeStateCardForm({ onClose, currentCardStatus }: ChangeStateCardFormProps) {
    const { showSuccessNotification, showErrorNotification } = useAlertNotifications();

    const methods = useForm<ChangeStateCardFormValues>({
        defaultValues: {
            cardStatus: getDefaultCardStatus(
                currentCardStatus
                // Should have this hack here as we should show to user all statuses enum CardsCardStatus but user may change only enum CardsChangeCardStatus
            ) as unknown as CardsChangeCardStatus,
        },
        resolver: zodResolver(changeCardStatusSchema),
    });

    const { control, handleSubmit } = methods;

    const { id: cardId } = useParams();

    const { isLoading, changeCardStatusMutation } = useChangeCardStatus(cardId);

    const onSubmitHandler = async ({ cardStatus }: ChangeStateCardFormValues) => {
        try {
            await changeCardStatusMutation({ status: cardStatus });

            showSuccessNotification({ title: cardDetailsLang.STATUS_CHANGED });
            onClose();
        } catch (e) {
            showErrorNotification({
                title: cardDetailsLang.STATUS_CHANGE_FAILED,
            });
        }
    };

    const filteredStatusOptions = cardDetailsBL.getStatusOptions(currentCardStatus);

    const selectedStatus = methods.watch()[changeCardStatusSchemaKeys.cardStatus];

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <ControlledSingleSelect
                    control={control}
                    label={cardDetailsLang.CARD_STATUS}
                    name={changeCardStatusSchemaKeys.cardStatus}
                    options={filteredStatusOptions}
                    defaultOption={currentCardStatus}
                    helperText={cardDetailsBL.getWarningText(selectedStatus)}
                />
                <ChangeStatusModalButtons
                    secondaryLabel={commonLang.CANCEL}
                    primaryLabel={cardDetailsLang.CHANGE_STATUS}
                    isLoading={isLoading}
                    onClose={onClose}
                />
            </form>
        </FormProvider>
    );
}
