import { Box, SxProps } from '@mui/material';
import React from 'react';

type PageActionButtonWrapperType = {
    children: React.ReactNode;
    customStyles?: SxProps;
};

export function PageActionButtonWrapper({ children, customStyles }: PageActionButtonWrapperType) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '50%',
                ...customStyles,
            }}
        >
            {children}
        </Box>
    );
}
