import type { Params } from 'react-router-dom';

export type TitleForSpecificLocation = {
    title: string;
    location: string | ((params: Readonly<Params<string>>) => string);
};

export function getTitle(
    config: TitleForSpecificLocation[],
    location: string,
    params: Readonly<Params<string>>
) {
    const locationWithoutParams = location.replace(/\?.*/, '');

    const titleBasedOnLocation = config.find(({ location: titleLocation }) => {
        if (typeof titleLocation === 'string') {
            return titleLocation === locationWithoutParams;
        }

        return titleLocation(params) === locationWithoutParams;
    });

    return titleBasedOnLocation?.title || '';
}
