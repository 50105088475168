import { Helmet } from '@dock/react';
import { Outlet } from 'react-router-dom';
import { titlesForSpecificLocations } from '../../configs/titlesForSpecificLocation';
import { useTitleLocation } from '../../common';

export function LocalHelmet() {
    const title = useTitleLocation(titlesForSpecificLocations);

    return (
        <>
            <Helmet title={title} />
            <Outlet />
        </>
    );
}
