import { useMutation } from '@tanstack/react-query';

import type { PayloadWithIdempotencyKey } from '@dock/types-common';
import type {
    BankTransferBankTransferInstrument,
    BankTransferInputBankTransferInstrument,
} from '@dock/types-dock-partner';
import { PartnerServiceError } from '../../../common/PartnerServiceError';
import { queryClient } from '../../client';
import { BANK_TRANSFERS_INSTRUMENTS_KEY } from '../../keys';
import { updateTokensOnMutate } from '../updateTokensOnMutate';
import { newBankTransferInstrument } from '../methods/bankTransfer';

export function useCreateBankTransferInstrument() {
    const { isLoading, data, isError, mutateAsync, isSuccess, error } = useMutation<
        BankTransferBankTransferInstrument,
        PartnerServiceError,
        PayloadWithIdempotencyKey<BankTransferInputBankTransferInstrument>
    >(({ payload, idempotencyKey }) => newBankTransferInstrument(payload, idempotencyKey), {
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [BANK_TRANSFERS_INSTRUMENTS_KEY],
            });
        },
        onMutate: updateTokensOnMutate,
    });

    return {
        data,
        isLoading,
        error,
        mutateAsync,
        isSuccess,
        isError,
    };
}
