import { TableRow, TableCell, Typography } from '@mui/material';

export type A2aTransferTxCollapsableRowProps = {
    externalRef: string | undefined | null;
    description: string;
};

export function A2aTransferTxScheduleCollapsableRow({
    externalRef,
    description,
}: A2aTransferTxCollapsableRowProps) {
    return (
        <TableRow data-testid="collapsedRow">
            <TableCell>
                <Typography data-testid="externalRef" variant="subtitle1">
                    {externalRef}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography data-testid="descriptionTypography" variant="subtitle1">
                    {description}
                </Typography>
            </TableCell>
        </TableRow>
    );
}
