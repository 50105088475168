export default {
    TRANSFER_ID: 'Bank transfer ID',
    ACCOUNT_ID: 'Account ID',
    ACCORDION: {
        TITLE_IBAN: 'IBANs',
        HELPTEXT_IBAN:
            'IBAN (Bank Account Number) - We offer 4 different IBANs for your convenience. To note that the default is the Luxembourg (LU) IBAN which allows SEPA Instant.',
        TITLE_BIC: 'BICs',
        HELPTEXT_BIC:
            'BIC (Bank Identifier Code) - We offer 4 different BICs associated with your IBANs. To note that the default is the Luxembourg IBAN (LU) which allows SEPA Instant.',
    },
    EXTERNAL_REFERENCE: 'External reference',
    TAB: {
        TRANSACTIONS: 'Transactions',
    },
    FILTER: {
        KIND: {
            SEPA: 'Sepa',
            CROSSBORDER: 'Crossborder',
            TARGET2: 'Target 2',
            INTERNAL: 'Internal',
        },
    },
    SEARCH_FOR_TRANSACTION_ID: 'Search for Bank transfer transaction ID',
    TRANSACTIONS_TABLE: {
        TRANSACTION_ID: 'Transaction ID',
        AVAILABLE_AMOUNT: 'Amount',
        CURRENCY: 'Currency',
        CREATED_DATE: 'Created time',
        TYPE: 'Type',
        BANK_TRANSFER_ID: 'Bank transfer ID',
        PAYMENT_DETAILS: 'Payment details',
        COUNTER_PARTY: 'Counterparty',
        PARTY: 'Party',
    },
    DIRECT_DEBIT: 'Direct debit',
    CREDIT_TRANSFER: 'Credit transfer',
    INSTANT_CREDIT_TRANSFER: 'Instant credit transfer',
};
