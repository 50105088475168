export default {
    HOLDERS_SEARCH: 'Search for card holder ID',
    TABS: {
        CARD: {
            LIST: 'Card list',
            HOLDERS: 'Card holders',
        },
    },
    CARDS_TABLE: {
        COLUMNS: {
            CARD_ID: 'Card ID',
            CARD_HOLDER_ID: 'Card holder ID',
            CARD_STATUS: 'Status',
            EXTERNAL_REFERENCE: 'External reference',
            CREATED_TIME: 'Created time',
            EXPIRATION_TIME: 'Expiration time',
        },
    },
    CARD_HOLDERS_TABLE: {
        COLUMNS: {
            CARD_HOLDER_ID: 'Card holder ID',
            NAME_ON_CARD: 'Name on card',
            TENANT_ID: 'Tenant ID',
            IDENTITY_ID: 'Identity ID',
            COUNTRY_CODE: 'Country code',
            PHONE_NUMBER: 'Phone number',
        },
    },
    SOURCE: 'Payment source',
    KIND: 'Kind',
    TYPE: 'Type',
    SEARCH_OPTION: {
        ID: 'Spending control ID',
        CARD_ID: 'Card ID',
        EXTERNAL_REFERENCE: 'External reference',
    },
    HOLDER_SEARCH_OPTION: {
        ID: 'Card holder ID',
        TENANT_ID: 'Tenant ID',
        IDENTITY_ID: 'Identity ID',
        EXTERNAL_REFERENCE: 'External reference',
    },
    CARD_STATUS: {
        ACTIVATED: 'Activated',
        UNACTIVATED: 'Unactivated',
        SUSPENDED: 'Suspended',
        TERMINATED: 'Terminated',
        TERMINATED_OBSOLETE: 'Terminated obsolete',
        LOCKED: 'Locked',
    },
    CREATE_NEW: {
        TITLE: 'Create new card',
        FAILED: 'Card creation failed. Please try again.',
        SUCCESS_PRE: 'New Card ',
        SUCCESS_POST: ' was created successfully.',
    },
    CREATE_CARD: 'Create card',
    CARD_HOLDER_ID: 'Card holder ID',
    BASIC_DETAILS_FORM: {
        ACCOUNT_ID_TOOLTIP:
            'The ID of the account to which card should be linked. Cards must always be linked to the account. In order to spend from card, the account associated needs to have an appropriate balance.',
        PRODUCT_ID_TOOLTIP:
            'Product ID defines the type (virtual, physical) and behavior of a card.',
    },
    LINE_2: 'Line 2',
    LINE_3: 'Line 3',
    QR_CODE_CONTENT: 'QR code content',
    PERSONAL_DETAILS: {
        NOTE: 'These fields are currently only available for physical cards. Go to next step if you are creating a virtual card.',
        LINE_2_TOOLTIP:
            'Use to add specific data to be printed on the card (e.g. human readable Card ID).',
        LINE_3_TOOLTIP: 'Use to add additional data to be printed on the card.',
        QR_CODE_CONTENT_TOOLTIP: 'Input content to generate QR code to be printed on the card.',
    },
    VIRTUAL: 'Virtual',
    PHYSICAL: 'Physical',
};
