import { flow } from 'fp-ts/lib/function';
import { ListCardAuthorizationsData } from '@dock/types-dock-partner';
import { handleErrors } from '../../../../errorService';
import {
    requestFlow,
    getDataAndHeaders,
    RequestAndUnwrap,
    unwrapService,
    ResponseWithContinuationToken,
} from '../../../../utilities';

export type CardAuthListWithToken = ResponseWithContinuationToken<ListCardAuthorizationsData>;

// service
export const fetchCardsAuthorizations = requestFlow(handleErrors)(`v0/cards/authorizations`);
// service & mapper
const fetchAndHandle = fetchCardsAuthorizations<CardAuthListWithToken>(getDataAndHeaders);

// service & mapper && open monad
export const getCardsAuthorizations: RequestAndUnwrap<'get', CardAuthListWithToken> = flow(
    fetchAndHandle,
    unwrapService<CardAuthListWithToken>
);
