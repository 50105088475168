import {
    getTypedParamValue,
    FilterReturnTypes,
    getBasicEmptyFilters,
    useFilters,
    BaseFilterParams,
} from '../../../../common';

export enum BankTransferInstrumentsSearchFields {
    ID = 'id',
    ACCOUNT_ID = 'accountId',
    IBAN = 'iban',
}

export type BankTransfersInstrumentsFiltersURLParams =
    BaseFilterParams<BankTransferInstrumentsSearchFields>;

export type BankTransfersInstrumentsFilters = Partial<
    Pick<BankTransfersInstrumentsFiltersURLParams, 'text' | 'searchBy' | 'sortOrder'>
>;

export const getEmptyFilters = (
    params: URLSearchParams
): BankTransfersInstrumentsFiltersURLParams => ({
    ...getBasicEmptyFilters({ params }),
    searchBy: getTypedParamValue(
        'searchBy',
        params,
        BankTransferInstrumentsSearchFields,
        BankTransferInstrumentsSearchFields.ID
    ),
});

export type BankTransfersInstrumentFilterReturnType = FilterReturnTypes<
    BankTransfersInstrumentsFiltersURLParams,
    BankTransfersInstrumentsFilters,
    BankTransferInstrumentsSearchFields
>;

const customFieldsExtractor = (fields: BankTransfersInstrumentsFiltersURLParams) => ({
    searchBy: fields.searchBy || BankTransferInstrumentsSearchFields.ID,
});

export const useInstrumentsTableFilters = (): BankTransfersInstrumentFilterReturnType =>
    useFilters<BankTransfersInstrumentsFiltersURLParams>(getEmptyFilters, customFieldsExtractor);
