import { FilterModalButtons, FilterModalWrapper } from '@dock/react';
import { FormControl } from '@mui/material';
import { BankTransferTransactionKind } from '@dock/types-dock-partner';
import {
    SimpleDialogProps,
    CheckItems,
    generateKeys,
    useCheckItems,
    useAmounts,
    AmountInputRange,
} from '../../../../common';
import { BankTransferTransactionPopUpFilterParams } from './useBankTransferTransferTxsFilters';
import { transactionKindMap } from './transactionKindMap';
import commonLang from '../../../../lang/common';
import cardsLang from '../../../../lang/cards';

const transactionMethodKeys = generateKeys(transactionKindMap);

export function BankTransferTransactionsFilter({
    isOpen,
    handleClose,
    fields,
    onApply,
}: SimpleDialogProps<BankTransferTransactionPopUpFilterParams>) {
    const [{ minAmount, maxAmount }, setAmount, handleAmountChange] = useAmounts({
        minAmount: fields.minAmount,
        maxAmount: fields.maxAmount,
    });

    const [kind, setSource, onSourceCheck] = useCheckItems<BankTransferTransactionKind>(
        fields.kind || []
    );

    const handleApply = () => {
        onApply({
            ...fields,
            kind,
            minAmount: minAmount || '',
            maxAmount: maxAmount || '',
        });
        handleClose();
    };

    const handleReset = () => {
        setSource([]);
        setAmount({ minAmount: '', maxAmount: '' });
        onApply({
            kind: [],
            minAmount: '',
            maxAmount: '',
        });
        handleClose();
    };

    return (
        <FilterModalWrapper
            handleClose={handleClose}
            isOpen={isOpen}
            title={commonLang.FILTER}
            testId="bankTransferTransactionsFilter"
        >
            <FormControl component="fieldset" variant="standard">
                <CheckItems
                    title={cardsLang.SOURCE}
                    items={kind}
                    keys={transactionMethodKeys}
                    handleChange={onSourceCheck}
                />
            </FormControl>
            <AmountInputRange
                label={commonLang.AMOUNT}
                maxAmount={maxAmount}
                minAmount={minAmount}
                handleChange={handleAmountChange}
                helperText={commonLang.FILTERS.AMOUNT_TEXT}
            />
            <FilterModalButtons
                applyText={commonLang.APPLY}
                resetText={commonLang.RESET_FILTERS}
                onApplyClick={handleApply}
                onResetClick={handleReset}
            />
        </FilterModalWrapper>
    );
}
