import { TaskEither, tryCatch } from 'fp-ts/lib/TaskEither';
import { RawRequestHeaders } from '@dock/common';
import type { Response } from './response';
import {
    interceptorResponse,
    interceptorError,
    decamelizeKeysInterceptorRequest,
} from './interceptors';
import type { HttpInstance } from './instance';

type TaskResult<E, A> = TaskEither<E, A>;

export type Headers = (Record<string, unknown> & RawRequestHeaders) | undefined;

export type RequestType = <R, P>(
    url: string,
    payload?: P,
    headers?: Headers
) => TaskResult<unknown, Response<R>>;

export type Request = {
    get: RequestType;
    post: RequestType;
    put: RequestType;
};

export const http = (instance: HttpInstance): Request => {
    instance.interceptors.response.use(interceptorResponse, interceptorError);
    instance.interceptors.request.use(decamelizeKeysInterceptorRequest);

    return {
        get: (url: string, _, headers?: Headers) =>
            tryCatch(
                () => instance.get(url, { headers: headers || {} }),
                (error) => error
            ),

        post<R, P>(url: string, payload: P, headers?: Headers) {
            return tryCatch(
                () =>
                    instance.post<P, R>(url, payload, {
                        headers: headers || {},
                    }),
                (error) => error
            );
        },

        put<R, P>(url: string, payload: P, headers?: Headers) {
            return tryCatch(
                () =>
                    instance.put<P, R>(url, payload, {
                        headers: headers || {},
                    }),
                (error) => error
            );
        },
    };
};
