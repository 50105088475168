import { TransactionsOverview } from './TransactionsOverview';
import { AuthorizationsOverviewTable } from './components/AuthorizationsTable/AuthorizationsOverviewTable';

export function CardAuthsOverviewPage() {
    return (
        <TransactionsOverview>
            <AuthorizationsOverviewTable />
        </TransactionsOverview>
    );
}
