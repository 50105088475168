import { Menu, Box } from '@mui/material';
import { DockColor } from '@dock/common';
import { NavbarRoutes } from '../types';
import { MenuTitle } from './MenuTitle';
import { MenuItem } from './MenuItem';

type NestedItemsMenuProps = {
    isNavbarOpen: boolean;
    isMenuOpen: boolean;
    anchorEl: HTMLElement | null;
    item: NavbarRoutes;
    handleClose: () => void;
};

export function NestedItemsMenu({
    isNavbarOpen,
    isMenuOpen,
    anchorEl,
    handleClose,
    item,
}: NestedItemsMenuProps) {
    if (!item.children || isNavbarOpen) {
        return null;
    }

    return (
        <Menu
            open={isMenuOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                sx: { backgroundColor: DockColor.PRIMARY_1100, color: '' },
            }}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            data-testid="sidebarMenu"
        >
            <Box
                sx={{
                    minWidth: '240px',
                    padding: '8px 16px',
                }}
            >
                <MenuTitle item={item} />

                {item.children.map((el) => (
                    <MenuItem key={el.name} item={el} handleClose={handleClose} />
                ))}
            </Box>
        </Menu>
    );
}
