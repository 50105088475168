import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../auth';

export function IsUserRedirectRoute({ children }: { children: JSX.Element }) {
    const location = useLocation();
    const { isLoggedIn } = useAuth();
    // Take previous page from location. For redirecting users to this location.
    const from: string = location.state?.from?.pathname || '/';

    if (isLoggedIn) {
        return <Navigate to={from} state={{ from: location }} replace />;
    }

    return children;
}
