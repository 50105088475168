import {
    ListVelocityControlsParams,
    CardsVelocityControlWindowKind,
} from '@dock/types-dock-partner';
import { BaseFilterParams, filterUrlParamsExtractor } from '../../../../../common';

export enum SearchFields {
    id = 'id',
    cardId = 'cardId',
}

export type SCListURLParams = BaseFilterParams<SearchFields> & {
    kind?: CardsVelocityControlWindowKind[];
};

const propertyMap: Record<string, keyof ListVelocityControlsParams> = {
    [SearchFields.id]: 'id',
    [SearchFields.cardId]: 'target_id',
};

const dateUrlParamsExtractor = filterUrlParamsExtractor({
    dateKey: 'datetimes/starts_at',
    // amountKey: 'amount',
});

const customKeyUrlParamsExtractor = dateUrlParamsExtractor<ListVelocityControlsParams>([
    { key: 'window_kind', value: 'kind' },
]);

export const transformSpendingControlListParams = (
    urlParams: SCListURLParams
): ListVelocityControlsParams =>
    customKeyUrlParamsExtractor<SCListURLParams>(propertyMap)(urlParams);
