import { useCallback } from 'react';
import type { IdempotencyKey } from '@dock/types-common';
import { A2ATransfersCreateInstrument } from '@dock/types-dock-partner';
import { Link } from '@mui/material';
import { ActionModalWrapper } from '@dock/react';
import { useCreateA2ATransfer } from '../../../../services';
import { stepsConfig } from './stepsConfig';
import { BasicDetailsStep } from './Form/BasicDetailsStep';
import {
    ExternalReferenceStep,
    generateErrorText,
    generateIdempotencyKey,
    interpolateParams,
    useCreateModal,
    useStatusModals,
    SuccessStatusMessage,
    A2A_DETAILS_TRANSACTIONS_ROUTE,
} from '../../../../common';
import a2aLang from '../../../../lang/a2a';
import { responseFieldsMap } from './responseFieldsMap';

type CreateAccountModalProps = {
    handleClose: () => void;
    isOpen: boolean;
    setOpenFormModal: () => void;
};

type CreateA2AInstrumentFormType = A2ATransfersCreateInstrument & IdempotencyKey;

export function CreateA2ATransferInstrumentModal({
    isOpen,
    handleClose,
    setOpenFormModal,
}: CreateAccountModalProps) {
    const defaultValues: CreateA2AInstrumentFormType = {
        accountId: '',
        definitionId: '',
        externalReference: '',
        holderId: '',
        tenantId: '',
        idempotencyKey: generateIdempotencyKey(),
    };
    const { showSuccessModal, showErrorModal } = useStatusModals();
    const { mutateAsync, isLoading } = useCreateA2ATransfer();
    const {
        formValues,
        handleForm,
        stepper,
        handleErrorResponse,
        handleCloseModal,
        handleChangeData,
    } = useCreateModal<CreateA2AInstrumentFormType>({
        defaultValues,
        closeFormModal: handleClose,
        openFormModal: setOpenFormModal,
    });

    const { activeStep, handleBack, handleNext } = stepper;

    const { accountId, definitionId, externalReference, holderId, tenantId, idempotencyKey } =
        formValues;

    const handleChangeDataClick = () => {
        handleChangeData({ idempotencyKey: generateIdempotencyKey() });
    };

    const handleFormSubmit = async (extReference: string) => {
        try {
            const response = await mutateAsync({
                payload: {
                    accountId,
                    definitionId,
                    externalReference: extReference,
                    holderId: holderId || null,
                    tenantId,
                },
                idempotencyKey,
            });

            handleCloseModal();
            const href = interpolateParams(A2A_DETAILS_TRANSACTIONS_ROUTE, {
                id: response.id,
            });
            showSuccessModal({
                messages: [
                    <SuccessStatusMessage
                        preLabel={a2aLang.CREATE_NEW.SUCCESS_PRE}
                        postLabel={a2aLang.CREATE_NEW.SUCCESS_POST}
                    >
                        <Link href={href}>{response.id}</Link>
                    </SuccessStatusMessage>,
                ],
            });
        } catch (e) {
            handleErrorResponse();

            showErrorModal({
                messages: [a2aLang.CREATE_NEW.FAILED, generateErrorText(e, responseFieldsMap)],
                onChangeClick: handleChangeDataClick,
                onSubmitClick: () => handleFormSubmit(externalReference || ''),
            });
        }
    };

    const handleOnSubmitClick = async (value: string) => {
        handleForm({ externalReference: value });
        await handleFormSubmit(value);
    };

    const renderCorrectStep = useCallback(() => {
        if (activeStep === 0) {
            return (
                <BasicDetailsStep
                    handleNextStep={handleNext}
                    handleForm={handleForm}
                    defaultValues={{
                        accountId,
                        definitionId,
                        holderId: holderId || '',
                        tenantId,
                    }}
                    handleCloseModal={handleCloseModal}
                />
            );
        }

        return (
            <ExternalReferenceStep
                handleBackStep={handleBack}
                defaultValues={{ externalReference }}
                handleFormSubmit={handleOnSubmitClick}
                handleCloseModal={handleCloseModal}
                isLoading={isLoading}
                submitButtonText={a2aLang.CREATE_NEW.SUBMIT_BUTTON}
            />
        );
    }, [activeStep, isLoading]);

    return (
        <ActionModalWrapper
            activeStep={activeStep}
            steps={stepsConfig}
            title={a2aLang.CREATE_NEW.CREATE_A2A_TITLE}
            handleClose={handleCloseModal}
            isOpen={isOpen}
            testId="createA2AModal"
        >
            {renderCorrectStep()}
        </ActionModalWrapper>
    );
}
