import { flow } from 'fp-ts/lib/function';
import type { CreateInstrumentData } from '@dock/types-dock-partner';
import { handleErrors } from '../../../../errorService';
import { getData, requestFlow, RequestAndUnwrap, unwrapService } from '../../../../utilities';

export const createInstrumentHandler = requestFlow(handleErrors)(`v0/a2a_transfers`);

const fetchAndHandle = createInstrumentHandler<CreateInstrumentData>(getData);

export const createInstrument: RequestAndUnwrap<'post', CreateInstrumentData> = flow(
    fetchAndHandle,
    unwrapService<CreateInstrumentData>
);
