import { CreateInstrumentData, A2ATransfersCreateInstrument } from '@dock/types-dock-partner';

import { GenericServiceError } from '@dock/common';
import { createInstrumentHandler } from '../../../client';

export const createA2A = async (
    payload: A2ATransfersCreateInstrument,
    idempotencyKey: string
): Promise<CreateInstrumentData> => {
    const headers = {
        'idempotency-key': idempotencyKey,
    };

    const errorOrResponse = await createInstrumentHandler({ payload, headers });

    if (errorOrResponse instanceof GenericServiceError) {
        throw errorOrResponse;
    }

    return errorOrResponse;
};
