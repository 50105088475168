import TableCell from '@mui/material/TableCell';
import {
    ActionMenuItemProps,
    ActionsMenu,
    AmountCell,
    CollapsableTableElement,
    TextWithCopyIcon,
    ExpandRow,
    useBoolean,
    ViewDetailsLink,
    NavLink,
} from '@dock/react';
import { transformDate } from '@dock/react-util';
import { useParams } from 'react-router-dom';
import { CardsAuthorization } from '@dock/types-dock-partner';
import {
    interpolateParams,
    ENV_VARS,
    transactionMethodsMap,
    cardAuthStatusesMap,
    cardAuthStatusColorMap,
    CARD_AUTHORIZATION_DETAILS_ROUTE,
    StatusChip,
    LinkWithCopyIcon,
    TablesTypography,
    CustomVirtualisationWrapper,
} from '../../../../common';
import { CollapsableCardAuthorizationsRows } from './CollapsableCardAuthorizationsRows';

export type CardAuthorizationsRowProps = Pick<
    CardsAuthorization,
    'id' | 'status' | 'amounts' | 'datetimes' | 'merchant' | 'source'
>;

const collapsableColumns = ['MCC', 'MID', 'Last update time'];

export function CardAuthorizationsRow({
    id,
    status,
    amounts,
    datetimes,
    merchant,
    source,
}: CardAuthorizationsRowProps) {
    const [isOpen, handleCollapsableElement] = useBoolean(false);

    const { id: cardId = '' } = useParams();

    const initiatedTime = transformDate(new Date(datetimes.initiated));
    const lastUpdatedTime = transformDate(new Date(datetimes.lastUpdated));
    const method = transactionMethodsMap[source.method] || source.method;

    const cardAuthorizationLink = interpolateParams(CARD_AUTHORIZATION_DETAILS_ROUTE, {
        id: cardId,
        authorizationId: id,
    });

    const CardTransactionsTableActions: ActionMenuItemProps[] = [
        {
            content: <NavLink to={cardAuthorizationLink} label={<ViewDetailsLink />} />,
            id: '0',
        },
    ];

    return (
        <>
            <CustomVirtualisationWrapper
                index={id}
                testId="cardAuthorizationsRows"
                isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
            >
                <TableCell>
                    <ExpandRow isOpen={isOpen} onClick={handleCollapsableElement} />
                </TableCell>
                <TableCell>
                    <TextWithCopyIcon text={id}>
                        <LinkWithCopyIcon
                            href={cardAuthorizationLink}
                            ellipsis="start"
                            text={id}
                            testId="cardAuthorizationId"
                        />
                    </TextWithCopyIcon>
                </TableCell>
                <TableCell>
                    <StatusChip
                        color={cardAuthStatusColorMap[status]}
                        label={cardAuthStatusesMap[status] || status}
                    />
                </TableCell>
                <AmountCell amount={amounts.approved.amount} />
                <TableCell data-testid="currency">
                    <TablesTypography content={amounts.approved.currency} />
                </TableCell>
                <TableCell data-testid="initiatedTime">
                    <TablesTypography content={initiatedTime} />
                </TableCell>
                <TableCell data-testid={`method${method}`}>
                    <TablesTypography content={method} />
                </TableCell>
                <TableCell data-testid="merchantName">
                    <TablesTypography content={merchant.name || ''} />
                </TableCell>
                <TableCell>
                    <ActionsMenu menuItems={CardTransactionsTableActions} />
                </TableCell>
            </CustomVirtualisationWrapper>

            <CollapsableTableElement
                isOpen={isOpen}
                columns={collapsableColumns}
                RowComponent={
                    <CollapsableCardAuthorizationsRows
                        lastUpdated={lastUpdatedTime}
                        mcc={merchant.mcc || ''}
                        mid={merchant.mid || ''}
                    />
                }
            />
        </>
    );
}
