import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { TypographyWithTooltip, ControlledInput, FormActionButtons } from '@dock/react';
import type { z } from 'zod';
import { gridInput } from '@dock/react-mui';
import type { CreateAccountFormType } from '../CreateAccountModal';
import accountsLang from '../../../../../lang/accounts';
import commonLang from '../../../../../lang/common';
import {
    createAccountBasicDetailsStepSchema,
    createAccountBasicDetailsStepSchemaKeys,
} from './validation';

type CreateAccountBasicDetailsStepFormValues = z.infer<typeof createAccountBasicDetailsStepSchema>;

type BasicDetailsStepProps = {
    handleForm: (values: Partial<CreateAccountFormType>) => void;
    handleNextStep: () => void;
    defaultValues: CreateAccountBasicDetailsStepFormValues;
    handleCloseModal: () => void;
};

export function BasicDetailsStep({
    handleForm,
    handleNextStep,
    defaultValues,
    handleCloseModal,
}: BasicDetailsStepProps) {
    const { control, handleSubmit, watch } = useForm({
        defaultValues,
        resolver: zodResolver(createAccountBasicDetailsStepSchema),
    });

    const onSubmitHandler = (values: CreateAccountBasicDetailsStepFormValues) => {
        handleForm(values);
        handleNextStep();
    };

    const isSubmitButtonDisabled = Object.values(watch()).some((value) => !value);

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid container data-testid="basicDetailsStep">
                <Grid item xs={11} sx={gridInput}>
                    <ControlledInput
                        control={control}
                        label={commonLang.TENANT_ID}
                        name={createAccountBasicDetailsStepSchemaKeys.tenantId}
                        isFullWidth
                        testId="tenantIdInput"
                    />
                </Grid>

                <Grid item xs={11} sx={gridInput}>
                    <ControlledInput
                        control={control}
                        label={accountsLang.ACCOUNT_HOLDER_ID}
                        name={createAccountBasicDetailsStepSchemaKeys.accountHolderId}
                        isFullWidth
                        testId="holderIdInput"
                    />
                </Grid>

                <Grid item xs={11} sx={gridInput}>
                    <ControlledInput
                        control={control}
                        label={accountsLang.ACCOUNT_LABEL}
                        name={createAccountBasicDetailsStepSchemaKeys.label}
                        isFullWidth
                        testId="accountNameInput"
                    />
                </Grid>

                <Grid item xs={1} sx={gridInput}>
                    <TypographyWithTooltip
                        text={accountsLang.CREATE_NEW.BASIC_DETAILS_FORM.ACCOUNT_NAME_TOOLTIP}
                        variant="body2"
                        isWithIcon
                    />
                </Grid>

                <Grid item xs={11} sx={gridInput}>
                    <ControlledInput
                        control={control}
                        label={commonLang.PRODUCT_ID}
                        name={createAccountBasicDetailsStepSchemaKeys.productId}
                        isFullWidth
                        testId="productIdInput"
                    />
                </Grid>

                <Grid item xs={1} sx={gridInput}>
                    <TypographyWithTooltip
                        text={accountsLang.CREATE_NEW.BASIC_DETAILS_FORM.PRODUCT_ID_TOOLTIP}
                        variant="body2"
                        isWithIcon
                    />
                </Grid>
            </Grid>

            <FormActionButtons
                onCancelHandler={handleCloseModal}
                isSubmitDisabled={isSubmitButtonDisabled}
                submitButtonText={commonLang.NEXT}
            />
        </form>
    );
}
