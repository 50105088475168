import { CardsCardStatus } from '@dock/types-dock-partner';
import cardDetailsLang from '../../../../lang/cardDetails';

export const cardStatusMap: Record<CardsCardStatus, string> = {
    ACTIVATED: cardDetailsLang.CHANGE_CARD_STATUSES.ACTIVATED,
    UNACTIVATED: cardDetailsLang.CHANGE_CARD_STATUSES.UNACTIVATED,
    LOCKED: cardDetailsLang.CHANGE_CARD_STATUSES.LOCKED,
    SUSPENDED: cardDetailsLang.CHANGE_CARD_STATUSES.SUSPENDED,
    TERMINATED: cardDetailsLang.CHANGE_CARD_STATUSES.TERMINATED,
    TERMINATED_OBSOLETE: cardDetailsLang.CHANGE_CARD_STATUSES.TERMINATED_OBSOLETE,
};
