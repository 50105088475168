import {
    UsersCapabilityAction,
    UsersCapabilityObject,
    A2ATransfersInstrumentStatus,
    A2ATransfersSetInstrumentStatus,
} from '@dock/types-dock-partner';
import { hasCapability } from '../../../services/user/hasCapability';
import commonLang from '../../../lang/common';
import { a2aStatusMap } from '../components/ChangeStatus/a2aStatusMap';

export const a2aDetailsBL = {
    isReadA2APaymentAvailable: () =>
        hasCapability(UsersCapabilityObject.A2APAYMENT, UsersCapabilityAction.READ),
    isCreateA2APaymentAvailable: () =>
        hasCapability(UsersCapabilityObject.A2APAYMENT, UsersCapabilityAction.CREATE),
    isChangeA2AStatusAvailable: () =>
        hasCapability(UsersCapabilityObject.A2ATRANSFERSTATUS, UsersCapabilityAction.UPDATE),
    isEditA2AStatusDisabled: (status: A2ATransfersInstrumentStatus | null | undefined) =>
        !!status && status === A2ATransfersInstrumentStatus.TERMINATED_OBSOLETE,
    getWarningText: (status: A2ATransfersInstrumentStatus | A2ATransfersSetInstrumentStatus) => {
        if (
            !!status &&
            (status === A2ATransfersInstrumentStatus.TERMINATED_OBSOLETE ||
                status === A2ATransfersInstrumentStatus.TERMINATED)
        ) {
            return commonLang.GET_CHANGE_STATUS_WARNING_MESSAGE(a2aStatusMap[status]);
        }

        return '';
    },
};
