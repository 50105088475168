import { transformDate } from '@dock/react-util';
import { TableRow, TableCell, Typography } from '@mui/material';

type CollapsableCardAuthorizationsRowsProps = {
    mcc: string;
    mid: string;
    lastUpdated: string;
};

export function CollapsableCardAuthorizationsRows({
    mcc,
    mid,
    lastUpdated,
}: CollapsableCardAuthorizationsRowsProps) {
    return (
        <TableRow>
            <TableCell data-testid="mcc">
                <Typography variant="subtitle1">{mcc}</Typography>
            </TableCell>
            <TableCell data-testid="mid">
                <Typography variant="subtitle1">{mid}</Typography>
            </TableCell>
            <TableCell data-testid="lastUpdated">
                <Typography variant="subtitle1">{transformDate(new Date(lastUpdated))}</Typography>
            </TableCell>
        </TableRow>
    );
}
