import { ListA2ATransactionsParams } from '@dock/types-dock-partner';
import { BaseFilterParams, dateAndAmountFilterUrlParamsExtractor } from '../../../../common';

export type A2ATransactionFiltersURLParams = BaseFilterParams<A2ATransactionsSearchFields>;

export enum A2ATransactionsSearchFields {
    transactionId = 'transactionId',
    paymentId = 'paymentId',
    externalReference = 'externalReference',
}

const propertyMap: Record<string, keyof ListA2ATransactionsParams> = {
    [A2ATransactionsSearchFields.transactionId]: 'id',
    [A2ATransactionsSearchFields.paymentId]: 'payment_id',
    [A2ATransactionsSearchFields.externalReference]: 'external_reference',
};

const customKeyUrlParamsExtractor =
    dateAndAmountFilterUrlParamsExtractor()<ListA2ATransactionsParams>([
        { key: 'party/instrument_id', value: 'instrumentId' },
    ]);

type ExtendedA2ATransactionFilterUrlParams = A2ATransactionFiltersURLParams & {
    instrumentId?: string | undefined;
};
export const transformA2ATransactionsParams =
    customKeyUrlParamsExtractor<ExtendedA2ATransactionFilterUrlParams>(propertyMap);
