import {
    A2ATransfersUpdateInstrumentStatus,
    UpdateInstrumentStatusData,
} from '@dock/types-dock-partner';

import { GenericServiceError } from '@dock/common';
import { setA2AStatus } from '../../../client';
import { generateIdempotencyKey } from '../../../../common';

type ChangeA2AStatusParams = {
    payload: A2ATransfersUpdateInstrumentStatus;
    id: string;
};

export const changeA2AStatus = async ({
    payload,
    id,
}: ChangeA2AStatusParams): Promise<UpdateInstrumentStatusData> => {
    const headers = {
        'idempotency-key': generateIdempotencyKey(),
    };

    const errorOrResponse = await setA2AStatus(id)({ payload, headers });

    if (errorOrResponse instanceof GenericServiceError) {
        throw errorOrResponse;
    }

    return errorOrResponse;
};
