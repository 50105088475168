import { DateRangeDropDown, FilterButton, SearchBox, SimpleTable } from '@dock/react';
import { prefillDatesFromAlways } from '@dock/common';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useModal } from '@dock/react-hooks';
import { useCardAuthorizations } from '../../../../services';
import { CardAuthorizationsRow } from './CardAuthorizationsRow';
import { cardAuthorizationsTableColumns } from './cardAuthorizationsTableColumns';
import {
    CardAuthsSearchFields,
    transformCardAuthsParams,
    useCardAuthsFilters,
    CardAuthorizationsFilter,
    TableFiltersWrapper,
    filterWrapperStyles,
} from '../../../../common';

import cardsDetailsLang from '../../../../lang/cardDetails';

export function CardAuthorizationsTable() {
    const { id: cardId = '' } = useParams();

    const { isOpen, setClose, setOpen } = useModal();

    const {
        filters,
        handleDateFilter,
        handlePopUpFilter,
        handleSearch,
        isPopFilterActive,
        sorting,
        handleSortOrder,
    } = useCardAuthsFilters(CardAuthsSearchFields.authorizationId);

    const { data, isFetching, error, fetchNextPage, isFetchingNextPage } = useCardAuthorizations({
        params: transformCardAuthsParams({ ...filters, cardId }),
    });

    const [dateFrom, dateTo] = prefillDatesFromAlways(filters.from, filters.to);

    return (
        <>
            <TableFiltersWrapper>
                <SearchBox
                    label={cardsDetailsLang.SEARCH_BY_AUTHORIZATION_ID}
                    setLastSearchValue={handleSearch}
                    initValue={filters.text || ''}
                />
                <Box sx={{ ...filterWrapperStyles }}>
                    <DateRangeDropDown
                        handleFilters={handleDateFilter}
                        from={dateFrom}
                        to={dateTo}
                    />

                    <FilterButton isFilterOn={isPopFilterActive} onClick={setOpen} />
                </Box>
            </TableFiltersWrapper>
            <CardAuthorizationsFilter
                isOpen={isOpen}
                handleClose={setClose}
                onApply={handlePopUpFilter(setOpen)}
                fields={{
                    status: filters.status || [],
                    sourceMethod: filters.sourceMethod || [],
                    maxAmount: filters.maxAmount || '',
                    minAmount: filters.minAmount || '',
                }}
            />
            <SimpleTable
                columns={cardAuthorizationsTableColumns}
                RowComponent={CardAuthorizationsRow}
                isLoading={isFetching}
                rows={data}
                error={error}
                isLoadingNextPage={isFetchingNextPage}
                onReachingBottom={fetchNextPage}
                sorting={sorting}
                onSortClick={handleSortOrder}
            />
        </>
    );
}
