import type { A2ATransfersHolder } from '@dock/types-dock-partner';
import { flow } from 'fp-ts/lib/function';
import { handleErrors } from '../../../../errorService';
import {
    getDataAndHeaders,
    requestFlow,
    RequestAndUnwrap,
    unwrapService,
    ResponseWithContinuationToken,
} from '../../../../utilities';

export type ListInstrumentsHoldersResponse = ResponseWithContinuationToken<A2ATransfersHolder[]>;
export const listInstrumentsHoldersHandler = requestFlow(handleErrors)(`v0/a2a_transfers/holders`);

const fetchAndHandle = <L>() => listInstrumentsHoldersHandler<L>(getDataAndHeaders);

export const listInstrumentsHolders: RequestAndUnwrap<'get', ListInstrumentsHoldersResponse> = flow(
    fetchAndHandle(),
    unwrapService<ListInstrumentsHoldersResponse>
);

export const CreateInstrumentsHolders: RequestAndUnwrap<'post', A2ATransfersHolder> = flow(
    fetchAndHandle(),
    unwrapService<A2ATransfersHolder>
);
