import { useMutation } from '@tanstack/react-query';

import { PayloadWithIdempotencyKey } from '@dock/types-common';
import { CardsCreateVelocityControl, CreateVelocityControlData } from '@dock/types-dock-partner';
import { PartnerServiceError } from '../../../common/PartnerServiceError';
import { queryClient } from '../../client';
import { VELOCITY_CONTROL_KEY } from '../../keys';
import { updateTokensOnMutate } from '../updateTokensOnMutate';
import { createCardVC } from '../methods/cards/createCardVC';

export function useCreateCardVC() {
    return useMutation<
        CreateVelocityControlData,
        PartnerServiceError,
        PayloadWithIdempotencyKey<CardsCreateVelocityControl>
    >((variables) => createCardVC(variables.payload, variables.idempotencyKey), {
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [VELOCITY_CONTROL_KEY],
            });
        },
        onMutate: updateTokensOnMutate,
    });
}
