import {
    UsersUserCapability,
    UsersCapabilityAction,
    UsersCapabilityObject,
} from '@dock/types-dock-partner';

export const hasCapability = (
    object: UsersCapabilityObject,
    action: UsersCapabilityAction
): boolean => {
    // TODO: remove hardcoded values after BE fix and use getCapabilitiesFromStorage
    const capabilities: UsersUserCapability[] = [
        {
            actions: [UsersCapabilityAction.CREATE],
            object: UsersCapabilityObject.CARD,
        },
        {
            actions: [UsersCapabilityAction.READ],
            object: UsersCapabilityObject.ACCOUNT,
        },
        {
            actions: [UsersCapabilityAction.UPDATE],
            object: UsersCapabilityObject.CARD_STATUS,
        },
    ];

    if (!Array.isArray(capabilities)) {
        return false;
    }

    return capabilities.some(
        (capability) => capability.object === object && capability.actions.includes(action)
    );
};
