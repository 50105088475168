import {
    Column,
    DateRangeDropDown,
    FilterButton,
    OptionType,
    MultipleChoiceSearch,
    SimpleTable,
    ColumnAlign,
} from '@dock/react';
import { Box } from '@mui/material';
import { prefillDatesFromAlways } from '@dock/common';
import { useModal } from '@dock/react-hooks';
import { SpendingControlTableRow } from './SpendingControlTableRow';
import { useSpendingControlListFilters } from './useSpendingControlListFilters';
import { useVelocityControl } from '../../../../../services';
import { filterWrapperStyles, TableFiltersWrapper } from '../../../../../common';
import {
    SearchFields,
    transformSpendingControlListParams,
} from './transformSpendingControlListParams';
import { SpendingControlListFilters } from './SpendingControlListFilter';
import spendingControlLang from '../../../../../lang/spendingControl';
import commonLang from '../../../../../lang/common';
import cardsLang from '../../../../../lang/cards';

export const tableColumns: Column[] = [
    { key: 'expand', name: '' },
    { key: 'id', name: spendingControlLang.ID },
    { key: 'amount', name: commonLang.AMOUNT, align: ColumnAlign.RIGHT },
    { key: 'currency', name: spendingControlLang.CURRENCY },
    { key: 'frequency', name: spendingControlLang.FREQUENCY_TYPE },
    { key: 'effectiveFrom', name: spendingControlLang.FROM },
    { key: 'effectiveUntil', name: spendingControlLang.UNTIL },
    { key: 'action', name: '' },
];

const searchOptions: OptionType<SearchFields>[] = [
    {
        label: cardsLang.SEARCH_OPTION.ID,
        value: SearchFields.id,
    },
    {
        label: cardsLang.SEARCH_OPTION.CARD_ID,
        value: SearchFields.cardId,
    },
];

export function SpendingControlListTable() {
    const { isOpen, setClose, setOpen } = useModal();

    const {
        filters,
        handlePopUpFilter,
        handleSearch,
        handleDateFilter,
        handleSearchSelect,
        sorting,
        handleSortOrder,
    } = useSpendingControlListFilters(SearchFields.id);

    const { data, error, fetchNextPage, isFetching, isFetchingNextPage } = useVelocityControl({
        params: transformSpendingControlListParams(filters),
    });

    const [dateFrom, dateTo] = prefillDatesFromAlways(filters.from, filters.to);

    return (
        <>
            <TableFiltersWrapper>
                <MultipleChoiceSearch
                    label={commonLang.SEARCH}
                    initValue={filters.text || ''}
                    handleSearch={handleSearch}
                    onChange={handleSearchSelect}
                    searchBy={filters.searchBy || SearchFields.cardId}
                    options={searchOptions}
                />
                <Box
                    sx={{
                        ...filterWrapperStyles,
                        justifyContent: 'flex-end',
                    }}
                >
                    <DateRangeDropDown
                        handleFilters={handleDateFilter}
                        from={dateFrom}
                        to={dateTo}
                    />
                    <FilterButton isFilterOn={!!filters.kind?.some((e) => !!e)} onClick={setOpen} />
                </Box>
            </TableFiltersWrapper>
            <SpendingControlListFilters
                onApply={handlePopUpFilter(setOpen)}
                handleClose={setClose}
                isOpen={isOpen}
                fields={{
                    kind: filters.kind || [],
                }}
            />
            <SimpleTable
                columns={tableColumns}
                RowComponent={SpendingControlTableRow}
                isLoading={isFetching}
                rows={data}
                isLoadingNextPage={isFetchingNextPage}
                onReachingBottom={fetchNextPage}
                error={error}
                sorting={sorting}
                onSortClick={handleSortOrder}
            />
        </>
    );
}
