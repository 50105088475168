import { Box } from '@mui/material';
import { NotFoundBaseWrapper, PrimaryButton, PrimaryTextButton } from '@dock/react';
import { useNavigate } from 'react-router-dom';
import notFoundLang from '../../lang/notFound';
import { ACCOUNTS_ROUTE } from '../../common';

export function NotFound() {
    const navigate = useNavigate();

    return (
        <NotFoundBaseWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                <PrimaryButton onClick={() => navigate(-1)}>
                    {notFoundLang.GO_TO_PREV_PAGE}
                </PrimaryButton>
                <PrimaryTextButton onClick={() => navigate(ACCOUNTS_ROUTE)}>
                    {notFoundLang.GO_TO_HOME_PAGE}
                </PrimaryTextButton>
            </Box>
        </NotFoundBaseWrapper>
    );
}
