import React from 'react';
import { Typography } from '@mui/material';

type SuccessStatusMessageProps = {
    preLabel: string;
    postLabel: string;
    children: React.ReactNode;
};

export function SuccessStatusMessage({ preLabel, postLabel, children }: SuccessStatusMessageProps) {
    return (
        <Typography data-testid="successStatusMessage">
            {preLabel}
            {children}
            {postLabel}
        </Typography>
    );
}
