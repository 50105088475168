import { transformDate } from '@dock/react-util';
import { paymentMethodMap } from '../paymentMethodMap';
import { AccountTransactionCsv, AccountTransactionTableRow } from './types';
import { getPaymentMethodLink, getPaymentMethodTransactionLink } from '../../../../common';
import { ExtendedAccountTransaction } from '../../../../services/queries/getTXsWithCounterparty';

export const formatTx = (tx: ExtendedAccountTransaction): AccountTransactionCsv => ({
    bookingTime: transformDate(new Date(tx.datetimes.valueDated)),
    id: tx.id,
    paymentInstrument: paymentMethodMap[tx.source.method] || tx.source.method,
    instrumentId: tx.source.instrumentId,
    instrumentTxId: tx.source.transactionId,
    counterparty: tx.counterparty,
    amount: tx.amount.amount,
    balance: tx.balances.totalAfter.amount,
    currency: tx.amount.currency,
});

// This is the mapper for table row only. Because it should have some additional data as links etc
export const accountTxsRowMapper = (
    txs: ExtendedAccountTransaction[]
): AccountTransactionTableRow[] =>
    txs.map((tx) => {
        const transactionLink = getPaymentMethodLink(tx.source.method, tx.source.instrumentId);

        const transactionDetailLink = getPaymentMethodTransactionLink({
            ...tx.source,
        });
        return {
            ...formatTx(tx),
            transactionLink,
            transactionDetailLink,
        };
    });
