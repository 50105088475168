import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Sorting, SortOrder } from '@dock/types-common';
import { AccountsPaymentMethod } from '@dock/types-dock-partner';
import { DEFAULT_SORT_BY } from '@dock/common';
import {
    BaseFilterParams,
    getSortOrderURLParam,
    getTypedParamValue,
    getUrlParam,
    useWriteSearchParams,
} from '../../../../common';

export type AccountPaymentMethodFiltersURLParams = BaseFilterParams<string> & {
    sourceMethod?: AccountsPaymentMethod;
};

export const getEmptyFilters = (
    params: URLSearchParams,
    defaultSourceMethod: AccountsPaymentMethod
): AccountPaymentMethodFiltersURLParams => ({
    sortOrder: getSortOrderURLParam(params),
    sortBy: getUrlParam('sortBy', params) || DEFAULT_SORT_BY,
    sourceMethod: getTypedParamValue(
        'sourceMethod',
        params,
        AccountsPaymentMethod,
        defaultSourceMethod
    ),
});

type AccountPaymentMethodFilterReturnType = {
    filters: AccountPaymentMethodFiltersURLParams;
    handleSelect: (value: AccountsPaymentMethod) => void;
    sorting: Sorting;
    handleSortOrder: (property: string) => void;
};

export const useAccountPaymentMethodFilters = (
    defaultSourceMethod: AccountsPaymentMethod
): AccountPaymentMethodFilterReturnType => {
    const [searchParams] = useSearchParams();
    const initialParams = getEmptyFilters(searchParams, defaultSourceMethod);

    const [filters, setFilters] = useState<AccountPaymentMethodFiltersURLParams>(initialParams);

    const handleFilters = (fields: AccountPaymentMethodFiltersURLParams) => {
        setFilters({
            ...filters,
            sourceMethod: fields.sourceMethod || defaultSourceMethod,
            sortOrder: fields.sortOrder || SortOrder.DESC,
            sortBy: fields.sortBy || DEFAULT_SORT_BY,
        });
    };

    const handleSelect = (value: AccountsPaymentMethod) => {
        handleFilters({
            ...initialParams,
            sourceMethod: value,
        });
    };

    const handleSortOrder = (sortingColumn: string) => {
        const isAsc = filters.sortBy === sortingColumn && filters.sortOrder === SortOrder.ASC;

        handleFilters({
            ...initialParams,
            sortBy: sortingColumn,
            sortOrder: isAsc ? SortOrder.DESC : SortOrder.ASC,
        });
    };

    useWriteSearchParams(filters);

    return {
        filters,
        handleSelect,
        sorting: {
            field: filters.sortBy || DEFAULT_SORT_BY,
            direction: filters.sortOrder || SortOrder.DESC,
        },
        handleSortOrder,
    };
};
