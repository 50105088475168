import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { changeA2AStatusSchema, changeA2AStatusSchemaKeys } from '@dock/validation';
import { useParams } from 'react-router-dom';
import { useAlertNotifications, ControlledSingleSelect } from '@dock/react';
import {
    A2ATransfersInstrumentStatus,
    A2ATransfersSetInstrumentStatus,
} from '@dock/types-dock-partner';
import commonLang from '../../../../lang/common';
import cardDetailsLang from '../../../../lang/cardDetails';
import a2aDetailsLang from '../../../../lang/a2aDetails';
import { useChangeA2AStatus } from '../../../../services/mutations/hooks/useChangeA2AStatus';
import { a2aStatusOptions } from './a2aStatusOptions';
import { a2aDetailsBL } from '../../BL/BL';
import { ChangeStatusModalButtons } from '../../../../common';

type ChangeStateCardFormValues = {
    [changeA2AStatusSchemaKeys.status]: A2ATransfersSetInstrumentStatus;
};

type ChangeStatusFormProps = {
    onClose: () => void;
    currentStatus: A2ATransfersInstrumentStatus | A2ATransfersSetInstrumentStatus;
};

export function ChangeStatusForm({ onClose, currentStatus }: ChangeStatusFormProps) {
    const { id = '' } = useParams();
    const { showSuccessNotification, showErrorNotification } = useAlertNotifications();

    const methods = useForm<ChangeStateCardFormValues>({
        defaultValues: {
            status:
                // Should have this hack here as we should show to user all statuses enum A2ATransfersInstrumentStatus but user may change only enum A2ATransfersSetInstrumentStatus
                (currentStatus as A2ATransfersSetInstrumentStatus) ||
                A2ATransfersInstrumentStatus.TERMINATED,
        },
        resolver: zodResolver(changeA2AStatusSchema),
    });

    const { control, handleSubmit } = methods;

    const { isLoading, mutateAsync } = useChangeA2AStatus(id);

    const onSubmitHandler = async ({ status }: ChangeStateCardFormValues) => {
        try {
            await mutateAsync({ status });

            showSuccessNotification({ title: a2aDetailsLang.STATUS_CHANGED });
            onClose();
        } catch (e) {
            showErrorNotification({
                title: a2aDetailsLang.STATUS_CHANGE_FAILED,
            });
        }
    };

    const selectedStatus = methods.watch()[changeA2AStatusSchemaKeys.status];

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <ControlledSingleSelect
                    control={control}
                    label={commonLang.STATUS}
                    name={changeA2AStatusSchemaKeys.status}
                    options={a2aStatusOptions}
                    defaultOption={currentStatus}
                    helperText={a2aDetailsBL.getWarningText(selectedStatus)}
                />
                <ChangeStatusModalButtons
                    secondaryLabel={commonLang.CANCEL}
                    primaryLabel={cardDetailsLang.CHANGE_STATUS}
                    isLoading={isLoading}
                    onClose={onClose}
                />
            </form>
        </FormProvider>
    );
}
