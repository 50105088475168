import { Column, ColumnAlign } from '@dock/react';
import cardsAuthsLang from '../../../lang/cardAuthorizationDetails';
import commonLang from '../../../lang/common';

export const authEventsTableColumns: Column[] = [
    { key: 'expand', name: '' },
    {
        key: 'type',
        name: commonLang.TYPE,
    },
    {
        key: 'status',
        name: commonLang.STATUS,
    },
    {
        key: 'reason',
        name: commonLang.REASON,
    },
    {
        key: 'amount',
        name: commonLang.AMOUNT,
        align: ColumnAlign.RIGHT,
        tooltip: cardsAuthsLang.AMOUNT_DESCRIPTION,
    },
    {
        key: 'currency',
        name: commonLang.CURRENCY,
    },
    {
        key: 'initiatedTime',
        name: commonLang.INITIATED_TIME,
        sortable: true,
    },
    {
        key: 'completedTime',
        name: commonLang.COMPLETED_TIME,
    },
];

export const collapsableRowColumns: Column[] = [
    {
        key: 'authEventId',
        name: cardsAuthsLang.AUTH_EVENT_ID,
    },
    {
        key: 'approvedAmount',
        name: cardsAuthsLang.APPROVED_AMOUNT,
    },
    {
        key: 'approvedCurrency',
        name: cardsAuthsLang.APPROVED_CURRENCY,
    },
    {
        key: 'requestedAmount',
        name: cardsAuthsLang.REQUESTED_AMOUNT,
    },
    {
        key: 'requestedCurrency',
        name: cardsAuthsLang.REQUESTED_CURRENCY,
    },
    {
        key: 'currencyConversion',
        name: cardsAuthsLang.CURRENCY_CONVERSION,
    },
];
