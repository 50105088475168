import { zodResolver } from '@hookform/resolvers/zod';
import type { z } from 'zod';
import { gridInput } from '@dock/react-mui';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { TypographyWithTooltip, ControlledInput, FormActionButtons } from '@dock/react';
import {
    createBankTransferBasicDetailsStepSchema,
    createBankTransferBasicDetailsStepSchemaKeys,
} from '@dock/validation';
import type { CreateBankTransferFormType } from '../CreateBankTransferModal';
import commonLang from '../../../../../lang/common';
import bankTransfersLang from '../../../../../lang/bankTransfers';

export type BasicDetailsStepFormValues = z.infer<typeof createBankTransferBasicDetailsStepSchema>;

type CreateBankTransferBasicDetailsStepProps = {
    handleForm: (values: Partial<CreateBankTransferFormType>) => void;
    handleNextStep: () => void;
    defaultValues: BasicDetailsStepFormValues;
    handleCloseModal: () => void;
};

export function BasicDetailsStep({
    handleForm,
    handleNextStep,
    defaultValues,
    handleCloseModal,
}: CreateBankTransferBasicDetailsStepProps) {
    const { control, handleSubmit, watch } = useForm({
        defaultValues,
        resolver: zodResolver(createBankTransferBasicDetailsStepSchema),
    });

    const onSubmitHandler = (values: BasicDetailsStepFormValues) => {
        handleForm(values);
        handleNextStep();
    };

    const isSubmitButtonDisabled = Object.values(watch()).some((value) => !value);

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid container data-testid="basicDetailsStep">
                <Grid item xs={11} sx={gridInput}>
                    <ControlledInput
                        control={control}
                        label={commonLang.ACCOUNT_ID}
                        name={createBankTransferBasicDetailsStepSchemaKeys.accountId}
                        isFullWidth
                        testId="accountIdInput"
                    />
                </Grid>
                <Grid item xs={1} sx={gridInput}>
                    <TypographyWithTooltip
                        text={bankTransfersLang.CREATE_NEW.ACCOUNT_ID_TOOLTIP}
                        variant="body2"
                        isWithIcon
                    />
                </Grid>
                <Grid item xs={11} sx={gridInput}>
                    <ControlledInput
                        control={control}
                        label={bankTransfersLang.DEFINITION_ID}
                        name={createBankTransferBasicDetailsStepSchemaKeys.definitionId}
                        isFullWidth
                        testId="definitionIdInput"
                    />
                </Grid>
                <Grid item xs={1} sx={gridInput}>
                    <TypographyWithTooltip
                        text={bankTransfersLang.CREATE_NEW.DEFINITION_ID_TOOLTIP}
                        variant="body2"
                        isWithIcon
                    />
                </Grid>
                <Grid item xs={11} sx={gridInput}>
                    <ControlledInput
                        control={control}
                        label={bankTransfersLang.HOLDER_ID}
                        name={createBankTransferBasicDetailsStepSchemaKeys.holderId}
                        isFullWidth
                        testId="holderIdInput"
                    />
                </Grid>
                <Grid item xs={1} sx={gridInput}>
                    <TypographyWithTooltip
                        text={bankTransfersLang.CREATE_NEW.HOLDER_ID_TOOLTIP}
                        variant="body2"
                        isWithIcon
                    />
                </Grid>
                <Grid item xs={11} sx={gridInput}>
                    <ControlledInput
                        control={control}
                        label={commonLang.TENANT_ID}
                        name={createBankTransferBasicDetailsStepSchemaKeys.tenantId}
                        isFullWidth
                        testId="tenantIdInput"
                    />
                </Grid>
            </Grid>

            <FormActionButtons
                onCancelHandler={handleCloseModal}
                isSubmitDisabled={isSubmitButtonDisabled}
                submitButtonText={commonLang.NEXT}
            />
        </form>
    );
}
