import { FormLabel, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { ChangeEvent } from 'react';

export type CheckItemKey = {
    label: string;
    value: string;
};

type CheckItemsProps = {
    title: string;
    items: Array<string>;
    keys: CheckItemKey[];
    handleChange: (htmlCheck: ChangeEvent<HTMLInputElement>) => void;
};

export function CheckItems({ title, items, keys, handleChange }: CheckItemsProps) {
    return (
        <>
            <FormLabel component="legend">
                <Typography variant="h4">{title}</Typography>
            </FormLabel>
            <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                {keys.map(({ label, value }) => (
                    <FormControlLabel
                        key={label}
                        control={
                            <Checkbox
                                checked={items.includes(value)}
                                onChange={handleChange}
                                name={label}
                                value={value}
                                data-testid={label}
                            />
                        }
                        label={label}
                    />
                ))}
            </FormGroup>
        </>
    );
}
