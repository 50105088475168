import {
    CardsAuthorizationStatus,
    CardsTransactionStatus,
    CardsTransactionKind,
    CardsMethod,
    AccountsPaymentMethod,
    AccountsTransactionSource,
} from '@dock/types-dock-partner';
import { SortOrder } from '@dock/types-common';
import { generateUUID, GenericServiceError } from '@dock/common';
import { IDEMPOTENCY_KEY_DATE, transformDate } from '@dock/react-util';
import { Params } from 'react-router-dom';
import commonLang from '../lang/common';
import {
    CARD_DETAILS_TRANSACTIONS_ROUTE,
    A2A_DETAILS_TRANSACTIONS_ROUTE,
    CARD_TRANSACTION_DETAILS_ROUTE,
    A2A_DETAILS_TRANSACTIONS_DETAILS_ROUTE,
    BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE,
    BANK_TRANSFERS_TRANSACTION_DETAIL_ROUTE,
} from './constants';
import { BaseFilterParams } from './types';

export type CardTransactionsFilterURLParams = BaseFilterParams<CardTransactionsSearchFields> & {
    sourceMethod?: CardsMethod[];
    status?: CardsTransactionStatus[];
    kind?: CardsTransactionKind[];
};

export enum CardTransactionsSearchFields {
    transactionId = 'transactionId',
    cardId = 'cardId',
    authorizationId = 'authorizationId',
}

export type CardAuthsFilterURLParams = BaseFilterParams<CardAuthsSearchFields> & {
    sourceMethod?: CardsMethod[];
    status?: CardsAuthorizationStatus[];
};

export enum CardAuthsSearchFields {
    authorizationId = 'authorizationId',
    cardId = 'cardId',
}

export const interpolateParams = (url: string, params: Readonly<Params<string>>) => {
    let newUrl = url;

    Object.entries(params).forEach(([key, value]) => {
        newUrl = newUrl.replace(new RegExp(`:${key}`, 'g'), value || '');
    });

    return newUrl;
};

export const getPaymentMethodLink = (sourceMethod: AccountsPaymentMethod, id: string) => {
    let href = '';
    const routeMap = {
        [AccountsPaymentMethod.CARD]: CARD_DETAILS_TRANSACTIONS_ROUTE,
        [AccountsPaymentMethod.A2ATRANSFER]: A2A_DETAILS_TRANSACTIONS_ROUTE,
        [AccountsPaymentMethod.BANK_TRANSFER]: BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE,
    };

    if (sourceMethod in routeMap) {
        const route = routeMap[sourceMethod];
        href = interpolateParams(route, { id });
    }
    return href;
};

export const getPaymentMethodTransactionLink = ({
    method,
    instrumentId,
    transactionId,
}: AccountsTransactionSource) => {
    let href = '';
    const routeMap = {
        [AccountsPaymentMethod.CARD]: CARD_TRANSACTION_DETAILS_ROUTE,
        [AccountsPaymentMethod.A2ATRANSFER]: A2A_DETAILS_TRANSACTIONS_DETAILS_ROUTE,
        [AccountsPaymentMethod.BANK_TRANSFER]: BANK_TRANSFERS_TRANSACTION_DETAIL_ROUTE,
    };

    if (method in routeMap) {
        const route = routeMap[method];
        href = interpolateParams(route, {
            id: instrumentId,
            transactionId,
        });
    }

    return href;
};

export const generateErrorText = (
    error: unknown,
    map?: Record<string, string | undefined>
): string => {
    if (error instanceof GenericServiceError) {
        if (!error?.reason?.type && !error?.message) {
            return '';
        }

        if (!error?.reason?.type && error?.message) {
            return error?.message;
        }

        const type = error.reason?.type;

        const getMapValue = (value: string | undefined): string => {
            if (!value) {
                return '';
            }

            return map ? map[value || ''] || value : value;
        };

        switch (type) {
            case 'MISSING_FIELD':
                return error.reason?.field
                    ? commonLang.GET_ERROR(getMapValue(error.reason?.field))
                    : '';
            case 'NOT_FOUND':
                return error.reason?.entity
                    ? commonLang.GET_ERROR(getMapValue(error.reason?.entity))
                    : '';
            default:
                return '';
        }
    }

    return '';
};

export const generateIdempotencyKey = (): string =>
    `${transformDate(new Date(), IDEMPOTENCY_KEY_DATE)}!${generateUUID()}`;

export const formatMinAmountValue = (value: string | null | undefined) =>
    value ? `>=${value}` : '';

export const formatMaxAmountValue = (value: string | null | undefined) =>
    value ? `<=${value}` : '';

export const formatDateFrom = (value: string | null | undefined) =>
    !value || value === 'undefined' ? '' : `>${value}`;

export const formatDateTo = (value: string | null | undefined) =>
    !value || value === 'undefined' ? '' : `<${value}`;

export const formatSortOrder = (value: SortOrder | null | undefined) =>
    value === SortOrder.DESC ? '-id' : '+id';
