import React from 'react';
import { Typography, CircularProgress, Box } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { PasswordField, PrimaryButton } from '@dock/react';
import loginLang from '../../../lang/login';

type LoginProps = {
    value: string;
    isLoading: boolean;
    onButtonClick: () => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isValidationError: boolean;
};

export function LoginForm({
    value,
    onChange,
    isValidationError,
    isLoading,
    onButtonClick,
}: LoginProps) {
    const isDisableButton = !value || isLoading;

    const handleOnEnterPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onButtonClick();
        }
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        handleOnEnterPress(e);
    };

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            onKeyDown={handleOnKeyDown}
            data-testid="loginForm"
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '418px',
                }}
            >
                <Typography variant="h1" sx={{ marginBottom: '8px' }}>
                    {loginLang.TITLE}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: '32px',
                        color: ({ palette }) => palette.grey[600],
                    }}
                >
                    {loginLang.DESCRIPTION}
                </Typography>
                <Box sx={{ marginBottom: '54px', width: '100%' }}>
                    <form>
                        <input hidden type="text" autoComplete="username" />
                        <PasswordField
                            value={value}
                            label={loginLang.LABEL}
                            onChange={onChange}
                            isError={isValidationError}
                            helpText={isValidationError ? loginLang.VALIDATION_ERROR : ''}
                        />
                    </form>
                </Box>

                <PrimaryButton
                    onClick={onButtonClick}
                    isDisabled={isDisableButton}
                    fullWidth
                    testId="loginButton"
                    endIcon={
                        isLoading ? (
                            <CircularProgress
                                data-testid="loadingIcon"
                                color="inherit"
                                size="1.25rem"
                            />
                        ) : (
                            <EastIcon />
                        )
                    }
                >
                    {loginLang.BUTTON}
                </PrimaryButton>
            </Box>
        </Box>
    );
}
