import {
    AccountsCreateAccount,
    CreateAccountData,
    ListAccountsData,
    ListAccountsParams,
    ListTransactionsData,
    ListTransactionsParams,
    RequestParams,
} from '@dock/types-dock-partner';
import { ResponseWithContinuationToken } from '@dock/services';
import { PagedServiceParams } from '../../../common';
import { AccountsAPI } from './accountsInstance';
import { addContinuationTokenToQuery } from '../../addContinuationTokenToQuery';
import { handleServiceRequest, handleServiceRequestWithToken } from '../handleServiceRequest';

export const fetchAccountsList = async (
    query: PagedServiceParams<ListAccountsParams>
): Promise<ResponseWithContinuationToken<ListAccountsData>> =>
    handleServiceRequestWithToken(AccountsAPI.listAccounts, addContinuationTokenToQuery(query));

export const fetchTransactionsList = async (
    query: PagedServiceParams<ListTransactionsParams>
): Promise<ResponseWithContinuationToken<ListTransactionsData>> =>
    handleServiceRequestWithToken(AccountsAPI.listTransactions, addContinuationTokenToQuery(query));

export const createAccount = async (
    data: AccountsCreateAccount,
    params: RequestParams
): Promise<CreateAccountData> => handleServiceRequest(AccountsAPI.createAccount, data, params);
