import React from 'react';
import { DEFAULT_THEME } from '@dock/react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

export const dockAdminTheme = createTheme(DEFAULT_THEME);

export function ThemeRoot({ children }: { children: React.ReactNode }) {
    return (
        <>
            <CssBaseline />
            <ThemeProvider theme={dockAdminTheme}>{children}</ThemeProvider>
        </>
    );
}
