import {
    A2A_DETAILS_TRANSACTIONS_ROUTE,
    A2A_DETAILS_SCHEDULE_PAYMENTS_ROUTE,
    interpolateParams,
    TabConfig,
} from '../../common';
import a2aDetailsLang from '../../lang/a2aDetails';

export const getA2ADetailsTabConfig = (id: string): TabConfig[] => [
    {
        label: a2aDetailsLang.TAB.TRANSACTIONS,
        link: interpolateParams(A2A_DETAILS_TRANSACTIONS_ROUTE, {
            id,
        }),
    },
    {
        label: a2aDetailsLang.TAB.SCHEDULE,
        link: interpolateParams(A2A_DETAILS_SCHEDULE_PAYMENTS_ROUTE, {
            id,
        }),
    },
];
