import {
    BankTransferDetails,
    bankTransfersDetailMapper,
} from '../mappers/bankTransfersDetailMapper';
import { useBankTransferTransactions } from './bankTransfers';

export const useBankTransferTransactionsDetails = (
    bankTransferId: string,
    id: string
): {
    data: BankTransferDetails | undefined;
    isFetching: boolean;
    error: boolean;
} => {
    const { data, isFetching, error } = useBankTransferTransactions({
        params: { bank_transfer_id: [bankTransferId], id: [id] },
    });

    return {
        error: data[0] === undefined ?? error,
        isFetching,
        data: data[0] ? bankTransfersDetailMapper(data[0]) : undefined,
    };
};
