import { CardsCreateCard, CreateCardData } from '@dock/types-dock-partner';
import { GenericServiceError } from '@dock/common';

import { createNewCardHandler } from '../../../client';

export const createNewCard = async (
    payload: CardsCreateCard,
    idempotencyKey: string
): Promise<CreateCardData> => {
    const headers = {
        'idempotency-key': idempotencyKey,
    };

    const errorOrResponse = await createNewCardHandler({ payload, headers });

    if (errorOrResponse instanceof GenericServiceError) {
        throw errorOrResponse;
    }

    return errorOrResponse;
};
