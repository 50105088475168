import { CardsVelocityControlWindowKind } from '@dock/types-dock-partner';
import { OptionType } from '@dock/react';
import { cardsVCWindowKindMap } from '../../../common';

export const windowKindOptions: OptionType<CardsVelocityControlWindowKind>[] = [
    {
        label: cardsVCWindowKindMap[CardsVelocityControlWindowKind.LIFETIME],
        value: CardsVelocityControlWindowKind.LIFETIME,
    },
    {
        label: cardsVCWindowKindMap[CardsVelocityControlWindowKind.MONTHS],
        value: CardsVelocityControlWindowKind.MONTHS,
    },
    {
        label: cardsVCWindowKindMap[CardsVelocityControlWindowKind.DAYS],
        value: CardsVelocityControlWindowKind.DAYS,
    },
    {
        label: cardsVCWindowKindMap[CardsVelocityControlWindowKind.PER_TRANSACTION],
        value: CardsVelocityControlWindowKind.PER_TRANSACTION,
    },
];
