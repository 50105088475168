import React from 'react';
import { LoginForm } from './components/LoginForm';
import { useAuth } from '../../auth';

export function Login() {
    const { validationError, isLoading, login } = useAuth();
    const [pass, setPass] = React.useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPass(event.target.value);
    };

    const handleLogin = () => login(pass);

    return (
        <LoginForm
            value={pass}
            isValidationError={validationError}
            isLoading={isLoading}
            onChange={handleChange}
            onButtonClick={handleLogin}
        />
    );
}
