import { flow } from 'fp-ts/lib/function';
import { CardsCardHolderListEntry, CreateCardData } from '@dock/types-dock-partner';
import { handleErrors } from '../../../../errorService';
import { requestFlow, getData, RequestAndUnwrap, unwrapService } from '../../../../utilities';

export const createNewCardHandler = requestFlow(handleErrors)(`v0/cards`);

const fetchAndHandle = createNewCardHandler<CreateCardData>(getData);

export const createNewCard: RequestAndUnwrap<'post', CreateCardData> = flow(
    fetchAndHandle,
    unwrapService<CreateCardData>
);

export const createNewCardHolderHandler = requestFlow(handleErrors)(`v0/cards/holders`);

export const createNewCardHolder: RequestAndUnwrap<'post', CardsCardHolderListEntry> = flow(
    createNewCardHandler<CardsCardHolderListEntry>(getData),
    unwrapService<CardsCardHolderListEntry>
);
