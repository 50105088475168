import { LABEL_MAX_LENGTH, requiredUuidValidation, zodEnumFromObjKeys } from '@dock/validation';
import { z } from 'zod';
import commonLang from '../../../../../lang/common';

export const createAccountBasicDetailsStepSchema = z.object({
    tenantId: requiredUuidValidation,
    accountHolderId: requiredUuidValidation,
    label: z
        .string()
        .trim()
        .min(1, commonLang.FIELD_REQUIRED)
        .max(LABEL_MAX_LENGTH, commonLang.GET_MAX_LENGTH(LABEL_MAX_LENGTH)),
    productId: requiredUuidValidation,
});

export const createAccountBasicDetailsStepSchemaKeys = zodEnumFromObjKeys(
    createAccountBasicDetailsStepSchema.shape
).Enum;
