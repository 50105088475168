import { flow } from 'fp-ts/lib/function';
import { A2ATransfersInstrument } from '@dock/types-dock-partner';
import { handleErrors } from '../../../../errorService';
import {
    getDataAndHeaders,
    requestFlow,
    RequestAndUnwrap,
    unwrapService,
    ResponseWithContinuationToken,
} from '../../../../utilities';

export type ListInstrumentsResponse = ResponseWithContinuationToken<A2ATransfersInstrument[]>;
export const listInstrumentsHandler = requestFlow(handleErrors)(`v0/a2a_transfers`);

const fetchAndHandle = listInstrumentsHandler<ListInstrumentsResponse>(getDataAndHeaders);

export const listInstruments: RequestAndUnwrap<'get', ListInstrumentsResponse> = flow(
    fetchAndHandle,
    unwrapService<ListInstrumentsResponse>
);
