import TableCell from '@mui/material/TableCell';
import { transformDate } from '@dock/react-util';
import type { A2ATransfersPaymentEvent } from '@dock/types-dock-partner';
import { TextWithCopyIcon } from '@dock/react';
import {
    CustomVirtualisationWrapper,
    StatusChip,
    ENV_VARS,
    TablesTypography,
} from '../../../../common';
import { A2APaymentEventKindMap, A2APaymentEventKindErrorMap } from './A2APaymentEventsMap';
import {
    a2APaymentEventKindColorMap,
    a2APaymentEventKindErrorColorMap,
} from './a2APaymentEventsColorMap';

export type A2ATransactionsTableRowProps = Pick<
    A2ATransfersPaymentEvent,
    'id' | 'datetimes' | 'kind'
>;

export function A2APaymentEventsTableRow({ id, kind, datetimes }: A2ATransactionsTableRowProps) {
    const createdDate = transformDate(new Date(datetimes.created));

    return (
        <CustomVirtualisationWrapper
            index={id}
            testId="a2aSchedulePaymentTableRow"
            isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
        >
            <TableCell data-testid="a2aSchedulePaymentId">
                <TextWithCopyIcon text={id}>
                    <TablesTypography content={id} />
                </TextWithCopyIcon>
            </TableCell>

            <TableCell data-testid="kind">
                {typeof kind === 'string' ? (
                    <StatusChip
                        color={a2APaymentEventKindColorMap[kind]}
                        label={A2APaymentEventKindMap[kind] || kind}
                    />
                ) : (
                    <StatusChip
                        color={a2APaymentEventKindErrorColorMap[kind.ERROR]}
                        label={A2APaymentEventKindErrorMap[kind.ERROR] || kind.ERROR}
                    />
                )}
            </TableCell>

            <TableCell data-testid="createdDate">
                <TablesTypography content={createdDate} />
            </TableCell>
        </CustomVirtualisationWrapper>
    );
}
