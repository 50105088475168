import {
    getTypedParamValue,
    FilterReturnTypes,
    getBasicEmptyFilters,
    useFilters,
} from '../../../../common';
import {
    A2ATransactionFiltersURLParams,
    A2ATransactionsSearchFields,
} from './transformA2ATransactionsParams';

export type A2ATxsPopUpFilterParams = Partial<
    Pick<A2ATransactionFiltersURLParams, 'minAmount' | 'maxAmount'>
>;

const getEmptyFilters =
    (defaultSearchBy: A2ATransactionsSearchFields) =>
    (params: URLSearchParams): A2ATransactionFiltersURLParams => ({
        ...getBasicEmptyFilters({ params }),
        searchBy: getTypedParamValue(
            'searchBy',
            params,
            A2ATransactionsSearchFields,
            defaultSearchBy
        ),
    });

type UseA2ATransferTxsFilter = FilterReturnTypes<
    A2ATransactionFiltersURLParams,
    A2ATxsPopUpFilterParams,
    A2ATransactionsSearchFields
>;

const customFieldsExtractor = (x: A2ATransactionFiltersURLParams) => x;

export const useA2ATransferTxsFilters = (
    defaultSearchBy: A2ATransactionsSearchFields
): UseA2ATransferTxsFilter =>
    useFilters<A2ATransactionFiltersURLParams>(
        getEmptyFilters(defaultSearchBy),
        customFieldsExtractor
    );
