import { useModal } from '@dock/react-hooks';
import { Box } from '@mui/material';
import { CommonButton } from '@dock/react';
import { CreateAccountModal } from './CreateAccountModal';
import commonLang from '../../../../lang/common';

export function CreateAccount() {
    const {
        isOpen: isOpenFormModal,
        setClose: setCloseFormModal,
        setOpen: setOpenFormModal,
    } = useModal();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}
        >
            <CommonButton
                label={commonLang.CREATE_NEW}
                onClick={setOpenFormModal}
                testId="createAccount"
            />

            <CreateAccountModal
                handleClose={setCloseFormModal}
                isOpen={isOpenFormModal}
                setOpenFormModal={setOpenFormModal}
            />
        </Box>
    );
}
