import { SimpleRow, AmountSimpleRow, SummaryStepWrapper, SummarySection } from '@dock/react';
import { DATE_TIME_FORMAT, transformDate } from '@dock/react-util';
import { CardsVelocityControlWindowKind } from '@dock/types-dock-partner';
import {
    cardsVCScopeMap,
    cardsVCTargetKindMap,
    cardsVCTargetIdMap,
    cardsVCWindowKindMap,
} from '../../../../common';
import createCardSCLang from '../../../../lang/createCardSC';
import commonLang from '../../../../lang/common';
import { CreateCardVCFormType } from '../types';

type SCSummaryStepProps = {
    handleFormSubmit: () => void;
    handleBack: () => void;
    formValues: CreateCardVCFormType;
    isLoading: boolean;
};

export function SCSummaryStep({
    handleFormSubmit,
    handleBack,
    formValues,
    isLoading,
}: SCSummaryStepProps) {
    const windowKind = formValues.window.kind;
    const categoryValue = cardsVCTargetKindMap[formValues.target.kind] || formValues.target.kind;
    const targetKindLabel = cardsVCTargetIdMap[formValues.target.kind];
    const scopeValue = cardsVCScopeMap[formValues.scope];
    const windowKindValue = cardsVCWindowKindMap[windowKind] || windowKind;
    const daysValue = formValues.window.days ? String(formValues.window.days) : undefined;
    const monthsValue = formValues.window.months ? String(formValues.window.months) : undefined;
    const startsAtValue = formValues.datetimes?.effectiveFrom
        ? transformDate(new Date(formValues.datetimes?.effectiveFrom), DATE_TIME_FORMAT)
        : undefined;
    const endsAtValue = formValues.datetimes?.effectiveUntil
        ? transformDate(new Date(formValues.datetimes?.effectiveUntil), DATE_TIME_FORMAT)
        : undefined;
    const isDays = windowKind === CardsVelocityControlWindowKind.DAYS;
    const isMonths = windowKind === CardsVelocityControlWindowKind.MONTHS;

    return (
        <SummaryStepWrapper
            title={createCardSCLang.SC_SUMMARY}
            onBack={handleBack}
            onFormSubmit={handleFormSubmit}
            backButtonText={commonLang.BACK_TO_EDITING}
            submitButtonText={createCardSCLang.CONFIRM}
            isLoading={isLoading}
        >
            <SummarySection title={createCardSCLang.BASIC_DETAILS}>
                {!!formValues.label && (
                    <SimpleRow
                        label={commonLang.LABEL}
                        value={formValues.label}
                        valueTestId="labelValue"
                    />
                )}
                <SimpleRow
                    label={createCardSCLang.CATEGORY}
                    value={categoryValue}
                    valueTestId="categoryValue"
                />
                <SimpleRow
                    label={targetKindLabel}
                    value={formValues.target.id}
                    valueTestId="targetIdValue"
                />
                <SimpleRow
                    label={createCardSCLang.SCOPE}
                    value={scopeValue}
                    valueTestId="scopeValue"
                />
                <SimpleRow
                    label={createCardSCLang.TENANT_ID}
                    value={formValues.tenantId}
                    valueTestId="tenantIdValue"
                />
            </SummarySection>
            <SummarySection title={createCardSCLang.SPENDING_CONFIGURATION}>
                <AmountSimpleRow
                    label={commonLang.AMOUNT}
                    amount={formValues.velocity.spendingLimit.amount}
                    currency={formValues.velocity.spendingLimit.currency}
                    valueTestId="amountValue"
                />
                <SimpleRow
                    label={commonLang.CURRENCY}
                    value={formValues.velocity.spendingLimit.currency}
                    valueTestId="currencyValue"
                />
                <SimpleRow
                    label={createCardSCLang.WINDOW}
                    value={windowKindValue}
                    valueTestId="windowKindValue"
                />
                {isDays && !!daysValue && (
                    <SimpleRow
                        label={createCardSCLang.AMOUNT_OF_DAYS}
                        value={daysValue}
                        valueTestId="daysValue"
                    />
                )}
                {isMonths && !!monthsValue && (
                    <SimpleRow
                        label={createCardSCLang.AMOUNT_OF_MONTHS}
                        value={monthsValue}
                        valueTestId="monthsValue"
                    />
                )}
                {!!startsAtValue && (
                    <SimpleRow
                        label={createCardSCLang.STARTING_TIME}
                        value={startsAtValue}
                        valueTestId="startsAtValue"
                    />
                )}
                {!!endsAtValue && (
                    <SimpleRow
                        label={createCardSCLang.ENDING_TIME}
                        value={endsAtValue}
                        valueTestId="endsAtValue"
                    />
                )}
            </SummarySection>
        </SummaryStepWrapper>
    );
}
