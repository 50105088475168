import { CardsVelocityControlWindowKind } from '@dock/types-dock-partner';
import { SpendingConfigurationStepFormValues } from './types';

export const createSpendingControlBL = {
    transformFormValues: (
        data: SpendingConfigurationStepFormValues
    ): SpendingConfigurationStepFormValues => {
        if (data.windowKind === CardsVelocityControlWindowKind.DAYS) {
            return { ...data, windowMonths: null };
        }

        if (data.windowKind === CardsVelocityControlWindowKind.MONTHS) {
            return { ...data, windowDays: null };
        }

        if (
            data.windowKind === CardsVelocityControlWindowKind.PER_TRANSACTION ||
            data.windowKind === CardsVelocityControlWindowKind.LIFETIME
        ) {
            return { ...data, windowDays: null, windowMonths: null };
        }

        return { ...data };
    },
};
