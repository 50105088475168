import React from 'react';
import { Box, Palette } from '@mui/material';

export const getBoxStyles = (palette: Palette) => ({
    p: 3,
    borderRadius: '8px',
    backgroundColor: 'background.default',
    boxShadow: `0px 7px 10px 0px ${palette.grey[700]}0A`,
});

type InformationBoxProps = {
    children: React.ReactNode;
    testId?: string;
};

export function InformationBox({ children, testId = 'informationBox' }: InformationBoxProps) {
    return (
        <Box
            sx={({ palette }) => ({
                ...getBoxStyles(palette),
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            })}
            data-testid={testId}
        >
            {children}
        </Box>
    );
}
