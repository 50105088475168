import { AppBarMapper } from '@dock/react';
import {
    useTitleLocation,
    LocalAndUtcDate,
    UserAndEmail,
    LegalItems,
    LogOutItem,
} from '../../common';
import { titlesForSpecificLocations } from '../../configs/titlesForSpecificLocation';
import { useAuth } from '../../auth';

export function AppBar() {
    const title = useTitleLocation(titlesForSpecificLocations);
    const { user, logout } = useAuth();

    return (
        <AppBarMapper
            title={title}
            navItems={[
                { component: <LegalItems />, id: '0' },
                {
                    component: <LogOutItem logout={logout} />,
                    id: '1',
                },
            ]}
            dropdownTitle={<UserAndEmail user={user} />}
            dateUTC={<LocalAndUtcDate />}
            user={user.name}
        />
    );
}
