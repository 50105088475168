import { AccountsAccountBalances } from '@dock/types-dock-partner';
import {
    ChartData,
    CoreChartOptions,
    ElementChartOptions,
    PluginChartOptions,
    DatasetChartOptions,
    ScaleChartOptions,
    DoughnutControllerChartOptions,
    TooltipItem,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import { dockAdminTheme } from '../../../common';
import lang from '../../../lang/accountDetails';

export const mapDataDoughnut = ({
    available,
    hold,
}: AccountsAccountBalances): ChartData<'doughnut', number[], string> => ({
    labels: [lang.HOLD_BALANCE, lang.AVAILABLE_BALANCE],
    datasets: [
        {
            data: [parseFloat(hold.amount), parseFloat(available.amount)],
            backgroundColor: [
                dockAdminTheme.palette.warning.light,
                dockAdminTheme.palette.secondary.dark,
            ],
        },
    ],
});

export const findFirstNonMatchingValue = (data: number[], amount: number): number =>
    data?.find((d: number) => !Number.isNaN(d) && d !== amount) || 0;

export const addPercentageSign = (data: number[], amount: number): string => {
    const noMatchingValue = findFirstNonMatchingValue(data, amount);
    if (!noMatchingValue || !amount) return '100 %';
    return `${((amount / (noMatchingValue + amount)) * 100)
        .toFixed(3)
        .slice(0, -1)
        .replace(/[.,]00$/, '')} %`;
};
export const optDoughnut: _DeepPartialObject<
    CoreChartOptions<'doughnut'> &
        ElementChartOptions<'doughnut'> &
        PluginChartOptions<'doughnut'> &
        DatasetChartOptions<'doughnut'> &
        ScaleChartOptions<'doughnut'> &
        DoughnutControllerChartOptions
> = {
    cutout: 23,
    rotation: -90,
    circumference: 180,
    plugins: {
        tooltip: {
            callbacks: {
                label: ({ parsed, dataset: { data } }: TooltipItem<'doughnut'>) =>
                    addPercentageSign(data, parsed),
            },
        },
        legend: {
            position: 'left',
            labels: {
                usePointStyle: true,
                boxHeight: 8,
                boxWidth: 8,
                padding: 20,
                color: 'black',
                font: {
                    size: 18,
                    weight: 'normal',
                    lineHeight: 24,
                },
            },
        },
    },
    maintainAspectRatio: false,
};
