import { PrimaryButton, SecondaryButton } from '@dock/react';
import { Box } from '@mui/material';
import loginLang from '../../../../lang/login';

type SessionExpiredModalButtonsProps = {
    onContinueSessionHandler: () => void;
    onCancelSessionHandler: () => void;
};

const wrapperStyles = {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    padding: '0 14px 8px 14px',
};

export function SessionExpiredModalButtons({
    onCancelSessionHandler,
    onContinueSessionHandler,
}: SessionExpiredModalButtonsProps) {
    return (
        <Box sx={wrapperStyles}>
            <SecondaryButton
                onClick={onCancelSessionHandler}
                testId="cancelSession"
                sx={{ width: '50%' }}
            >
                {loginLang.LOGOUT}
            </SecondaryButton>
            <PrimaryButton
                onClick={onContinueSessionHandler}
                testId="continueSession"
                size="small"
                sx={{ width: '50%' }}
            >
                {loginLang.SESSION_EXPIRED.CONTINUE}
            </PrimaryButton>
        </Box>
    );
}
