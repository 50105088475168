import type { CardsCardStatus } from '@dock/types-dock-partner';
import { cardDetailsBL } from '../BL/cardDetails.BL';
import cardDetailsLang from '../../../lang/cardDetails';
import { BorderColorIconButton } from '../../../common';

type ChangeCardStatusProps = {
    cardStatus: CardsCardStatus;
    setOpen: () => void;
};

export function ChangeCardStatus({ setOpen, cardStatus }: ChangeCardStatusProps) {
    const isEditStatusDisabled = cardDetailsBL.isEditCardStatusDisabled(cardStatus);

    return (
        <BorderColorIconButton
            setOpen={setOpen}
            isDisabled={isEditStatusDisabled}
            testId="changeCardStatusButton"
            ariaLabel={cardDetailsLang.CARD_ACCORDION.EDIT_STATUS}
        />
    );
}
