import { Palette } from '@mui/material';

export const accordionStyles = {
    boxShadow: ({ palette }: { palette: Palette }) => `0px 7px 10px 0px ${palette.grey[700]}0A`,
    borderRadius: '8px',
    '.MuiAccordionSummary-content': {
        display: 'flex',
        alignItems: 'baseline',
    },
};
