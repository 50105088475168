import { Column, ColumnAlign } from '@dock/react';
import cardOverviewLang from '../../../../lang/cardTransactionsOverview';

export const authorizationsEventsOverviewColumns: Column[] = [
    {
        key: 'authorizationId',
        name: cardOverviewLang.CARD_AUTHORIZATIONS_EVENTS_TABLE.AUTHORIZATION_ID,
    },
    {
        key: 'cardId',
        name: cardOverviewLang.CARD_AUTHORIZATIONS_EVENTS_TABLE.CARD_ID,
    },
    {
        key: 'status',
        name: cardOverviewLang.CARD_AUTHORIZATIONS_EVENTS_TABLE.STATUS,
    },
    {
        key: 'type',
        name: cardOverviewLang.CARD_AUTHORIZATIONS_EVENTS_TABLE.TYPE,
    },
    {
        key: 'outcomeReason',
        name: cardOverviewLang.CARD_AUTHORIZATIONS_EVENTS_TABLE.OUTCOME_REASON,
    },
    {
        key: 'amount',
        name: cardOverviewLang.CARD_AUTHORIZATIONS_EVENTS_TABLE.AMOUNT,
        align: ColumnAlign.RIGHT,
    },
    {
        key: 'currency',
        name: cardOverviewLang.CARD_AUTHORIZATIONS_EVENTS_TABLE.CURRENCY,
    },
    {
        key: 'initiatedTime',
        name: cardOverviewLang.CARD_AUTHORIZATIONS_EVENTS_TABLE.TIME_INITIATED,
        sortable: true,
    },
    { key: 'actions', name: '' },
];
