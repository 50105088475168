import type { CardsCardStatus } from '@dock/types-dock-partner';
import { ChipColors } from '../../../../common';

export const cardStatusColorMap: Record<CardsCardStatus, ChipColors> = {
    ACTIVATED: ChipColors.success,
    LOCKED: ChipColors.error,
    UNACTIVATED: ChipColors.default,
    SUSPENDED: ChipColors.default,
    TERMINATED: ChipColors.info,
    TERMINATED_OBSOLETE: ChipColors.info,
};
