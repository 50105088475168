import { Box, Typography } from '@mui/material';
import lang from '../../../lang/accountDetails';

export function ErrorBox({ id }: { id: string }) {
    return (
        <Box
            sx={{
                minHeight: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography variant="h4">{lang.GET_MONEY_BALANCE_ERROR(id)}</Typography>
        </Box>
    );
}
