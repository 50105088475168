import { WhiteBackgroundWrapper, PageContainer } from '@dock/react';
import {
    NavLinkTabSwitcher,
    PageActionButtonWrapper,
    BreadcrumbsWrapper,
} from '../../../../common';
import { getCardTabConfig } from '../../getTabConfig';
import { cardsBL } from '../../BL/cards.BL';
import { CardsListTable } from './CardsListTable';
import { CreateCard } from '../CreateCard/CreateCard';

export function Cards() {
    const tabs = getCardTabConfig();

    return (
        <PageContainer testId="cardPage">
            <BreadcrumbsWrapper>
                <PageActionButtonWrapper>
                    {cardsBL.isCreateCardAvailable() && <CreateCard />}
                </PageActionButtonWrapper>
            </BreadcrumbsWrapper>

            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher tabs={tabs} />
                <CardsListTable />
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
