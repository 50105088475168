import {
    CARD_DETAILS_AUTHORIZATION_ROUTE,
    CARD_DETAILS_TRANSACTIONS_ROUTE,
    interpolateParams,
    TabConfig,
} from '../../common';
import cardDetailsLang from '../../lang/cardDetails';

export const getCardDetailsTabConfig = (id: string): TabConfig[] => [
    {
        label: cardDetailsLang.TABS.CARD_DETAILS.TRANSACTIONS,
        link: interpolateParams(CARD_DETAILS_TRANSACTIONS_ROUTE, {
            id,
        }),
    },
    {
        label: cardDetailsLang.TABS.CARD_DETAILS.AUTHORIZATIONS,
        link: interpolateParams(CARD_DETAILS_AUTHORIZATION_ROUTE, {
            id,
        }),
    },
];
