import { StepType } from '@dock/react';
import commonLang from '../../../../lang/common';

export const stepsConfig: StepType[] = [
    {
        label: commonLang.STEPS.BASIC_DETAILS,
        isOptional: false,
    },
    {
        label: commonLang.EXTERNAL_REFERENCE,
        isOptional: true,
    },
];
