import {
    CARD_AUTHORIZATION_EVENTS_OVERVIEW_ROUTE,
    CARD_AUTHORIZATIONS_OVERVIEW_ROUTE,
    CARD_TRANSACTIONS_OVERVIEW_ROUTE,
    TabConfig,
} from '../../common';
import cardOverviewLang from '../../lang/cardTransactionsOverview';

export const getTabConfig = (): TabConfig[] => [
    {
        label: cardOverviewLang.TABS.AUTHORIZATIONS,
        link: CARD_AUTHORIZATIONS_OVERVIEW_ROUTE,
    },
    {
        label: cardOverviewLang.TABS.AUTHORIZATIONS_EVENTS,
        link: CARD_AUTHORIZATION_EVENTS_OVERVIEW_ROUTE,
    },
    {
        label: cardOverviewLang.TABS.TRANSACTIONS,
        link: CARD_TRANSACTIONS_OVERVIEW_ROUTE,
    },
];
