import React from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider } from '@tanstack/react-query';
import { ThemeRoot, ENV_VARS } from './common';
import { queryClient } from './services';

export function AppRoot({ children }: { children: React.ReactNode }) {
    return (
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <ThemeRoot>{children}</ThemeRoot>
                {ENV_VARS.IS_DEVELOPMENT_BUILD && <ReactQueryDevtools />}
            </QueryClientProvider>
        </React.StrictMode>
    );
}
