import TableCell from '@mui/material/TableCell';
import {
    ActionMenuItemProps,
    ActionsMenu,
    AmountCell,
    TextWithCopyIcon,
    TextWithCopyIconContent,
    NavLink,
    ViewDetailsLink,
} from '@dock/react';
import { AccountsAccount } from '@dock/types-dock-partner';
import { transformDate } from '@dock/react-util';
import {
    LinkWithCopyIcon,
    TablesTypography,
    CustomVirtualisationWrapper,
    StatusChip,
    interpolateParams,
    ENV_VARS,
    ACCOUNT_DETAILS_TRANSACTIONS_ROUTE,
} from '../../../../common';
import { accountStatusLabelMap } from './accountStatusLabelMap';
import { accountStatusColorMap } from './accountStatusColorMap';

type AccountsRowProps = Pick<
    AccountsAccount,
    'id' | 'accountHolderId' | 'status' | 'balances' | 'label' | 'datetimes'
>;

export function AccountsRow({
    id,
    accountHolderId,
    status,
    balances,
    label,
    datetimes,
}: AccountsRowProps) {
    const createdTime = transformDate(new Date(datetimes.created));

    const accountsTableActions: ActionMenuItemProps[] = [
        {
            content: (
                <NavLink
                    to={interpolateParams(ACCOUNT_DETAILS_TRANSACTIONS_ROUTE, {
                        id,
                    })}
                    label={<ViewDetailsLink />}
                />
            ),
            id: '0',
        },
    ];

    return (
        <CustomVirtualisationWrapper
            index={id}
            testId="accountRow"
            isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
        >
            <TableCell data-testid="accountName">
                <TablesTypography content={label} />
            </TableCell>
            <TableCell data-testid="accountIdCell">
                <TextWithCopyIcon text={id}>
                    <LinkWithCopyIcon
                        href={interpolateParams(ACCOUNT_DETAILS_TRANSACTIONS_ROUTE, {
                            id,
                        })}
                        text={id}
                        ellipsis="start"
                    />
                </TextWithCopyIcon>
            </TableCell>
            <TableCell data-testid="accountHolderIdCell">
                <TextWithCopyIcon text={accountHolderId}>
                    <TextWithCopyIconContent text={accountHolderId} ellipsis="start" />
                </TextWithCopyIcon>
            </TableCell>
            <TableCell data-testid="accountStatusCell">
                <StatusChip
                    color={accountStatusColorMap[status]}
                    label={accountStatusLabelMap[status] || status}
                />
            </TableCell>
            <TableCell data-testid="createdTimeCell">
                <TablesTypography content={createdTime} />
            </TableCell>
            <AmountCell amount={balances.available.amount} />
            <TableCell data-testid="currencyCell">
                <TablesTypography content={balances.available.currency} />
            </TableCell>
            <TableCell data-testid="actionCell">
                <ActionsMenu menuItems={accountsTableActions} />
            </TableCell>
        </CustomVirtualisationWrapper>
    );
}
