import { WhiteBackgroundWrapper } from '@dock/react';

import { generateErrorText, ErrorStatusContent } from '../../../../common';
import { PartnerServiceError } from '../../../../common/PartnerServiceError';

type ErrorMessageProps = {
    isLoading: boolean;
    error: PartnerServiceError;
    onChangeClick: () => void;
    onFormSubmit: () => void;
    message: string;
};

export function ErrorMessage({
    message,
    isLoading,
    error,
    onChangeClick,
    onFormSubmit,
}: ErrorMessageProps) {
    return (
        <WhiteBackgroundWrapper sx={{ p: '24px' }}>
            <ErrorStatusContent
                buttonStyles={{ width: '443px' }}
                statusMessages={[message, generateErrorText(error)]}
                onChangeClick={onChangeClick}
                onSubmitAgainClick={onFormSubmit}
                isLoading={isLoading}
            />
        </WhiteBackgroundWrapper>
    );
}
