import { Box } from '@mui/material';
import { useModal } from '@dock/react-hooks';
import EastIcon from '@mui/icons-material/East';
import { CommonButton } from '@dock/react';
import a2aDetailsLang from '../../../../lang/a2aDetails';
import { PayA2ATransferModal } from './Modal/PayA2ATransferModal';

export function PayA2ATransfer() {
    const {
        isOpen: isOpenFormModal,
        setClose: setCloseFormModal,
        setOpen: setOpenFormModal,
    } = useModal();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}
        >
            <CommonButton
                icon={<EastIcon fontSize="small" />}
                onClick={setOpenFormModal}
                label={a2aDetailsLang.PAY.BUTTON}
                testId="a2aPayment"
            />

            <PayA2ATransferModal
                handleClose={setCloseFormModal}
                isOpen={isOpenFormModal}
                setOpenFormModal={setOpenFormModal}
            />
        </Box>
    );
}
