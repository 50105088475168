import {
    AmountSimpleRow,
    CollapsableTitle,
    DetailsTitle,
    HeadlessTable,
    SimpleRow,
    TableSkeleton,
    TextWithCopyIcon,
    useBoolean,
} from '@dock/react';
import { Paper, Collapse, Typography } from '@mui/material';
import {
    DetailsTransactionsColumns,
    emptyCardTransactionDetail,
    useCardTransactionsDetails,
} from '../../../services';
import {
    CardTransactionDetailColumns,
    CardTransactionMerchantColumns,
    CardTransactionOtherColumns,
} from './CardTransactionDetailColumns';
import txDetailsLang from '../../../lang/cardTransactionsDetails';

const detailColumnsKeys = Object.keys(CardTransactionDetailColumns);
const merchantColumnsKeys = Object.keys(CardTransactionMerchantColumns);
const otherColumnsKeys = Object.keys(CardTransactionOtherColumns);

type CardTransactionDetailTableProps = {
    cardId: string;
    transactionID: string;
};

export function CardTransactionDetailTable({
    cardId,
    transactionID,
}: CardTransactionDetailTableProps) {
    //* hooks
    const [isDetailCollapsed, flipDetailCollapsable] = useBoolean(true);
    const [isMerchantCollapsed, flipMerchantCollapsable] = useBoolean(false);
    const [isOtherCollapsed, flipOtherCollapsable] = useBoolean(false);
    //* Data
    const {
        data: [{ details, merchant, other } = emptyCardTransactionDetail],
        isFetching,
    } = useCardTransactionsDetails(cardId, transactionID);

    //* Collapsable
    const shouldShowTransactionsDetail = !isFetching && details;
    const shouldShowTransactionsMerchant = !isFetching && merchant;
    const shouldShowTransactionsOther = !isFetching && other;

    const {
        transactionId,
        authorizationId,
        cardId: id,
        totalAmount,
        ...rest
    } = details as DetailsTransactionsColumns;

    return (
        <>
            <Paper>
                <CollapsableTitle
                    isOpen={isDetailCollapsed}
                    titleElement={<DetailsTitle title={txDetailsLang.CARD_TRANSACTION_TITLE} />}
                    handleCollapsableElement={flipDetailCollapsable}
                />
                <Collapse in={isDetailCollapsed} timeout="auto" unmountOnExit>
                    <HeadlessTable>
                        <TableSkeleton
                            rows={detailColumnsKeys.length}
                            columns={2}
                            shouldShow={isFetching}
                        />
                        {shouldShowTransactionsDetail &&
                            Object.entries({
                                transactionId,
                                authorizationId,
                                cardId: id,
                            }).map(([key, value]) => (
                                <SimpleRow
                                    key={key}
                                    label={
                                        CardTransactionDetailColumns[
                                            key as keyof typeof CardTransactionDetailColumns
                                        ]
                                    }
                                    value={
                                        <TextWithCopyIcon text={value}>
                                            <Typography>{value}</Typography>
                                        </TextWithCopyIcon>
                                    }
                                />
                            ))}
                        <AmountSimpleRow
                            label={CardTransactionDetailColumns.totalAmount}
                            amount={totalAmount}
                        />
                        {shouldShowTransactionsDetail &&
                            Object.entries(rest).map(([key, value]) => (
                                <SimpleRow
                                    key={key}
                                    label={
                                        CardTransactionDetailColumns[
                                            key as keyof typeof CardTransactionDetailColumns
                                        ]
                                    }
                                    value={value as string}
                                />
                            ))}
                    </HeadlessTable>
                </Collapse>
            </Paper>
            <Paper sx={{ my: '16px' }}>
                <CollapsableTitle
                    isOpen={isMerchantCollapsed}
                    titleElement={
                        <DetailsTitle title={txDetailsLang.CARD_TRANSACTION_MERCHANT_TITLE} />
                    }
                    handleCollapsableElement={flipMerchantCollapsable}
                />
                <Collapse in={isMerchantCollapsed} timeout="auto" unmountOnExit>
                    <HeadlessTable>
                        <TableSkeleton
                            rows={merchantColumnsKeys.length}
                            columns={2}
                            shouldShow={isFetching}
                        />
                        {shouldShowTransactionsMerchant &&
                            Object.entries(merchant).map(([key, value]) => (
                                <SimpleRow
                                    key={key}
                                    label={
                                        CardTransactionMerchantColumns[
                                            key as keyof typeof CardTransactionMerchantColumns
                                        ]
                                    }
                                    value={value as string}
                                />
                            ))}
                    </HeadlessTable>
                </Collapse>
            </Paper>
            <Paper sx={{ my: '16px' }}>
                <CollapsableTitle
                    isOpen={isOtherCollapsed}
                    titleElement={
                        <DetailsTitle title={txDetailsLang.CARD_TRANSACTION_OTHER_COLUM_TITLE} />
                    }
                    handleCollapsableElement={flipOtherCollapsable}
                />
                <Collapse in={isOtherCollapsed} timeout="auto" unmountOnExit>
                    <HeadlessTable>
                        <TableSkeleton
                            rows={otherColumnsKeys.length}
                            columns={2}
                            shouldShow={isFetching}
                        />
                        {shouldShowTransactionsOther &&
                            Object.entries(other).map(([key, value]) => (
                                <SimpleRow
                                    key={key}
                                    label={
                                        CardTransactionOtherColumns[
                                            key as keyof typeof CardTransactionOtherColumns
                                        ]
                                    }
                                    value={value as string}
                                />
                            ))}
                    </HeadlessTable>
                </Collapse>
            </Paper>
        </>
    );
}
