import React from 'react';
import { InputRange } from '@dock/react';
import { FormControl, Typography, FormGroup, FormLabel } from '@mui/material';

type AmountInputRangeProps = {
    handleChange: (htmlCheck: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    helperText: string;
    minAmount: string | undefined;
    maxAmount: string | undefined;
};

export function AmountInputRange({
    handleChange,
    label,
    minAmount,
    maxAmount,
    helperText,
}: AmountInputRangeProps) {
    return (
        <FormControl component="fieldset" variant="standard">
            <FormLabel sx={{ marginBottom: '16px' }}>
                <Typography variant="h4">{label}</Typography>
            </FormLabel>
            <FormGroup>
                <InputRange
                    handleChange={handleChange}
                    min={minAmount ?? '00.00'}
                    max={maxAmount ?? '00.00'}
                    helperText={helperText}
                />
            </FormGroup>
        </FormControl>
    );
}
