import {
    CardsTransaction,
    ListCardTransactionsData,
    ListTransactionsData,
    AccountsPaymentMethod,
    AccountsTransaction,
    BankTransferBankTransferTransaction,
    BankTransferBankTransferTransactionPaymentSystemSpecific,
    BankTransferBankTransferTransactionSepaSpecific,
} from '@dock/types-dock-partner';
import { ExtendedA2ATransaction } from './mergeA2ATransactionsAndAccountName';

export type ExtendedAccountTransaction = AccountsTransaction & {
    counterparty: string;
};

const findTransaction = <T extends { id: string }>(transactions: T[], id: string): T | null =>
    transactions.find((el) => el.id === id) || null;

const isSepa = (
    paymentSystemSpecific: BankTransferBankTransferTransactionPaymentSystemSpecific
): paymentSystemSpecific is {
    Sepa: BankTransferBankTransferTransactionSepaSpecific;
} => 'Sepa' in paymentSystemSpecific;

const getBankTransferCounterpartyName = (
    transactions: BankTransferBankTransferTransaction[],
    id: string
): string => {
    const transaction = findTransaction<BankTransferBankTransferTransaction>(transactions, id);

    if (!transaction) {
        return '';
    }

    if (isSepa(transaction.paymentSystemSpecific)) {
        return transaction.paymentSystemSpecific.Sepa.counterparty.name || '';
    }

    return '';
};

export const getTXsWithCounterparty = (
    transactions: ListTransactionsData,
    cardTransactions: ListCardTransactionsData,
    a2aTransactions: ExtendedA2ATransaction[],
    btTransactionsData: BankTransferBankTransferTransaction[]
): ExtendedAccountTransaction[] => {
    const getCounterparty = (currentValue: AccountsTransaction) => {
        const currentTxId = currentValue.source.transactionId;

        switch (currentValue.source.method) {
            case AccountsPaymentMethod.CARD:
                return (
                    findTransaction<CardsTransaction>(cardTransactions, currentTxId)?.merchant
                        .name || ''
                );
            case AccountsPaymentMethod.A2ATRANSFER:
                return (
                    findTransaction<ExtendedA2ATransaction>(a2aTransactions, currentTxId)?.label ||
                    ''
                );
            case AccountsPaymentMethod.BANK_TRANSFER:
                return getBankTransferCounterpartyName(btTransactionsData, currentTxId);
            default:
                return '';
        }
    };

    return transactions.reduce((acc: ExtendedAccountTransaction[], currentValue) => {
        acc.push({
            ...currentValue,
            counterparty: getCounterparty(currentValue),
        });

        return acc;
    }, []);
};
