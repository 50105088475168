import { DateRangeDropDown, FilterButton, SearchBox, SimpleTable } from '@dock/react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { prefillDatesFromAlways } from '@dock/common';
import { useModal } from '@dock/react-hooks';
import { useBankTransferTransactions } from '../../../../services';
import { BankTransferTransactionsTableRow } from './BankTransferTransactionsTableRow';
import { tableColumns } from './bankTransferTransactionsTableColumns';
import { filterWrapperStyles, TableFiltersWrapper } from '../../../../common';
import lang from '../../../../lang/bankTransferDetails';
import { useBankTransferTransferTxsFilters } from './useBankTransferTransferTxsFilters';
import { transformBankTransferTransactionsParams } from './transformBankTransferTransactionsParams';
import { BankTransferTransactionsFilter } from './BankTransferTransactionsFilter';

export function TransactionsTable() {
    const { id: bankTransferId = '' } = useParams();
    const { isOpen, setClose, setOpen } = useModal();

    const {
        filters,
        handlePopUpFilter,
        handleDateFilter,
        handleSearch,
        isPopFilterActive,
        sorting,
        handleSortOrder,
    } = useBankTransferTransferTxsFilters();

    const { data, isFetchingNextPage, error, fetchNextPage, isFetching } =
        useBankTransferTransactions({
            params: transformBankTransferTransactionsParams(filters, bankTransferId),
        });

    const [dateFrom, dateTo] = prefillDatesFromAlways(filters.from, filters.to);

    return (
        <>
            <TableFiltersWrapper>
                <SearchBox
                    label={lang.SEARCH_FOR_TRANSACTION_ID}
                    setLastSearchValue={handleSearch}
                    initValue={filters.text || ''}
                />
                <Box
                    sx={{
                        ...filterWrapperStyles,
                    }}
                >
                    <DateRangeDropDown
                        handleFilters={handleDateFilter}
                        from={dateFrom}
                        to={dateTo}
                    />
                    <FilterButton isFilterOn={isPopFilterActive} onClick={setOpen} />
                </Box>
            </TableFiltersWrapper>
            <BankTransferTransactionsFilter
                onApply={handlePopUpFilter(setOpen)}
                handleClose={setClose}
                isOpen={isOpen}
                fields={{
                    kind: filters.kind || [],
                    minAmount: filters.minAmount || '',
                    maxAmount: filters.maxAmount || '',
                }}
            />
            <SimpleTable
                columns={tableColumns}
                RowComponent={BankTransferTransactionsTableRow}
                isLoading={isFetching}
                rows={data}
                isLoadingNextPage={isFetchingNextPage}
                onReachingBottom={fetchNextPage}
                error={error}
                sorting={sorting}
                onSortClick={handleSortOrder}
            />
        </>
    );
}
