import {
    A2A_LIST_KEY,
    A2A_PAYMENT_EVENTS_KEY,
    A2A_PAYMENT_SCHEDULE_KEY,
    A2A_TRANSACTIONS_KEY,
} from '../../../keys';
import {
    fetchA2AList,
    fetchA2ATransferHolderList,
    fetchA2APaymentEvents,
    fetchA2APaymentSchedule,
    fetchA2ATransactions,
} from '../../methods';
import { useInfiniteQueryWith } from '../useInfiniteQueryWith';

export const useA2AList = useInfiniteQueryWith({
    fetcher: fetchA2AList,
    queryKey: A2A_LIST_KEY,
});

export const useA2AHolderList = useInfiniteQueryWith({
    fetcher: fetchA2ATransferHolderList,
    queryKey: A2A_LIST_KEY,
});

export const useA2APaymentEvents = useInfiniteQueryWith({
    fetcher: fetchA2APaymentEvents,
    queryKey: A2A_PAYMENT_EVENTS_KEY,
});

export const useA2APaymentSchedule = useInfiniteQueryWith({
    fetcher: fetchA2APaymentSchedule,
    queryKey: A2A_PAYMENT_SCHEDULE_KEY,
});

export const useA2ATransactions = useInfiniteQueryWith({
    fetcher: fetchA2ATransactions,
    queryKey: A2A_TRANSACTIONS_KEY,
});
