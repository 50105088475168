import { FilterModalButtons, FilterModalWrapper } from '@dock/react';
import { FormControl } from '@mui/material';
import type {
    CardsAuthorizationEventStatus,
    CardsAuthorizationEventKind,
} from '@dock/types-dock-partner';
import {
    SimpleDialogProps,
    CheckItems,
    generateKeys,
    useCheckItems,
    useAmounts,
    AmountInputRange,
    authorizationEventStatusMap,
    authorizationEventKindMap,
} from '../../../../common';
import { CardAuthsEventsFilterURLParams } from './useCardAuthsEventsFilters';
import commonLang from '../../../../lang/common';
import cardsLang from '../../../../lang/cards';

const cardAuthEventStatusKeys = generateKeys(authorizationEventStatusMap);
const cardAuthEventKindKeys = generateKeys(authorizationEventKindMap);

export function CardAuthorizationsEventsFilter({
    isOpen,
    handleClose,
    fields,
    onApply,
}: SimpleDialogProps<CardAuthsEventsFilterURLParams>) {
    const [{ minAmount, maxAmount }, setAmount, handleAmountChange] = useAmounts({
        minAmount: fields.minAmount,
        maxAmount: fields.maxAmount,
    });

    const [status, setStatus, onStatusCheck] = useCheckItems<CardsAuthorizationEventStatus>(
        fields.status || []
    );

    const [kind, setKind, onKindCheck] = useCheckItems<CardsAuthorizationEventKind>(
        fields.kind || []
    );

    const handleApply = () => {
        onApply({
            ...fields,
            status,
            kind,
            minAmount: minAmount || '',
            maxAmount: maxAmount || '',
        });
        handleClose();
    };

    const handleReset = () => {
        setStatus([]);
        setKind([]);
        setAmount({ minAmount: '', maxAmount: '' });
        onApply({
            status: [],
            kind: [],
            minAmount: '',
            maxAmount: '',
        });
        handleClose();
    };

    return (
        <FilterModalWrapper
            handleClose={handleClose}
            isOpen={isOpen}
            title={commonLang.FILTER}
            testId="cardAuthorizationsEventsFilter"
        >
            <FormControl component="fieldset" variant="standard">
                <CheckItems
                    title={commonLang.STATUS}
                    items={status}
                    keys={cardAuthEventStatusKeys}
                    handleChange={onStatusCheck}
                />
            </FormControl>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <CheckItems
                    title={cardsLang.TYPE}
                    items={kind}
                    keys={cardAuthEventKindKeys}
                    handleChange={onKindCheck}
                />
            </FormControl>
            <AmountInputRange
                label={commonLang.AMOUNT}
                maxAmount={maxAmount}
                minAmount={minAmount}
                handleChange={handleAmountChange}
                helperText={commonLang.FILTERS.AMOUNT_TEXT}
            />
            <FilterModalButtons
                applyText={commonLang.APPLY}
                resetText={commonLang.RESET_FILTERS}
                onApplyClick={handleApply}
                onResetClick={handleReset}
            />
        </FilterModalWrapper>
    );
}
