import { KeysToSnakeCase } from '@dock/mock-server';
import { BankTransferInputBankTransferInstrument } from '@dock/types-dock-partner';
import commonLang from '../../../../lang/common';
import btLang from '../../../../lang/bankTransfers';

export const responseFieldsMap: Record<
    keyof KeysToSnakeCase<BankTransferInputBankTransferInstrument>,
    string
> = {
    account_id: commonLang.ACCOUNT_ID,
    definition_id: btLang.DEFINITION_ID,
    external_reference: commonLang.EXTERNAL_REFERENCE,
    holder_id: btLang.HOLDER_ID,
    tenant_id: commonLang.TENANT_ID,
    notes: commonLang.NOTES,
};
