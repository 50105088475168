import { Column, DateRangeDropDown, FilterButton, SearchBox, SimpleTable } from '@dock/react';
import { Box } from '@mui/material';
import { prefillDatesFromAlways } from '@dock/common';
import { useModal } from '@dock/react-hooks';
import A2ATableLang from '../../../../lang/a2a';
import { A2ATableRow } from './A2ATableRow';
import { useA2AList } from '../../../../services';
import { transformA2AListParams } from './transformA2AListParams';
import { A2AListFilters } from './A2AListFilter';
import { useA2AListFilters } from './useA2AListFilters';
import { TableFiltersWrapper, filterWrapperStyles } from '../../../../common';

export const a2aTableColumns: Column[] = [
    { key: 'id', name: A2ATableLang.A2A_ID },
    { key: 'holderId', name: A2ATableLang.A2A_HOLDER_ID },
    { key: 'status', name: A2ATableLang.A2A_STATUS },
    { key: 'externalReference', name: A2ATableLang.EXTERNAL_REFERENCE },
    { key: 'createdTime', name: A2ATableLang.CREATED_TIME, sortable: true },
    { key: 'action', name: '' },
];

export function A2AListTable() {
    const { isOpen, setClose, setOpen } = useModal();

    const {
        filters,
        handlePopUpFilter,
        handleSearch,
        handleDateFilter,
        isPopFilterActive,
        sorting,
        handleSortOrder,
    } = useA2AListFilters();

    const { data, error, fetchNextPage, isFetching, isFetchingNextPage } = useA2AList({
        params: transformA2AListParams(filters),
    });

    const [dateFrom, dateTo] = prefillDatesFromAlways(filters.from, filters.to);

    return (
        <>
            <TableFiltersWrapper>
                <SearchBox
                    label={A2ATableLang.SEARCH_FOR_ID}
                    setLastSearchValue={handleSearch}
                    initValue={filters.text || ''}
                />

                <Box
                    sx={{
                        ...filterWrapperStyles,
                        justifyContent: 'flex-end',
                    }}
                >
                    <DateRangeDropDown
                        handleFilters={handleDateFilter}
                        from={dateFrom}
                        to={dateTo}
                    />
                    <FilterButton isFilterOn={isPopFilterActive} onClick={setOpen} />
                </Box>
            </TableFiltersWrapper>
            <A2AListFilters
                onApply={handlePopUpFilter(setOpen)}
                handleClose={setClose}
                isOpen={isOpen}
                fields={{
                    status: filters.status || [],
                }}
            />
            <SimpleTable
                columns={a2aTableColumns}
                RowComponent={A2ATableRow}
                isLoading={isFetching}
                rows={data}
                isLoadingNextPage={isFetchingNextPage}
                onReachingBottom={fetchNextPage}
                error={error}
                sorting={sorting}
                onSortClick={handleSortOrder}
            />
        </>
    );
}
