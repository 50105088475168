import * as Sentry from '@sentry/react';
import { HttpInstance } from '@dock/http-request';
import { replaceLocation } from '@dock/common';
import { LOGIN_ROUTE, ENV_VARS } from '../common';
import { updateTokens } from './api/auth/updateTokens';
import { PartnerServiceError } from '../common/PartnerServiceError';

// This flag indicates whether a token refresh is currently in progress.
let isRefreshing = false;
// The refreshQueue array holds functions that resolve the waiting requests.
let refreshQueue: (() => void)[] = [];

export const handleTokenRefresh = async () => {
    if (!isRefreshing) {
        isRefreshing = true;

        try {
            // Perform the token refresh
            await updateTokens();

            // Resolve all queued requests
            refreshQueue.forEach((resolveRequest) => resolveRequest());
        } catch (e) {
            replaceLocation(LOGIN_ROUTE);
            refreshQueue.forEach((rejectRequest) => rejectRequest());
        } finally {
            isRefreshing = false;
            refreshQueue = [];
        }
    } else {
        // If a refreshing is already in progress, add request to the queue
        const retryRequest = new Promise<void>((resolve) => {
            refreshQueue.push(() => resolve());
        });
        await retryRequest;
    }
};

export const dockPartnerAppInterceptorResponseError = async (
    error: PartnerServiceError,
    instance: HttpInstance
) => {
    if (ENV_VARS.IS_PRODUCTION_BUILD) {
        Sentry.captureException(error);
    }

    if (error.code === 401 && error.request !== 'v0/tokens') {
        await handleTokenRefresh();

        const originalRequest = error.config;

        return originalRequest ? instance(originalRequest) : Promise.reject(error);
    }

    return Promise.reject(error);
};
