import React from 'react';
import { authContext } from './authContext';
import { useProvideAuth } from './useProvideAuth';

type ProvideAuthProps = {
    children: React.ReactNode;
};

export function ProvideAuth({ children }: ProvideAuthProps) {
    const auth = useProvideAuth();

    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
