import { ListCardAuthorizationEventsParams } from '@dock/types-dock-partner';
import { filterUrlParamsExtractor } from '../../../../common';
import {
    CardAuthsEventsFilterURLParams,
    CardAuthsEventsSearchFields,
} from './useCardAuthsEventsFilters';

const authsEventsPropertyMap: Record<string, keyof ListCardAuthorizationEventsParams> = {
    [CardAuthsEventsSearchFields.authorizationId]: 'authorization_id',
    [CardAuthsEventsSearchFields.cardId]: 'card_id',
};

const amountAndDateUrlParamsExtractor = filterUrlParamsExtractor({
    dateKey: 'datetimes/initiated',
    amountKey: 'amounts/requested/amount',
});

const customKeyUrlParamsExtractor =
    amountAndDateUrlParamsExtractor<ListCardAuthorizationEventsParams>([
        { key: 'status', value: 'status' },
        { key: 'kind', value: 'kind' },
    ]);

export const transformCardAuthsEventsParams =
    customKeyUrlParamsExtractor<CardAuthsEventsFilterURLParams>(authsEventsPropertyMap);
