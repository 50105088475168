import { Accordion, AccordionTitle, TextWithCopyIcon, TextWithCopyIconContent } from '@dock/react';
import { Grid, Typography } from '@mui/material';
import {
    ACCOUNT_DETAILS_TRANSACTIONS_ROUTE,
    interpolateParams,
    LinkWithCopyIcon,
} from '../../../common';

interface ExternalReferencesAccordionProps {
    cardId: string;
    cardholderId: string;
    accountId: string;
    externalReference: string;
}

export function ExternalReferencesAccordion({
    accountId,
    cardId,
    cardholderId,
    externalReference,
}: ExternalReferencesAccordionProps) {
    const interpolatedLinkToAccountsDetails = interpolateParams(
        ACCOUNT_DETAILS_TRANSACTIONS_ROUTE,
        {
            id: accountId,
        }
    );

    return (
        <Accordion title="ID & external reference" testId="externalReferencesAccordion">
            <Grid container rowSpacing="20px">
                <Grid item xs={4} xxl={3}>
                    <AccordionTitle content="Card ID" />
                    <TextWithCopyIcon text={cardId}>
                        <TextWithCopyIconContent text={cardId} ellipsis="start" variant="h4" />
                    </TextWithCopyIcon>
                </Grid>

                <Grid item xs={4} xxl={3}>
                    <AccordionTitle content="Cardholder ID" />
                    <TextWithCopyIcon text={cardholderId}>
                        <TextWithCopyIconContent
                            text={cardholderId}
                            ellipsis="start"
                            variant="h4"
                            testId="cardIdValue"
                        />
                    </TextWithCopyIcon>
                </Grid>

                <Grid item xs={4} xxl={3}>
                    <AccordionTitle content="Account ID" />

                    <TextWithCopyIcon text={accountId}>
                        <LinkWithCopyIcon
                            text={accountId}
                            href={interpolatedLinkToAccountsDetails}
                            ellipsis="start"
                            variant="h4"
                            testId="accountIdValue"
                        />
                    </TextWithCopyIcon>
                </Grid>

                <Grid item xs={4} xxl={3}>
                    <AccordionTitle content="External Reference" />
                    <Typography variant="h3" data-testid="externalReferenceValue">
                        {externalReference || '-'}
                    </Typography>
                </Grid>
            </Grid>
        </Accordion>
    );
}
