import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../auth';
import { LOGIN_ROUTE } from '../../../common';

type ProtectedRouteProps = {
    children: React.ReactNode;
};

export function ProtectedRoute({ children }: ProtectedRouteProps) {
    const { isLoggedIn } = useAuth();
    const location = useLocation();

    if (!isLoggedIn) {
        /* Redirect them to the /login page, but save the current location they were
        trying to go to when they were redirected. This allows us to send them
        along to that page after they log in, which is a nicer user experience
        than dropping them off on the home page.
        */
        return <Navigate to={LOGIN_ROUTE} state={{ from: location }} replace />;
    }

    return children;
}
