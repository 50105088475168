import { ListInstrumentsParams, A2ATransfersInstrumentStatus } from '@dock/types-dock-partner';
import { BaseFilterParams, dateFilterUrlParamsExtractor } from '../../../../common';

export type A2AListURLParams = BaseFilterParams<string> & {
    status?: A2ATransfersInstrumentStatus[];
};

const customKeyUrlParamsExtractor = dateFilterUrlParamsExtractor([
    { key: 'status', value: 'status' },
]);

export const transformA2AListParams = (urlParams: A2AListURLParams): ListInstrumentsParams => {
    const { text, searchBy } = urlParams;

    if (text) {
        return {
            id: searchBy === 'instrumentId' && text ? [text] : [],
        };
    }

    return customKeyUrlParamsExtractor()(urlParams);
};
