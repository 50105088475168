import { WhiteBackgroundWrapper, PageContainer } from '@dock/react';
import { getAccountTabConfig } from './getTabConfig';
import { NavLinkTabSwitcher, PageActionButtonWrapper, BreadcrumbsWrapper } from '../../common';
import { CreateAccount } from './components/CreateAccount/CreateAccount';
import { accountsBL } from './BL/account.BL';
import { AccountsTable } from './components/AccountsTable/AccountsTable';

export function Accounts() {
    const tabs = getAccountTabConfig();

    return (
        <PageContainer testId="accountsPage">
            <BreadcrumbsWrapper>
                <PageActionButtonWrapper>
                    {accountsBL.isCreateAccountAvailable() && <CreateAccount />}
                </PageActionButtonWrapper>
            </BreadcrumbsWrapper>

            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher tabs={tabs} />
                <AccountsTable />
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
