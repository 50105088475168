import { TransactionsOverview } from './TransactionsOverview';
import { AuthorizationsEventsOverviewTable } from './components/AuthorizationsEventsTable/AuthorizationsEventsOverviewTable';

export function CardAuthsEventsOverviewPage() {
    return (
        <TransactionsOverview>
            <AuthorizationsEventsOverviewTable />
        </TransactionsOverview>
    );
}
