import { Download, useModal } from '@dock/react';
import { MAX_ENTITIES_IN_RESPONSE } from '@dock/common';
import { InfiniteQueryObserverResult } from '@tanstack/react-query';
import { ListA2ATransactionsParams } from '@dock/types-dock-partner';
import { useAccountsList, useExportAccountDetailsTxs } from '../../../../services';
import { transformAccountTransactionsParams } from './transformAccountTransactionsParams';
import { AccountTransactionFiltersURLParams } from './useAccountTransactionsFilters';
import { AccountTransactionCsv } from './types';
import { formatTx } from './formatters';

type DownloadContainerProps = {
    filters: AccountTransactionFiltersURLParams;
    accountId: string;
    onApplyFilterClick: () => void;
};

const emptyData: AccountTransactionCsv = {
    bookingTime: '',
    id: '',
    paymentInstrument: '',
    instrumentId: '',
    instrumentTxId: '',
    counterparty: '',
    amount: '',
    balance: '',
    currency: '',
};

export function DownloadContainer({
    filters,
    accountId,
    onApplyFilterClick,
}: DownloadContainerProps) {
    const { isOpen, setClose, setOpen } = useModal();

    const params: ListA2ATransactionsParams = {
        ...transformAccountTransactionsParams(filters, accountId),
        _size: MAX_ENTITIES_IN_RESPONSE,
    };

    const { data, fetchNextPage, isFetching, hasNextPage, isError } = useExportAccountDetailsTxs({
        params,
        enabled: isOpen,
    });

    const { data: accounts } = useAccountsList({
        params: { id: [accountId] },
    });
    const accountInfo = accounts[0];
    const accountLabel = accountInfo?.label || '';

    const mappedData = data.map(formatTx);

    return (
        <Download<InfiniteQueryObserverResult>
            isOpen={isOpen}
            setClose={setClose}
            setOpen={setOpen}
            data={mappedData}
            emptyData={[emptyData]}
            fetchNextPage={fetchNextPage}
            isFetching={isFetching}
            isError={isError}
            hasNextPage={hasNextPage}
            onApplyFilterClick={onApplyFilterClick}
            fileNamePrefix={`account-transactions_${accountLabel}`}
        />
    );
}
