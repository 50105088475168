import { KeysToSnakeCase } from '@dock/mock-server';
import commonLang from '../../../../lang/common';
import accountsLang from '../../../../lang/accounts';

export type CreateAccountPayloadType = {
    tenantId: string;
    accountHolderId: string;
    label: string;
    productId: string;
    externalReference: string;
};

export const responseFieldsMap: Record<keyof KeysToSnakeCase<CreateAccountPayloadType>, string> = {
    tenant_id: commonLang.TENANT_ID,
    account_holder_id: accountsLang.ACCOUNT_HOLDER_ID,
    label: accountsLang.ACCOUNT_LABEL,
    product_id: commonLang.PRODUCT_ID,
    external_reference: commonLang.EXTERNAL_REFERENCE,
};
