import { TableRow, TableCell } from '@mui/material';
import { AmountCell, TextWithCopyIcon, TextWithCopyIconContent } from '@dock/react';
import {
    CardsAuthorizationEventAmounts,
    CardsAuthorizationEventCurrencyConversion,
} from '@dock/types-dock-partner';
import { TablesTypography } from '../../../common';

type CollapsableAuthEventRowProps = {
    id: string;
    currencyConversion: CardsAuthorizationEventCurrencyConversion;
    amounts: CardsAuthorizationEventAmounts;
};

export function CollapsableAuthEventRow({
    id,
    currencyConversion,
    amounts,
}: CollapsableAuthEventRowProps) {
    return (
        <TableRow>
            <TableCell data-testid="authEventId">
                <TextWithCopyIcon text={id}>
                    <TextWithCopyIconContent text={id} ellipsis="start" />
                </TextWithCopyIcon>
            </TableCell>
            <AmountCell amount={amounts.approved.amount} testId="approvedAmountCell" align="left" />
            <TableCell data-testid="approvedCurrencyCell">
                <TablesTypography content={amounts.approved.currency} />
            </TableCell>
            <AmountCell
                amount={amounts.requested.amount}
                testId="requestedAmountCell"
                align="left"
            />
            <TableCell data-testid="requestedCurrencyCell">
                <TablesTypography content={amounts.requested.currency} />
            </TableCell>
            <TableCell data-testid="currencyConversionCell">
                <TablesTypography content={currencyConversion.conversionRate} />
            </TableCell>
        </TableRow>
    );
}
