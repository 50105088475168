import { AccountsDetailsPage } from './AccountsDetailsPage';
import { AccountPaymentMethodsTable } from './components/AccountPaymentMethods/AccountPaymentMethodsTable';

export function AccountDetailsPayMethodsTable() {
    return (
        <AccountsDetailsPage>
            <AccountPaymentMethodsTable />
        </AccountsDetailsPage>
    );
}
