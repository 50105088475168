import { Box, Typography, Link } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import commonLang from '../../../lang/common';

const SUPPORT_EMAIL = 'support@dock.financial';

export function SidebarSupportBox() {
    return (
        <Box
            sx={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'end',
                paddingBottom: '2em',
            }}
        >
            <Box
                sx={{
                    mx: 1.5,
                    p: 1,
                    width: '90%',
                    backgroundColor: ({ palette }) => `${palette.secondary.main}33`,
                    color: 'background.paper',
                    borderRadius: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: 1,
                }}
            >
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <SupportAgentIcon />
                    <Typography>{commonLang.SUPPORT}</Typography>
                </Box>
                <Link
                    sx={{ color: 'secondary.light', mx: '32px' }}
                    data-testid="supportEmail"
                    href={`mailto:${SUPPORT_EMAIL}`}
                >
                    {SUPPORT_EMAIL}
                </Link>
            </Box>
        </Box>
    );
}
