import { A2ATransfersInstrumentStatus } from '@dock/types-dock-partner';
import { ChipColors } from '../../../../common';

export const a2aStatusColorMap: Record<A2ATransfersInstrumentStatus, ChipColors> = {
    ACTIVATED: ChipColors.success,
    UNACTIVATED: ChipColors.default,
    LOCKED: ChipColors.error,
    TERMINATED_OBSOLETE: ChipColors.info,
    TERMINATED: ChipColors.info,
};
