import React from 'react';
import { WhiteBackgroundWrapper, PageContainer } from '@dock/react';
import { useParams } from 'react-router-dom';
import { A2ADetailsBox } from './components/A2ADetailsBox';
import { getA2ADetailsTabConfig } from './getTabConfig';
import { NavLinkTabSwitcher, BreadcrumbsWrapper, PageActionButtonWrapper } from '../../common';
import { PayA2ATransfer } from './components/PayA2ATransfer/PayA2ATransfer';
import { a2aDetailsBL } from './BL/BL';

type A2ADetailsProps = {
    children: React.ReactNode;
};

export function A2ADetails({ children }: A2ADetailsProps) {
    if (!a2aDetailsBL.isReadA2APaymentAvailable()) {
        return null;
    }

    const { id: a2aId = '' } = useParams();

    const tabs = getA2ADetailsTabConfig(a2aId);

    return (
        <PageContainer testId="a2aDetailsPage">
            <BreadcrumbsWrapper>
                <PageActionButtonWrapper>
                    {a2aDetailsBL.isCreateA2APaymentAvailable() && <PayA2ATransfer />}
                </PageActionButtonWrapper>
            </BreadcrumbsWrapper>

            <A2ADetailsBox id={a2aId} />

            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher tabs={tabs} />
                {children}
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
