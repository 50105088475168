import { TableCell } from '@mui/material';
import {
    ActionMenuItemProps,
    ActionsMenu,
    TextWithCopyIcon,
    TextWithCopyIconContent,
    ViewDetailsLink,
    NavLink,
} from '@dock/react';
import { transformDate } from '@dock/react-util';
import type { CardsCard } from '@dock/types-dock-partner';
import {
    interpolateParams,
    ENV_VARS,
    CARD_DETAILS_TRANSACTIONS_ROUTE,
    TablesTypography,
    LinkWithCopyIcon,
    CustomVirtualisationWrapper,
    StatusChip,
} from '../../../../common';
import { cardStatusMap } from './cardStatusMap';
import { cardStatusColorMap } from './cardStatusColorMap';

export type CardsTableRowProps = Pick<
    CardsCard,
    'id' | 'datetimes' | 'cardHolderId' | 'status' | 'externalReference'
>;

export function CardsTableRow({
    id,
    datetimes,
    cardHolderId,
    status,
    externalReference,
}: CardsTableRowProps) {
    const createdTime = transformDate(new Date(datetimes.created));
    const expirationTime = transformDate(new Date(datetimes.expires));

    const actions: ActionMenuItemProps[] = [
        {
            content: (
                <NavLink
                    to={interpolateParams(CARD_DETAILS_TRANSACTIONS_ROUTE, {
                        id,
                    })}
                    label={<ViewDetailsLink />}
                />
            ),
            id: '0',
        },
    ];

    return (
        <CustomVirtualisationWrapper
            index={id}
            testId="cardRow"
            isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
        >
            <TableCell data-testid="cardIdCell">
                <TextWithCopyIcon text={id}>
                    <LinkWithCopyIcon
                        href={interpolateParams(CARD_DETAILS_TRANSACTIONS_ROUTE, {
                            id,
                        })}
                        text={id}
                        ellipsis="start"
                    />
                </TextWithCopyIcon>
            </TableCell>
            <TableCell data-testid="cardHolderIdCell">
                <TextWithCopyIcon text={cardHolderId}>
                    <TextWithCopyIconContent text={cardHolderId} ellipsis="start" />
                </TextWithCopyIcon>
            </TableCell>
            <TableCell data-testid="cardStatusCell">
                <StatusChip
                    color={cardStatusColorMap[status]}
                    label={cardStatusMap[status] || status}
                />
            </TableCell>
            <TableCell data-testid="externalReferenceCell">
                <TablesTypography content={externalReference || ''} />
            </TableCell>
            <TableCell data-testid="createdTimeCell">
                <TablesTypography content={createdTime} />
            </TableCell>
            <TableCell data-testid="expirationTimeCell">
                <TablesTypography content={expirationTime} />
            </TableCell>
            <TableCell data-testid="actionsCell">
                <ActionsMenu menuItems={actions} />
            </TableCell>
        </CustomVirtualisationWrapper>
    );
}
