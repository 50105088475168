import { WhiteBackgroundWrapper, PageContainer } from '@dock/react';
import { NavLinkTabSwitcher, PageActionButtonWrapper, BreadcrumbsWrapper } from '../../common';
import { getBankTransfersTabConfig } from './getTabConfig';
import { CreateBankTransferInstrument } from './components/CreateBankTransferInstrument/CreateBankTransferInstrument';
import { InstrumentsTable } from './components/InstrumentsTable/InstrumentsTable';

export function BankTransfer() {
    const tabs = getBankTransfersTabConfig();

    return (
        <PageContainer testId="bankTransferPage">
            <BreadcrumbsWrapper>
                <PageActionButtonWrapper>
                    <CreateBankTransferInstrument />
                </PageActionButtonWrapper>
            </BreadcrumbsWrapper>
            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher tabs={tabs} />
                <InstrumentsTable />
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
