import {
    CardsMethod,
    CardsAuthorizationStatus,
    ListCardAuthorizationsParams,
} from '@dock/types-dock-partner';
import {
    CardAuthsFilterURLParams,
    CardAuthsSearchFields,
    CardTransactionsSearchFields,
} from '../../formatters';
import { FilterReturnTypes, getBasicEmptyFilters, useFilters } from '../../hooks';
import {
    filterUrlParamsExtractor,
    getAllTypedParamValues,
    getTypedParamValue,
} from '../../extractors';

export type CardAuthsPopUpFilterParams = Partial<
    Pick<CardAuthsFilterURLParams, 'sourceMethod' | 'status' | 'minAmount' | 'maxAmount'>
>;

const getEmptyFilters =
    (defaultSearchBy: CardAuthsSearchFields) =>
    (params: URLSearchParams): CardAuthsFilterURLParams => ({
        ...getBasicEmptyFilters({ params, sortByKey: 'initiatedTime' }),
        sourceMethod: getAllTypedParamValues('sourceMethod', params, CardsMethod),
        status: getAllTypedParamValues('status', params, CardsAuthorizationStatus),
        searchBy: getTypedParamValue('searchBy', params, CardAuthsSearchFields, defaultSearchBy),
    });

type CardAuthsFilterReturnType = FilterReturnTypes<
    CardAuthsFilterURLParams,
    CardAuthsPopUpFilterParams,
    CardAuthsSearchFields
>;
const customFieldsExtractor =
    (defaultSearchBy: CardAuthsSearchFields) => (fields: CardAuthsFilterURLParams) => ({
        sourceMethod: fields.sourceMethod || [],
        status: fields.status || [],
        searchBy: fields.searchBy || defaultSearchBy,
    });

export const useCardAuthsFilters = (
    defaultSearchBy: CardAuthsSearchFields
): CardAuthsFilterReturnType =>
    useFilters<CardAuthsFilterURLParams>(
        getEmptyFilters(defaultSearchBy),
        customFieldsExtractor(defaultSearchBy)
    );

const authsPropertyMap: Record<string, keyof ListCardAuthorizationsParams> = {
    [CardTransactionsSearchFields.authorizationId]: 'id',
    [CardTransactionsSearchFields.cardId]: 'card_id',
};

const amountAndDateUrlParamsExtractor = filterUrlParamsExtractor({
    dateKey: 'datetimes/initiated',
    amountKey: 'amounts/approved/amount',
});

const customKeyUrlParams = [
    { key: 'status', value: 'status' },
    { key: 'source/method', value: 'sourceMethod' },
];

const cardAuthUrlKeyExtractor = amountAndDateUrlParamsExtractor<ListCardAuthorizationsParams>([
    { key: 'card_id', value: 'cardId' },
    ...customKeyUrlParams,
]);

type ExtendedCardAuthsFilterURLParams = CardAuthsFilterURLParams & {
    cardId?: string;
};
export const transformCardAuthsParams =
    cardAuthUrlKeyExtractor<ExtendedCardAuthsFilterURLParams>(authsPropertyMap);

export const transformCardAuthsOverviewParams =
    amountAndDateUrlParamsExtractor<ListCardAuthorizationsParams>(
        customKeyUrlParams
    )<ExtendedCardAuthsFilterURLParams>(authsPropertyMap);
