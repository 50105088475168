import type { DockError } from '@dock/common';
import type {
    CardsFulfillmentStatus,
    CardsCardStatus,
    GetCardData,
} from '@dock/types-dock-partner';
import { useQuery } from '@tanstack/react-query';
import { CARD_DETAILS_KEY } from '../../keys';
import { fetchCardDetails } from '../methods';
import { cardTypeMap } from '../../../common';

interface ExternalReferences {
    accountId: string;
    cardId: string;
    cardholderId: string;
    externalReference: string;
}

interface CardKind {
    kind: string;
    cardStatus: CardsCardStatus | undefined;
    fulfillmentStatus: CardsFulfillmentStatus | undefined;
}

interface GeneralCardData {
    cardHolder: string;
    cardNumber: string;
    expirationDate: string;
}

type AccountsListType = {
    data: GetCardData | undefined;
    externalReferences: ExternalReferences;
    generalCardData: GeneralCardData;
    cardKind: CardKind;
    isLoading: boolean;
    isError: boolean;
};

export function useCardDetails(cardId: string): AccountsListType {
    const { isLoading, isError, data } = useQuery<
        GetCardData | undefined,
        DockError,
        GetCardData | undefined,
        string[]
    >([CARD_DETAILS_KEY, cardId], () => fetchCardDetails(cardId), {
        enabled: !!cardId,
    });

    const externalReferences = {
        accountId: data?.accountId || '',
        cardId: data?.id || '',
        cardholderId: data?.cardHolderId || '',
        externalReference: data?.externalReference || '',
    };

    const isExpirationDateValid = data?.expiration?.year && data?.expiration?.month;

    const generalCardData = {
        cardHolder: data?.personalization.line1 || '',
        cardNumber: data?.last4 || '',
        expirationDate: isExpirationDateValid
            ? `${data.expiration.month}/${data.expiration.year}`
            : '-',
    };

    const cardKind = {
        kind: data?.kind ? cardTypeMap[data.kind] : data?.kind || '',
        cardStatus: data?.status,
        fulfillmentStatus: data?.fulfillment.status,
    };

    return {
        data,
        cardKind,
        externalReferences,
        generalCardData,
        isLoading,
        isError,
    };
}
