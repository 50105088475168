import { flow } from 'fp-ts/lib/function';
import { ListCardTransactionsData } from '@dock/types-dock-partner';
import { handleErrors } from '../../../../errorService';
import {
    requestFlow,
    getDataAndHeaders,
    RequestAndUnwrap,
    unwrapService,
    ResponseWithContinuationToken,
} from '../../../../utilities';

export type CardTransactionListWithToken = ResponseWithContinuationToken<ListCardTransactionsData>;

export const fetchCardsTransactions = requestFlow(handleErrors)(`v0/cards/transactions`);

const fetchAndMap = fetchCardsTransactions<CardTransactionListWithToken>(getDataAndHeaders);

export const getCardsTransactions: RequestAndUnwrap<'get', CardTransactionListWithToken> = flow(
    fetchAndMap,
    unwrapService<CardTransactionListWithToken>
);
