import { WhiteBackgroundWrapper, PageContainer } from '@dock/react';
import { useParams } from 'react-router-dom';
import { A2APaymentDetailTable } from './components/A2APaymentsDetailTable';
import { TransactionsPaymentEventTable } from './components/PaymentEvents';
import { BreadcrumbsWrapper, NavLinkTabSwitcher } from '../../common';
import lang from '../../lang/a2aTransactionsDetails';

export function A2APaymentDetails() {
    const { transactionId = '' } = useParams();

    return (
        <PageContainer testId="a2APaymentDetailsPage">
            <BreadcrumbsWrapper />
            <A2APaymentDetailTable transactionId={transactionId} />
            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher
                    tabs={[
                        {
                            label: lang.PAYMENT_EVENTS,
                            link: '',
                        },
                    ]}
                />
                <TransactionsPaymentEventTable />
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
