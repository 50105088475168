import { SxProps, Theme, Typography } from '@mui/material';

type TablesTypographyProps = {
    content: string;
    styles?: SxProps<Theme>;
    testId?: string;
};

export function TablesTypography({ content, testId, styles }: TablesTypographyProps) {
    return (
        <Typography sx={{ ...(styles && styles) }} data-testid={testId}>
            {content}
        </Typography>
    );
}
