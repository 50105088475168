export const transactionDetailColumns = {
    transactionId: 'Transaction ID',
    kind: 'Payment scheme',
    amount: 'Amount',
    currency: 'Currency',
    dateTime: 'Time created',
    bankTransferId: 'Bank transfer ID',
    paymentDetails: 'Payment details',
    paymentType: 'Payment type',
};

export const transactionCounterPartyColumns = {
    name: 'Name',
    bic: 'BIC (Bank Identifier Code)',
    iban: 'IBAN (Bank Account Number)',
    address: 'Address',
    city: 'City',
    state: 'State',
    country: 'Country',
};
