import { KeysToSnakeCase } from '@dock/mock-server';
import { CreateInstrumentData } from '@dock/types-dock-partner';
import commonLang from '../../../../lang/common';
import a2aLang from '../../../../lang/a2a';

export const responseFieldsMap: Record<keyof KeysToSnakeCase<CreateInstrumentData>, string> = {
    account_id: commonLang.ACCOUNT_ID,
    definition_id: commonLang.PRODUCT_ID,
    external_reference: commonLang.EXTERNAL_REFERENCE,
    holder_id: a2aLang.A2A_HOLDER_ID,
    a_2_a_holder_id: a2aLang.A2A_HOLDER_ID,
    tenant_id: commonLang.TENANT_ID,
    notes: commonLang.NOTES,
    datetimes: commonLang.DATETIMES,
    status: commonLang.STATUS,
    id: commonLang.ID,
};
