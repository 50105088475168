import { AccountsDetailsPage } from './AccountsDetailsPage';
import { TransactionTable } from './components/AccountTransactions/TransactionTable';

export function AccountDetailsTransactionsTable() {
    return (
        <AccountsDetailsPage>
            <TransactionTable />
        </AccountsDetailsPage>
    );
}
