import { useParams } from 'react-router-dom';
import { PageContainer } from '@dock/react';
import { CardTransactionDetailTable } from './components/CardTransactionDetailTable';
import { BreadcrumbsWrapper } from '../../common';

export function CardTransactionDetails() {
    const { id: cardId = '', transactionId = '' } = useParams();
    return (
        <PageContainer testId="cardTransactionDetailsPage">
            <BreadcrumbsWrapper />
            <CardTransactionDetailTable cardId={cardId} transactionID={transactionId} />
        </PageContainer>
    );
}
