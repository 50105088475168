import React from 'react';
import { WhiteBackgroundWrapper, PageContainer } from '@dock/react';
import { getTabConfig } from './getTabConfig';
import { BreadcrumbsWrapper, NavLinkTabSwitcher } from '../../common';

type TransactionsOverviewProps = {
    children: React.ReactNode;
};

export function TransactionsOverview({ children }: TransactionsOverviewProps) {
    const tabs = getTabConfig();

    return (
        <PageContainer testId="cardTransactionsOverviewPage">
            <BreadcrumbsWrapper />
            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher tabs={tabs} />
                {children}
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
