import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { TypographyWithTooltip, ControlledInput, FormActionButtons } from '@dock/react';
import type { z } from 'zod';
import { gridInput } from '@dock/react-mui';
import type { CreateCardFormType } from './CreateNewCardModal';
import cardsLang from '../../../../lang/cards';
import commonLang from '../../../../lang/common';
import {
    createCardPersonalisationDetailsStep,
    createCardPersonalisationDetailsStepKeys,
} from './createNewCardSchema';

type CreateNewCardFormValues = z.infer<typeof createCardPersonalisationDetailsStep>;

type BasicDetailsStepStepProps = {
    handleForm: (values: Partial<CreateCardFormType>) => void;
    handleNextStep: () => void;
    defaultValues: CreateNewCardFormValues;
    handleCloseModal: () => void;
    handleBackStep: () => void;
};

export function PersonalisationDetailsStep({
    handleForm,
    handleNextStep,
    handleBackStep,
    defaultValues,
    handleCloseModal,
}: BasicDetailsStepStepProps) {
    const { control, handleSubmit } = useForm<CreateNewCardFormValues>({
        defaultValues,
        resolver: zodResolver(createCardPersonalisationDetailsStep),
    });

    const onSubmitHandler = (values: CreateNewCardFormValues) => {
        handleForm({
            line2: values.line2 || '',
            line3: values.line3 || '',
            qrCodeContent: values.qrCodeContent || '',
        });
        handleNextStep();
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid container>
                <Grid item xs={11} sx={{ mb: '16px' }}>
                    <Typography>{cardsLang.PERSONAL_DETAILS.NOTE}</Typography>
                </Grid>
                <Grid item xs={11} sx={gridInput}>
                    <ControlledInput
                        control={control}
                        label={cardsLang.LINE_2}
                        name={createCardPersonalisationDetailsStepKeys.line2}
                        isFullWidth
                        testId="line2Input"
                    />
                </Grid>
                <Grid item xs={1} sx={gridInput}>
                    <TypographyWithTooltip
                        text={cardsLang.PERSONAL_DETAILS.LINE_2_TOOLTIP}
                        variant="body2"
                        isWithIcon
                    />
                </Grid>

                <Grid item xs={11} sx={gridInput}>
                    <ControlledInput
                        control={control}
                        label={cardsLang.LINE_3}
                        name={createCardPersonalisationDetailsStepKeys.line3}
                        isFullWidth
                        testId="line3Input"
                    />
                </Grid>
                <Grid item xs={1} sx={gridInput}>
                    <TypographyWithTooltip
                        text={cardsLang.PERSONAL_DETAILS.LINE_3_TOOLTIP}
                        variant="body2"
                        isWithIcon
                    />
                </Grid>

                <Grid item xs={11} sx={gridInput}>
                    <ControlledInput
                        control={control}
                        label={cardsLang.QR_CODE_CONTENT}
                        name={createCardPersonalisationDetailsStepKeys.qrCodeContent}
                        isFullWidth
                        testId="qrCodeContentInput"
                    />
                </Grid>
                <Grid item xs={1} sx={gridInput}>
                    <TypographyWithTooltip
                        text={cardsLang.PERSONAL_DETAILS.QR_CODE_CONTENT_TOOLTIP}
                        variant="body2"
                        isWithIcon
                    />
                </Grid>
            </Grid>

            <FormActionButtons
                onCancelHandler={handleCloseModal}
                onBackHandler={handleBackStep}
                submitButtonText={commonLang.NEXT}
            />
        </form>
    );
}
