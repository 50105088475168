import React from 'react';
import { Modal } from '@dock/react';
import { Box } from '@mui/material';

type ChangeStatusModalWrapperProps = {
    isOpen: boolean;
    handleClose: () => void;
    children: React.ReactNode;
    title: string;
    ariaTitle?: string;
};

export function ChangeStatusModalWrapper({
    handleClose,
    isOpen,
    children,
    title,
    ariaTitle,
}: ChangeStatusModalWrapperProps) {
    return (
        <Modal
            handleClose={handleClose}
            isOpen={isOpen}
            modalAriaTitle={ariaTitle || title}
            title={title}
        >
            <Box sx={{ width: '430px', marginTop: '24px' }}>{children}</Box>
        </Modal>
    );
}
