export const transactionDetailColumns = {
    id: 'Payment ID',
    recipientId: 'Recipient ID',
    senderId: 'Sender ID',
    amount: 'Amount',
    created: 'Created time',
    externalReference: 'External reference',
    description: 'Description',
};

export const transactionColumns = {
    accountLabel: 'Account label',
    accountId: 'Account ID',
    instrumentId: 'Instrument ID',
};
