import { flow } from 'fp-ts/lib/function';
import { ListA2ATransactionsData } from '@dock/types-dock-partner';
import { handleErrors } from '../../../../errorService';
import {
    requestFlow,
    RequestAndUnwrap,
    unwrapService,
    ResponseWithContinuationToken,
    getDataAndHeaders,
} from '../../../../utilities';

export type A2ATransactionResponse = ResponseWithContinuationToken<ListA2ATransactionsData>;

export const a2aTransactionsHandler = requestFlow(handleErrors)(`/v0/a2a_transfers/transactions`);

const fetchAndHandle = a2aTransactionsHandler<A2ATransactionResponse>(getDataAndHeaders);

export const getA2ATransactions: RequestAndUnwrap<'get', A2ATransactionResponse> = flow(
    fetchAndHandle,
    unwrapService<A2ATransactionResponse>
);
