import type { CardsCardStatus } from '@dock/types-dock-partner';
import cardDetailsLang from '../../../lang/cardDetails';
import { ChangeStateCardForm } from './ChangeStateCardForm/ChangeStateCardForm';
import { ChangeStatusModalWrapper } from '../../../common';

type ChangeCardStatusModalProps = {
    handleClose: () => void;
    isOpen: boolean;
    cardStatus: CardsCardStatus;
};

export function ChangeCardStatusModal({
    handleClose,
    isOpen,
    cardStatus,
}: ChangeCardStatusModalProps) {
    return (
        <ChangeStatusModalWrapper
            handleClose={handleClose}
            isOpen={isOpen}
            title={cardDetailsLang.CHANGE_CARD_STATUS}
        >
            <ChangeStateCardForm onClose={handleClose} currentCardStatus={cardStatus} />
        </ChangeStatusModalWrapper>
    );
}
