import { AccountsAccountStatus, ListAccountsParams } from '@dock/types-dock-partner';
import { dateAndAmountFilterUrlParamsExtractor, BaseFilterParams } from '../../../../common';

export type AccountsListURLParams = BaseFilterParams<AccountListSearchFields> & {
    status?: AccountsAccountStatus[];
};

export enum AccountListSearchFields {
    id = 'id',
    externalReference = 'externalReference',
}

const propertyMap: Record<string, keyof ListAccountsParams> = {
    [AccountListSearchFields.id]: 'id',
};

const customKeyUrlParamsExtractor = dateAndAmountFilterUrlParamsExtractor(
    'balances/available'
)<ListAccountsParams>([{ key: 'status', value: 'status' }]);

export const transformAccountsListParams = customKeyUrlParamsExtractor(propertyMap);
