import {
    BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE,
    interpolateParams,
    TabConfig,
} from '../../common';
import lang from '../../lang/bankTransferDetails';

export const getDetailsTabConfig = (id: string): TabConfig[] => [
    {
        label: lang.TAB.TRANSACTIONS,
        link: interpolateParams(BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE, {
            id,
        }),
    },
];
