import type { ListPaymentEventsData } from '@dock/types-dock-partner';
import { flow } from 'fp-ts/lib/function';
import { handleErrors } from '../../../../errorService';
import {
    getDataAndHeaders,
    requestFlow,
    RequestAndUnwrap,
    unwrapService,
    ResponseWithContinuationToken,
} from '../../../../utilities';

export type ListPaymentsEventResponse = ResponseWithContinuationToken<ListPaymentEventsData>;

export const listPaymentsEventsHandler = requestFlow(handleErrors)(
    `v0/a2a_transfers/payments/events`
);

const fetchAndHandle = listPaymentsEventsHandler<ListPaymentsEventResponse>(getDataAndHeaders);

export const listPaymentsEvents: RequestAndUnwrap<'get', ListPaymentsEventResponse> = flow(
    fetchAndHandle,
    unwrapService<ListPaymentsEventResponse>
);
