import { Modal } from '@dock/react';
import { SessionExpiredModalButtons } from './SessionExpiredModalButtons';
import { SessionExpiredModalInfo } from './SessionExpiredModalInfo';
import loginLang from '../../../../lang/login';

type SessionExpiredModalProps = {
    isOpen: boolean;
    onCancel: () => void;
    onContinue: () => void;
};

export function SessionExpiredModal({ isOpen, onCancel, onContinue }: SessionExpiredModalProps) {
    return (
        <Modal
            handleClose={() => {}}
            isOpen={isOpen}
            modalAriaTitle={loginLang.SESSION_EXPIRED.MAIN_TITLE}
            modalAriaDescription={loginLang.SESSION_EXPIRED.SECONDARY_TITLE}
            isShowCloseIcon={false}
        >
            <SessionExpiredModalInfo />
            <SessionExpiredModalButtons
                onCancelSessionHandler={onCancel}
                onContinueSessionHandler={onContinue}
            />
        </Modal>
    );
}
