import { TransactionsOverview } from './TransactionsOverview';
import { TransactionsOverviewTable } from './components/TransactionsTable/TransactionsOverviewTable';

export function CardTxsOverviewPage() {
    return (
        <TransactionsOverview>
            <TransactionsOverviewTable />
        </TransactionsOverview>
    );
}
