import { FilterModalButtons, FilterModalWrapper } from '@dock/react';
import type { A2ATransfersInstrumentStatus } from '@dock/types-dock-partner';
import { FormControl } from '@mui/material';
import { CheckItems, useCheckItems, SimpleDialogProps, generateKeys } from '../../../../common';
import { a2AStatusMap } from './a2AStatusMap';
import { PopUpFilterParams } from './useA2AListFilters';
import commonLang from '../../../../lang/common';
import a2aLang from '../../../../lang/a2a';

const statusKeys = generateKeys(a2AStatusMap);

export function A2AListFilters({
    isOpen,
    handleClose,
    fields,
    onApply,
}: SimpleDialogProps<PopUpFilterParams>) {
    const [status, setStatus, onStatusCheck] = useCheckItems<A2ATransfersInstrumentStatus>(
        fields.status || []
    );

    const handleApply = () => {
        onApply({
            ...fields,
            status,
        });
        handleClose();
    };

    const handleReset = () => {
        setStatus([]);
        onApply({
            status: [],
        });
        handleClose();
    };

    return (
        <FilterModalWrapper
            handleClose={handleClose}
            isOpen={isOpen}
            title={commonLang.FILTER}
            testId="a2AListFilters"
        >
            <FormControl component="fieldset" variant="standard">
                <CheckItems
                    title={a2aLang.TRANSFER_STATUS}
                    items={status}
                    handleChange={onStatusCheck}
                    keys={statusKeys}
                />
            </FormControl>
            <FilterModalButtons
                applyText={commonLang.APPLY}
                resetText={commonLang.RESET_FILTERS}
                onApplyClick={handleApply}
                onResetClick={handleReset}
            />
        </FilterModalWrapper>
    );
}
