import type { ListPaymentEventsParams } from '@dock/types-dock-partner';
import { A2APaymentFiltersURLParams } from './useA2APaymentEventFilters';
import { dateFilterUrlParamsExtractor, BasicKey } from '../../../../common';

const customKeyUrlParamsExtractor = dateFilterUrlParamsExtractor<BasicKey>([
    { key: 'payment_id', value: 'paymentId' },
    { key: 'kind', value: 'kind' },
]);

const transformFilterToParams = customKeyUrlParamsExtractor<ExtendedA2APaymentFiltersURLParams>({
    id: 'id',
});

type ExtendedA2APaymentFiltersURLParams = A2APaymentFiltersURLParams & {
    paymentId: string;
};

export const transformA2APaymentEventParams = (
    urlParams: A2APaymentFiltersURLParams,
    paymentId: string
): ListPaymentEventsParams =>
    transformFilterToParams({
        ...urlParams,
        paymentId,
    });
