import type { AuthToken } from '@dock/types-dock-partner';
import { commonBL } from '@dock/common';
import { AuthUser } from '../types';

export const loginBL = {
    isUserLoggedIn: (
        user: AuthUser | Error | undefined,
        accessToken: AuthToken | Error | undefined,
        refreshToken: AuthToken | Error | undefined,
        authAccessTokenFromStorage: string | Error | undefined
        // capabilities: UsersUserCapability[] | Error | undefined
    ): boolean => {
        if (
            user instanceof Error ||
            user === undefined ||
            accessToken instanceof Error ||
            accessToken === undefined ||
            refreshToken instanceof Error ||
            refreshToken === undefined ||
            authAccessTokenFromStorage instanceof Error ||
            authAccessTokenFromStorage === undefined
            // TODO: uncomment after BE fix
            // capabilities === undefined ||
            // capabilities instanceof Error
        ) {
            return false;
        }

        return (
            loginBL.isValidUser(user) &&
            loginBL.isValidToken(accessToken) &&
            loginBL.isValidToken(refreshToken) &&
            !!authAccessTokenFromStorage &&
            !commonBL.isTokenExpired(refreshToken)
            // capabilities?.length > 0
        );
    },
    // TODO: add type validation library (zod, class-validator, ...)
    isValidToken: (token: AuthToken) =>
        !!token && !!token.token && !!token.id && !!token.datetimes && !!token.datetimes.expires,
    isValidUser: (user: AuthUser) => !!user && !!user.id && !!user.name && !!user.email,
    shouldFetchCapabilities: (
        accessToken: AuthToken | Error | undefined,
        refreshToken: AuthToken | Error | undefined,
        authAccessTokenFromStorage: string | Error | undefined
    ): boolean => {
        if (
            accessToken instanceof Error ||
            accessToken === undefined ||
            refreshToken instanceof Error ||
            refreshToken === undefined ||
            authAccessTokenFromStorage instanceof Error ||
            authAccessTokenFromStorage === undefined
        ) {
            return false;
        }

        return (
            loginBL.isValidToken(accessToken) &&
            !commonBL.isTokenExpired(accessToken) &&
            loginBL.isValidToken(refreshToken) &&
            !commonBL.isTokenExpired(refreshToken) &&
            !!authAccessTokenFromStorage
        );
    },
};
