import {
    ActionMenuItemProps,
    ActionsMenu,
    AmountCell,
    TextWithCopyIcon,
    NavLink,
    ViewDetailsLink,
} from '@dock/react';
import { CardsTransaction } from '@dock/types-dock-partner';
import { TableCell } from '@mui/material';
import { transformDate } from '@dock/react-util';
import {
    TablesTypography,
    LinkWithCopyIcon,
    CustomVirtualisationWrapper,
    StatusChip,
    transactionKindMap,
    transactionMethodsMap,
    transactionStatusColorMap,
    transactionStatusesMap,
    interpolateParams,
    ENV_VARS,
    CARD_DETAILS_TRANSACTIONS_ROUTE,
    CARD_TRANSACTION_DETAILS_ROUTE,
} from '../../../../common';

type TransactionsOverviewRowProps = Pick<
    CardsTransaction,
    'id' | 'cardId' | 'kind' | 'amounts' | 'datetimes' | 'source' | 'merchant' | 'status'
>;

export function TransactionsOverviewRow({
    id,
    cardId,
    amounts,
    kind,
    datetimes,
    source,
    merchant,
    status,
}: TransactionsOverviewRowProps) {
    const transactionDetailsLink = interpolateParams(CARD_TRANSACTION_DETAILS_ROUTE, {
        id: cardId,
        transactionId: id,
    });

    const cardDetailsLink = interpolateParams(CARD_DETAILS_TRANSACTIONS_ROUTE, {
        id: cardId,
    });

    const CardTransactionsTableActions: ActionMenuItemProps[] = [
        {
            content: <NavLink to={transactionDetailsLink} label={<ViewDetailsLink />} />,
            id: '0',
        },
    ];

    const transformedCompletedTime = transformDate(new Date(datetimes.completed));

    const type = transactionKindMap[kind] || kind;

    return (
        <CustomVirtualisationWrapper
            index={id}
            testId="cardTransactionRow"
            isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
        >
            <TableCell data-testid="transactionIdCell">
                <TextWithCopyIcon text={id}>
                    <LinkWithCopyIcon href={transactionDetailsLink} ellipsis="start" text={id} />
                </TextWithCopyIcon>
            </TableCell>
            <TableCell data-testid="cardIdCell">
                <TextWithCopyIcon text={cardId}>
                    <LinkWithCopyIcon href={cardDetailsLink} ellipsis="start" text={cardId} />
                </TextWithCopyIcon>
            </TableCell>
            <TableCell data-testid="statusCell">
                <StatusChip
                    label={transactionStatusesMap[status] || status}
                    color={transactionStatusColorMap[status]}
                />
            </TableCell>
            <TableCell data-testid="typeCell">
                <TablesTypography content={type} />
            </TableCell>
            <AmountCell amount={amounts.total.amount} />
            <TableCell data-testid="currencyCell">
                <TablesTypography content={amounts.total.currency} />
            </TableCell>
            <TableCell data-testid="methodCell">
                <TablesTypography content={transactionMethodsMap[source.method] || source.method} />
            </TableCell>
            <TableCell data-testid="merchantNameCell">
                <TablesTypography content={merchant.name || ''} />
            </TableCell>
            <TableCell data-testid="completedTimeCell">
                <TablesTypography content={transformedCompletedTime} />
            </TableCell>
            <TableCell>
                <ActionsMenu menuItems={CardTransactionsTableActions} />
            </TableCell>
        </CustomVirtualisationWrapper>
    );
}
