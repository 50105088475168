import { Column } from '@dock/react';
import cardDetailsLang from '../../../../lang/cardDetails';

export const collapsableRowColumns: Column[] = [
    {
        key: 'authorizationId',
        name: cardDetailsLang.AUTHORIZATION_ID,
    },
    { key: 'mcc', name: cardDetailsLang.MCC },
    { key: 'mid', name: cardDetailsLang.MID },
    { key: 'lastUpdatedTime', name: cardDetailsLang.INITIATED_TIME },
];
