import TableCell from '@mui/material/TableCell';
import { transformDate } from '@dock/react-util';
import type { A2ATransfersTransaction } from '@dock/types-dock-partner';
import {
    CollapsableTableElement,
    TextWithCopyIcon,
    ActionsMenu,
    ActionMenuItemProps,
    useBoolean,
    AmountCell,
    NavLink,
    ExpandRow,
    ViewDetailsLink,
} from '@dock/react';
import { useParams } from 'react-router-dom';
import {
    CustomVirtualisationWrapper,
    TablesTypography,
    LinkWithCopyIcon,
    interpolateParams,
    A2A_DETAILS_TRANSACTIONS_DETAILS_ROUTE,
    ENV_VARS,
} from '../../../../common';
import { collapsableRowColumns } from './a2aTransactionsTableColumns';
import { A2aTransferTxCollapsableRow } from './A2aTransferTxCollapsableRow';

export type A2ATransactionsTableRowProps = Pick<
    A2ATransfersTransaction,
    | 'id'
    | 'amount'
    | 'datetimes'
    | 'externalReference'
    | 'instrumentId'
    | 'paymentId'
    | 'description'
>;

export function A2ATransactionsTableRow({
    id,
    amount,
    datetimes,
    externalReference,
    instrumentId,
    paymentId,
    description,
}: A2ATransactionsTableRowProps) {
    const [isOpen, handleRow] = useBoolean(false);

    const createdDate = transformDate(new Date(datetimes.created));

    // TODO this can be a hook.
    const { id: a2aId = '' } = useParams();
    const transactionDetailsLink = interpolateParams(A2A_DETAILS_TRANSACTIONS_DETAILS_ROUTE, {
        id: a2aId,
        transactionId: id,
    });

    const TransactionsTableActions: ActionMenuItemProps[] = [
        {
            content: <NavLink to={transactionDetailsLink} label={<ViewDetailsLink />} />,
            id: '0',
        },
    ];

    return (
        <>
            <CustomVirtualisationWrapper
                index={id}
                testId="a2aTransactionsTableRow"
                isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
            >
                <TableCell>
                    <ExpandRow isOpen={isOpen} onClick={handleRow} />
                </TableCell>
                <TableCell data-testid="a2aTransactionId">
                    <TextWithCopyIcon text={id}>
                        <LinkWithCopyIcon
                            testId="contentCopyText"
                            href={transactionDetailsLink}
                            ellipsis="start"
                            text={id}
                        />
                    </TextWithCopyIcon>
                </TableCell>
                <AmountCell amount={amount.amount} />
                <TableCell>
                    <TablesTypography content={amount.currency} />
                </TableCell>
                <TableCell data-testid="createdDate">
                    <TablesTypography content={createdDate} />
                </TableCell>
                <TableCell>
                    <TablesTypography content={externalReference || ''} />
                </TableCell>
                <TableCell>
                    <ActionsMenu menuItems={TransactionsTableActions} />
                </TableCell>
            </CustomVirtualisationWrapper>

            <CollapsableTableElement
                isOpen={isOpen}
                columns={collapsableRowColumns}
                RowComponent={
                    <A2aTransferTxCollapsableRow
                        instrumentId={instrumentId}
                        paymentId={paymentId}
                        description={description || ''}
                    />
                }
            />
        </>
    );
}
