import {
    SimpleRow,
    CollapsableElement,
    useBoolean,
    TextWithCopyIcon,
    TextWithCopyIconContent,
    AmountSimpleRow,
} from '@dock/react';
import { Grid, Paper } from '@mui/material';
import { DASH } from '@dock/common';
import { useBankTransferTransactionsDetails } from '../../../services';
import {
    transactionDetailColumns,
    transactionCounterPartyColumns,
} from './BankTransferTransactionDetailColumns';
import lang from '../../../lang/bankTransferDetails';
import {
    LinkWithCopyIcon,
    BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE,
    interpolateParams,
} from '../../../common';
import { bankTransferTransactionSepaKindKindMap } from '../bankTransferTransactionSepaKindKindMap';

const detailColumnsKeys = Object.keys(transactionDetailColumns);
const counterPartyColumnsKeys = Object.keys(transactionCounterPartyColumns);

type BankTransferTransactionDetailTableProps = {
    id: string;
    transactionID: string;
};

export function BankTransferTransactionDetailTable({
    id,
    transactionID,
}: BankTransferTransactionDetailTableProps) {
    //* hooks
    const [isDetailCollapsed, flipDetailCollapsable] = useBoolean(true);
    const [isCounterPartyCollapsed, flipCounterPartyCollapsable] = useBoolean(false);
    const [isPartyCollapsed, flipPartyCollapsable] = useBoolean(false);
    //* Data
    const { data, isFetching } = useBankTransferTransactionsDetails(id, transactionID);

    const {
        paymentSystemSpecific,
        bankTransferId,
        id: idTransfer,
        amount,
        paymentType,
        ...details
    } = data ?? {
        paymentSystemSpecific: undefined,
    };

    const instrumentLink = interpolateParams(BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE, { id });
    const sepaKindValue = paymentType ? bankTransferTransactionSepaKindKindMap[paymentType] : DASH;

    //* Collapsable
    const shouldShowTransactionsDetail = !isFetching && details;
    const shouldShowCounterParty = !isFetching && paymentSystemSpecific?.counterParty;
    const shouldShowParty = !isFetching && paymentSystemSpecific?.party;

    return (
        <>
            <Paper>
                <CollapsableElement
                    isCollapsed={isDetailCollapsed}
                    title={lang.TRANSACTIONS_TABLE.PAYMENT_DETAILS}
                    flip={flipDetailCollapsable}
                    columns={detailColumnsKeys.length}
                    isLoading={isFetching}
                >
                    <SimpleRow
                        label={transactionDetailColumns.transactionId}
                        value={
                            <TextWithCopyIcon text={transactionID}>
                                <TextWithCopyIconContent
                                    testId="transactionId"
                                    text={transactionID}
                                />
                            </TextWithCopyIcon>
                        }
                    />

                    <SimpleRow
                        label={transactionDetailColumns.bankTransferId}
                        value={
                            <TextWithCopyIcon text={id}>
                                <LinkWithCopyIcon
                                    testId="bankTransferId"
                                    href={instrumentLink}
                                    text={id}
                                />
                            </TextWithCopyIcon>
                        }
                        valueTestId="bankTransferId"
                    />

                    {amount && (
                        <AmountSimpleRow label={transactionDetailColumns.amount} amount={amount} />
                    )}

                    {shouldShowTransactionsDetail &&
                        details &&
                        Object.entries(details).map(([key, value]) => (
                            <SimpleRow
                                key={key}
                                label={
                                    transactionDetailColumns[
                                        key as keyof typeof transactionDetailColumns
                                    ]
                                }
                                value={value as string}
                            />
                        ))}
                    <SimpleRow label={transactionDetailColumns.paymentType} value={sepaKindValue} />
                </CollapsableElement>
            </Paper>
            <Grid container spacing="16px">
                <Grid item xs={12} sm={6}>
                    <Paper sx={{ my: '16px' }}>
                        <CollapsableElement
                            isCollapsed={isCounterPartyCollapsed}
                            title={lang.TRANSACTIONS_TABLE.COUNTER_PARTY}
                            flip={flipCounterPartyCollapsable}
                            columns={counterPartyColumnsKeys.length}
                            isLoading={isFetching}
                        >
                            {shouldShowCounterParty &&
                                Object.entries(paymentSystemSpecific?.counterParty).map(
                                    ([key, value]) => (
                                        <SimpleRow
                                            key={key}
                                            label={
                                                transactionCounterPartyColumns[
                                                    key as keyof typeof transactionCounterPartyColumns
                                                ]
                                            }
                                            value={value as string}
                                        />
                                    )
                                )}
                        </CollapsableElement>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper sx={{ my: '16px' }}>
                        <CollapsableElement
                            isCollapsed={isPartyCollapsed}
                            title={lang.TRANSACTIONS_TABLE.PARTY}
                            flip={flipPartyCollapsable}
                            columns={counterPartyColumnsKeys.length}
                            isLoading={isFetching}
                        >
                            {shouldShowParty &&
                                Object.entries(paymentSystemSpecific?.party).map(([key, value]) => (
                                    <SimpleRow
                                        key={key}
                                        label={
                                            transactionCounterPartyColumns[
                                                key as keyof typeof transactionCounterPartyColumns
                                            ]
                                        }
                                        value={value as string}
                                    />
                                ))}
                        </CollapsableElement>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
