import { QueryClient } from '@tanstack/react-query';
import { clientBL } from './client.BL';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 0,
            retry: (failureCount, error) => clientBL.shouldRetry(failureCount, error),
            refetchOnWindowFocus: false,
        },
    },
});
