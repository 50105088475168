export default {
    TRANSFER_ID: 'Bank transfer ID',
    PAYMENT_EVENTS: 'Payment events',
    EXTERNAL_REFERENCE: 'External reference',
    TAB: {
        TRANSACTIONS: 'Transactions',
    },
    FILTER: {
        KIND: {
            SEPA: 'Sepa',
            CROSSBORDER: 'Crossborder',
            TARGET2: 'Target 2',
            INTERNAL: 'Internal',
        },
    },
    SEARCH_FOR_TRANSACTION_ID: 'Search for Bank transfer transaction ID',
    TRANSACTIONS_TABLE: {
        TRANSACTION_ID: 'Transaction ID',
        AVAILABLE_AMOUNT: 'Amount',
        CURRENCY: 'Currency',
        CREATED_DATE: 'Created time',
        TYPE: 'Type',
        BANK_TRANSFER_ID: 'Bank transfer ID',
        PAYMENT_DETAILS: 'Transaction details',
        PAYMENT_EVENT_DETAILS: 'A2A transfer payment',
        COUNTER_PARTY: 'Counterparty',
        PARTY: 'Party',
        SENDER: 'Sender',
        RECIPIENT: 'Recipient',
    },
    EVENTS_TABLE: {
        CREATED_DATE: 'Created time',
        PAYMENT_EVENT_ID: 'Payment event ID',
        EVENT_TYPE: 'Event type',
        KIND: 'Kind',
    },
    EVENTS_KIND: {
        PENDING: 'Pending',
        COMPLETED: 'Completed',
        SCHEDULED: 'Scheduled',
        EXECUTED: 'Executed',
        DISABLED: 'Disabled',
        INSUFFICIENT_FUNDS: 'Insufficient funds',
        INTERNAL: 'Internal',
        LOCKED: 'Locked',
        ERROR: 'Error',
    },
};
