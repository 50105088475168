export const LOGIN_ROUTE = `/login`;
export const NOT_FOUND_ROUTE = `/404`;
export const TENANTS_ROUTE = '/tenants';
export const ACCOUNTS_ROUTE = '/accounts';
export const CARDS_ROUTE = '/cards';
export const PRODUCT_DEFINITIONS_ROUTE = '/product-definitions';
export const IDENTITIES_ROUTE = '/identities';
export const USERS_ROUTE = '/users';
export const AUDIT_ROUTE = '/audit';
const A2A_ROUTE = '/a2a';

export const ACCOUNT_DETAILS_TRANSACTIONS_ROUTE = `${ACCOUNTS_ROUTE}/:id/transactions`;
export const ACCOUNT_DETAILS_PAYMENT_INST_ROUTE = `${ACCOUNTS_ROUTE}/:id/payment-instruments`;

export const CARD_LIST_ROUTE = `${CARDS_ROUTE}/list`;
export const CARD_HOLDERS_ROUTE = `${CARDS_ROUTE}/holders`;
export const CARD_DETAILS_TRANSACTIONS_ROUTE = `${CARD_LIST_ROUTE}/:id/transactions`;
export const CARD_DETAILS_AUTHORIZATION_ROUTE = `${CARD_LIST_ROUTE}/:id/authorizations`;
export const CARD_TRANSACTION_DETAILS_ROUTE = `${CARD_DETAILS_TRANSACTIONS_ROUTE}/:transactionId`;
export const CARD_AUTHORIZATION_DETAILS_ROUTE = `${CARD_DETAILS_AUTHORIZATION_ROUTE}/:authorizationId`;
export const CARD_SPENDING_CONTROLS_ROUTE = `${CARDS_ROUTE}/spending-controls`;
export const CARD_SPENDING_CONTROL_DETAILS_ROUTE = `${CARD_SPENDING_CONTROLS_ROUTE}/:id`;
export const CARD_SPENDING_CONTROL_CREATE_ROUTE = `${CARDS_ROUTE}/create-spending-control`;
export const CARD_SPENDING_CONTROL_EDIT_ROUTE = `${CARDS_ROUTE}/edit-spending-control/:id`;
export const A2A_TRANSACTIONS_ROUTE = `${A2A_ROUTE}/transactions`;
export const A2A_HOLDERS_ROUTE = `${A2A_ROUTE}/holders`;

export const A2A_DETAILS_TRANSACTIONS_ROUTE = `${A2A_TRANSACTIONS_ROUTE}/:id/transactions`;
export const A2A_DETAILS_TRANSACTIONS_DETAILS_ROUTE = `${A2A_DETAILS_TRANSACTIONS_ROUTE}/:transactionId`;
export const A2A_DETAILS_SCHEDULE_PAYMENTS_ROUTE = `${A2A_TRANSACTIONS_ROUTE}/:id/schedule-payments`;
export const A2A_DETAILS_SCHEDULE_PAYMENTS_DETAILS_ROUTE = `${A2A_DETAILS_SCHEDULE_PAYMENTS_ROUTE}/:transactionId`;

const BANK_TRANSFERS_ROUTE = '/bank-transfers';
export const BANK_TRANSFERS_INSTRUMENTS_ROUTE = `${BANK_TRANSFERS_ROUTE}/instruments`;
export const BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE = `${BANK_TRANSFERS_INSTRUMENTS_ROUTE}/:id`;
export const BANK_TRANSFERS_TRANSACTION_DETAIL_ROUTE = `${BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE}/:transactionId`;
export const BANK_TRANSFERS_HOLDERS_ROUTE = `${BANK_TRANSFERS_ROUTE}/holders`;

export const CARD_OVERVIEW_ROUTE = '/card-overview';
export const CARD_AUTHORIZATIONS_OVERVIEW_ROUTE = `${CARD_OVERVIEW_ROUTE}/authorizations`;
export const CARD_TRANSACTIONS_OVERVIEW_ROUTE = `${CARD_OVERVIEW_ROUTE}/transactions`;
export const CARD_AUTHORIZATION_EVENTS_OVERVIEW_ROUTE = `${CARD_OVERVIEW_ROUTE}/authorization-events`;
