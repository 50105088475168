import { createHttpInstance, http, Request, GET, POST, PUT } from '@dock/http-request';
import type { RequestAndUnwrap } from '@dock/services';
import { dockPartnerAppAuthTokenInterceptorRequest } from '../dockPartnerAppAuthTokenInterceptorRequest';
import { interceptorResponseErrorForOldServices } from '../interceptorResponseErrorForOldServices';
import { ENV_VARS } from '../../common';

export const httpGenerator = (url: string) => {
    // we can add common interceptors for your package instance here.
    const instance = createHttpInstance(url);
    instance.interceptors.request.use(dockPartnerAppAuthTokenInterceptorRequest);
    instance.interceptors.response.use(
        (response) => response,
        (error) => interceptorResponseErrorForOldServices(error, instance)
    );

    const createdHttp = http(instance);

    return <V extends keyof Request>(verb: V) => {
        // you can ask for an specific verb: get/post/put
        const action = createdHttp[verb];

        return <R>(service: RequestAndUnwrap<V, R>) => service(action);
    };
};

// API Instance
const apiInstance = httpGenerator(ENV_VARS.API_URL);
export const apiGet = apiInstance(GET);
export const apiPost = apiInstance(POST);
export const apiPut = apiInstance(PUT);
