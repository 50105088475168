import { NavLink } from 'react-router-dom';
import { tabSwitcherItemStyles, getTabSwitcherTypographyStyles } from '@dock/react';
import { Divider, Box, Typography } from '@mui/material';

export type TabConfig = {
    label: string;
    link: string;
    isHidden?: boolean;
};

type TabSwitcherProps = {
    tabs: TabConfig[];
    testId?: string;
};

export function NavLinkTabSwitcher({ tabs, testId = 'tabSwitcher' }: TabSwitcherProps) {
    return (
        <>
            <Box
                data-testid={testId}
                sx={{
                    display: 'flex',
                }}
            >
                {tabs
                    .filter(({ isHidden }) => !isHidden)
                    .map(({ label, link }) => (
                        <Box key={label} sx={tabSwitcherItemStyles}>
                            <NavLink
                                to={link}
                                style={{ textDecoration: 'none' }}
                                data-testid={`link${label}`}
                            >
                                {({ isActive }) => (
                                    <Typography
                                        variant="h4"
                                        sx={(theme) =>
                                            getTabSwitcherTypographyStyles(theme, isActive)
                                        }
                                    >
                                        {label}
                                    </Typography>
                                )}
                            </NavLink>
                        </Box>
                    ))}
            </Box>

            <Divider sx={{ color: 'grey.600' }} />
        </>
    );
}
