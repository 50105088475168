export default {
    CARD_TXS_OVERVIEW_TITLE: 'Card transactions overview',
    TABS: {
        TRANSACTIONS: 'Transactions',
        AUTHORIZATIONS: 'Authorizations',
        AUTHORIZATIONS_EVENTS: 'Authorizations events',
    },
    CARD_TRANSACTIONS_TABLE: {
        TRANSACTION_ID: 'Transaction ID',
        CARD_ID: 'Card ID',
        AMOUNT: 'Amount',
        CURRENCY: 'Currency',
        INITIATED_TIME: 'Initiated time',
        COMPLETED_TIME: 'Completed time',
        AUTHORIZATION_ID: 'Authorization ID',
        PAYMENT_SOURCE: 'Payment source',
        MCC: 'MCC',
        MID: 'MID',
        TYPE: 'Type',
        STATUS: 'Status',
        MERCHANT_NAME: 'Merchant name',
    },
    CARD_AUTHORIZATIONS_TABLE: {
        AUTHORIZATION_ID: 'Authorization ID',
        CARD_ID: 'Card ID',
        STATUS: 'Status',
        AMOUNT: 'Amount',
        CURRENCY: 'Currency',
        TIME_INITIATED: 'Time initiated',
        TIME_OF_LAST_UPDATE: 'Time of last update',
        PAYMENT_SOURCE: 'Payment source',
        MERCHANT_NAME: 'Merchant name',
    },
    CARD_AUTHORIZATIONS_EVENTS_TABLE: {
        AUTHORIZATION_ID: 'Authorization ID',
        CARD_ID: 'Card ID',
        STATUS: 'Status',
        TYPE: 'Type',
        OUTCOME_REASON: 'Outcome reason',
        AMOUNT: 'Amount',
        CURRENCY: 'Currency',
        TIME_INITIATED: 'Time initiated',
    },
    SEARCH_OPTION: {
        CARD_ID: 'Card ID',
        TRANSACTION_ID: 'Transaction ID',
        AUTHORIZATION_ID: 'Authorization ID',
    },
};
