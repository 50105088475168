import React from 'react';
import { useParams } from 'react-router-dom';
import { Currency } from '@dock/types-common';
import { Accordion, PageContainer, WhiteBackgroundWrapper } from '@dock/react';
import { Monetary } from '@dock/types-dock-partner';
import { Box, Grid } from '@mui/material';
import { getAccountsDetailsTabConfig } from './getTabConfig';
import { useAccountsList } from '../../services';
import { AccountDetails } from './components/AccountDetails';
import { MoneyBalance } from './components/MoneyBalance';
import { Analytics } from './components/Analytics';
import { BreadcrumbsWrapper, NavLinkTabSwitcher } from '../../common';
import lang from '../../lang/accountDetails';

type AccountsDetailsProps = {
    children: React.ReactNode;
};

const monetary: Monetary = { amount: '', currency: Currency.EUR };

const emptyMoneyBalance = {
    available: monetary,
    hold: monetary,
    total: monetary,
};

export function AccountsDetailsPage({ children }: AccountsDetailsProps) {
    const { id: accountId = '' } = useParams();
    const tabs = getAccountsDetailsTabConfig(accountId);

    const { data, isLoading, isError } = useAccountsList({
        params: { id: [accountId] },
    });
    const accountData = data[0];

    return (
        <PageContainer testId="accountsDetailsPage">
            <BreadcrumbsWrapper />
            <Grid
                container
                spacing={4}
                sx={{
                    flexGrow: 1,
                    marginBottom: 3,
                }}
            >
                <Grid item xs={6}>
                    <MoneyBalance
                        accountId={accountId}
                        balances={accountData?.balances || emptyMoneyBalance}
                        isLoading={isLoading}
                        isError={isError}
                    />
                </Grid>
                <Grid item xs={6} data-testid="analyticsBox">
                    <Analytics
                        accountId={accountId}
                        balances={accountData?.balances || emptyMoneyBalance}
                        isLoading={isLoading}
                        isError={isError}
                    />
                </Grid>
            </Grid>
            {accountData && (
                <Box sx={{ mb: '40px' }} data-testid="accountDetailsBox">
                    <Accordion
                        title={lang.DETAILS_STATUS}
                        childStyles={{
                            backgroundColor: ({ palette }) => palette.background.paper,
                        }}
                    >
                        <AccountDetails data={accountData} isLoading={isLoading} />
                    </Accordion>
                </Box>
            )}
            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher tabs={tabs} />
                {children}
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
