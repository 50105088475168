import { WhiteBackgroundWrapper, PageContainer, CommonLinkAsButton } from '@dock/react';
import { Box } from '@mui/material';
import { tabConfig } from './getTabConfig';
import {
    NavLinkTabSwitcher,
    PageActionButtonWrapper,
    BreadcrumbsWrapper,
    CARD_SPENDING_CONTROL_CREATE_ROUTE,
} from '../../../common';
import { SpendingControlListTable } from './components/SpendingControlList';
import commonLang from '../../../lang/common';

export function CardSpendingControl() {
    return (
        <PageContainer testId="sCPage">
            <BreadcrumbsWrapper>
                <PageActionButtonWrapper>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <CommonLinkAsButton
                            label={commonLang.CREATE_NEW}
                            testId="createSC"
                            to={CARD_SPENDING_CONTROL_CREATE_ROUTE}
                        />
                    </Box>
                </PageActionButtonWrapper>
            </BreadcrumbsWrapper>

            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher tabs={tabConfig()} />
                <SpendingControlListTable />
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
