import {
    AmountSimpleRow,
    CollapsableTitle,
    DetailsTitle,
    HeadlessTable,
    SimpleRow,
    TableSkeleton,
    TextWithCopyIcon,
    useBoolean,
} from '@dock/react';
import { Paper, Collapse, Typography } from '@mui/material';
import { DASH } from '@dock/common';
import { transformDate } from '@dock/react-util';
import {
    CardsVelocityControlTargetKind,
    CardsVelocityControlWindowKind,
} from '@dock/types-dock-partner';
import { useVelocityControl } from '../../../../services';
import lang from '../../../../lang/spendingControl';
import {
    LinkWithCopyIcon,
    CARD_DETAILS_TRANSACTIONS_ROUTE,
    interpolateParams,
    cardsVCWindowKindMap,
    cardsVCTargetKindMap,
    cardsVCScopeMap,
} from '../../../../common';

const basicDetailColumnsKeys = Object.keys(lang.BASIC_DETAIL_COLUMNS);
const configurationColumnsKeys = Object.keys(lang.CONFIGURATION_COLUMNS);

type CardTransactionDetailTableProps = {
    id: string;
};

export function CardSpendingControlDetailTable({ id }: CardTransactionDetailTableProps) {
    //* hooks
    const [basicDetailCollapsed, flipBasicDetailCollapsable] = useBoolean(true);
    const [configurationCollapsed, flipConfigurationCollapsable] = useBoolean(true);
    const {
        data: [velocityControl],
        isFetching,
    } = useVelocityControl({ params: { id } });
    const cardLink = interpolateParams(CARD_DETAILS_TRANSACTIONS_ROUTE, {
        id: velocityControl?.target.id,
    });

    const from = velocityControl?.datetimes
        ? transformDate(new Date(velocityControl?.datetimes.effectiveFrom))
        : DASH;
    const until = velocityControl?.datetimes.effectiveUntil
        ? transformDate(new Date(velocityControl?.datetimes.effectiveUntil))
        : DASH;
    const lastUpdate = velocityControl?.datetimes.lastUpdated
        ? transformDate(new Date(velocityControl?.datetimes.lastUpdated))
        : DASH;

    const shouldShowWindowRange =
        velocityControl?.window.kind === CardsVelocityControlWindowKind.DAYS ||
        velocityControl?.window.kind === CardsVelocityControlWindowKind.MONTHS;
    const shouldShowDaysLabel =
        velocityControl?.window.kind === CardsVelocityControlWindowKind.DAYS;

    const windowLabel = shouldShowDaysLabel
        ? lang.CONFIGURATION_COLUMNS.amountOfDays
        : lang.CONFIGURATION_COLUMNS.amountOfMonths;
    const windowRange = shouldShowDaysLabel
        ? velocityControl?.window.days?.toString() || DASH
        : velocityControl?.window.months?.toString() || DASH;

    const isCard = velocityControl?.target.kind === CardsVelocityControlTargetKind.CARD;

    return (
        <>
            <Paper sx={{ mt: '16px', mb: '40px' }}>
                <CollapsableTitle
                    isOpen={basicDetailCollapsed}
                    titleElement={<DetailsTitle title={lang.TABS.BASIC_DETAILS} />}
                    handleCollapsableElement={flipBasicDetailCollapsable}
                />
                <Collapse in={basicDetailCollapsed} timeout="auto" unmountOnExit>
                    <HeadlessTable>
                        <TableSkeleton
                            rows={basicDetailColumnsKeys.length}
                            columns={2}
                            shouldShow={isFetching}
                        />
                        {isFetching ? null : (
                            <>
                                <SimpleRow
                                    label={lang.ID}
                                    value={
                                        <TextWithCopyIcon text={velocityControl?.id ?? DASH}>
                                            <Typography>{velocityControl?.id ?? DASH}</Typography>
                                        </TextWithCopyIcon>
                                    }
                                />
                                <SimpleRow
                                    label={lang.BASIC_DETAIL_COLUMNS.label}
                                    value={velocityControl?.label ?? DASH}
                                />
                                <SimpleRow
                                    label={lang.BASIC_DETAIL_COLUMNS.category}
                                    value={
                                        velocityControl?.target
                                            ? cardsVCTargetKindMap[velocityControl.target.kind]
                                            : DASH
                                    }
                                />
                                <SimpleRow
                                    label={
                                        isCard
                                            ? lang.BASIC_DETAIL_COLUMNS.cardId
                                            : lang.BASIC_DETAIL_COLUMNS.cardProductId
                                    }
                                    value={
                                        <TextWithCopyIcon text={velocityControl?.target.id ?? DASH}>
                                            {isCard ? (
                                                <LinkWithCopyIcon
                                                    href={cardLink}
                                                    text={velocityControl?.target.id ?? DASH}
                                                />
                                            ) : (
                                                <Typography>
                                                    {velocityControl?.target.id ?? DASH}
                                                </Typography>
                                            )}
                                        </TextWithCopyIcon>
                                    }
                                />
                                <SimpleRow
                                    label={lang.BASIC_DETAIL_COLUMNS.scope}
                                    value={
                                        velocityControl?.scope
                                            ? cardsVCScopeMap[velocityControl.scope]
                                            : '-'
                                    }
                                />
                                <SimpleRow
                                    label={lang.BASIC_DETAIL_COLUMNS.tenantId}
                                    value={
                                        <TextWithCopyIcon text={velocityControl?.tenantId ?? DASH}>
                                            <Typography>
                                                {velocityControl?.tenantId ?? DASH}
                                            </Typography>
                                        </TextWithCopyIcon>
                                    }
                                />
                            </>
                        )}
                    </HeadlessTable>
                </Collapse>
            </Paper>
            <Paper sx={{ mt: '16px' }}>
                <CollapsableTitle
                    isOpen={configurationCollapsed}
                    titleElement={<DetailsTitle title={lang.TABS.SPENDING_CONFIGURATION} />}
                    handleCollapsableElement={flipConfigurationCollapsable}
                />
                <Collapse in={configurationCollapsed} timeout="auto" unmountOnExit>
                    <HeadlessTable>
                        <TableSkeleton
                            rows={configurationColumnsKeys.length}
                            columns={2}
                            shouldShow={isFetching}
                        />
                        {isFetching ? null : (
                            <>
                                <AmountSimpleRow
                                    label={lang.CONFIGURATION_COLUMNS.amount}
                                    amount={velocityControl?.velocity.spendingLimit.amount}
                                    currency={velocityControl?.velocity.spendingLimit.currency}
                                />
                                {velocityControl?.window.kind !== cardsVCWindowKindMap.DAYS && (
                                    <SimpleRow
                                        label={lang.CONFIGURATION_COLUMNS.window}
                                        value={
                                            velocityControl?.window
                                                ? cardsVCWindowKindMap[velocityControl.window.kind]
                                                : DASH
                                        }
                                    />
                                )}
                                {shouldShowWindowRange && (
                                    <SimpleRow label={windowLabel} value={windowRange} />
                                )}
                                <SimpleRow
                                    label={lang.CONFIGURATION_COLUMNS.effectiveFrom}
                                    value={from}
                                />
                                <SimpleRow
                                    label={lang.CONFIGURATION_COLUMNS.effectiveUntil}
                                    value={until}
                                />
                                <SimpleRow
                                    label={lang.CONFIGURATION_COLUMNS.lastUpdated}
                                    value={lastUpdate}
                                />
                            </>
                        )}
                    </HeadlessTable>
                </Collapse>
            </Paper>
        </>
    );
}
