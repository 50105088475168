import {
    listInstruments as listInstrumentsService,
    listInstrumentsHolders as listInstrumentsHolderService,
    listTransfers as listTransfersService,
    listPaymentsEvents as listPaymentsEventsService,
    createInstrument as createInstrumentService,
    getA2ATransactions as getA2ATransactionsService,
    createA2APayment as createA2APaymentService,
    setA2AStatus as setA2AStatusService,
} from '@dock/services';
import { apiGet, apiPost } from './instance';

export const getA2ATransfers = apiGet(listInstrumentsService);

export const getA2ATransfersHolders = apiGet(listInstrumentsHolderService);

export const createInstrumentHandler = apiPost(createInstrumentService);

export const getA2ATransactions = apiGet(getA2ATransactionsService);

export const createA2APaymentHandler = (senderId: string) =>
    apiPost(createA2APaymentService(senderId));

export const getA2APayments = apiGet(listTransfersService);
export const getA2APaymentsEvents = apiGet(listPaymentsEventsService);

export const setA2AStatus = (id: string) => apiPost(setA2AStatusService(id));
