import { Card, Skeleton } from '@dock/react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ExternalReferencesAccordion } from './ExternalReferencesAccordion';
import { CardKindAccordion } from './CardKindAccordion';
import { PersonalisationAccordion } from './PersonalisationAccordion';
import { useCardDetails } from '../../../services';

const infoWrapperStyles = {
    display: 'flex',
    gap: '36px',
    width: '100%',
    mb: 6,
};

export function CardsMainInfo() {
    const { id: cardId = '' } = useParams();

    const { cardKind, externalReferences, generalCardData, isLoading, data } =
        useCardDetails(cardId);

    return (
        <Box sx={infoWrapperStyles}>
            {isLoading ? <Skeleton height={170} width="70%" /> : <Card {...generalCardData} />}

            {isLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        gap: '1px',
                    }}
                >
                    <Skeleton height={55} width="100%" />
                    <Skeleton height={55} width="100%" />
                    <Skeleton height={55} width="100%" />
                </Box>
            ) : (
                <Box sx={{ width: '100%' }}>
                    <ExternalReferencesAccordion {...externalReferences} />
                    {cardKind.cardStatus && cardKind.fulfillmentStatus && (
                        <CardKindAccordion
                            kind={cardKind.kind}
                            cardStatus={cardKind.cardStatus}
                            fulfillmentStatus={cardKind.fulfillmentStatus}
                        />
                    )}
                    {data?.personalization && (
                        <PersonalisationAccordion personalization={data.personalization} />
                    )}
                </Box>
            )}
        </Box>
    );
}
