export default {
    TITLE: 'Login',
    DESCRIPTION: ' Log in to Dock Partner Console',
    LABEL: ' Login token',
    BUTTON: 'Log in',
    LOGOUT: 'Log out',
    VALIDATION_ERROR: 'Error. Invalid login token.',
    ERROR_MESSAGE: 'Something went wrong. Please try again later.',
    SESSION_EXPIRED: {
        MAIN_TITLE: 'Your session is about to expire',
        SECONDARY_TITLE: 'Do you want to continue the session?',
        CONTINUE: 'Continue session',
    },
};
