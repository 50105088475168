import { Box, ListItemIcon, ListItemText, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import loginLang from '../../../lang/login';

export function LogOutItem({ logout }: { logout: () => void }) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0.5em 0',
                width: '100%',
            }}
            data-testid="logOutItem"
            onClick={logout}
        >
            <ListItemIcon sx={{ color: ({ palette }) => palette.grey[800] }}>
                <LogoutIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: ({ palette }) => palette.grey[800] }}>
                <Typography variant="body1">{loginLang.LOGOUT}</Typography>
            </ListItemText>
        </Box>
    );
}
