import { useMutation } from '@tanstack/react-query';
import type { CreateCryptoChallengeData, AuthCreateChallenge } from '@dock/types-dock-partner';
import { PartnerServiceError } from '../../../common/PartnerServiceError';

import { createCryptokeyChallenge } from '../../api';

export function useCryptokeyChallengeMutation() {
    return useMutation<CreateCryptoChallengeData, PartnerServiceError, AuthCreateChallenge>(
        (payload) => createCryptokeyChallenge(payload)
    );
}
