import { Box } from '@mui/material';
import {
    SimpleTable,
    MultipleChoiceSearch,
    FilterButton,
    DateRangeDropDown,
    OptionType,
} from '@dock/react';
import { prefillDatesFromAlways } from '@dock/common';
import { useModal } from '@dock/react-hooks';
import { useCardTransactions } from '../../../../services';
import { TransactionsOverviewRow } from './TransactionsOverviewRow';
import { transactionsOverviewColumns } from './transactionsOverviewColumns';
import {
    CardTransactionsFilters,
    CardTransactionsSearchFields,
    transformCardOverviewParams,
    useCardTransactionsFilters,
    TableFiltersWrapper,
    filterWrapperStyles,
} from '../../../../common';
import cardTxsOverviewLang from '../../../../lang/cardTransactionsOverview';
import commonLang from '../../../../lang/common';

const searchOptions: OptionType<CardTransactionsSearchFields>[] = [
    {
        label: cardTxsOverviewLang.SEARCH_OPTION.TRANSACTION_ID,
        value: CardTransactionsSearchFields.transactionId,
    },
    {
        label: cardTxsOverviewLang.SEARCH_OPTION.CARD_ID,
        value: CardTransactionsSearchFields.cardId,
    },
];

export function TransactionsOverviewTable() {
    const { isOpen, setClose, setOpen } = useModal();

    const {
        filters,
        handleDateFilter,
        handlePopUpFilter,
        handleSearch,
        isPopFilterActive,
        handleSearchSelect,
        sorting,
        handleSortOrder,
    } = useCardTransactionsFilters(CardTransactionsSearchFields.transactionId);

    const { data, error, fetchNextPage, isFetching, isFetchingNextPage } = useCardTransactions({
        params: transformCardOverviewParams(filters),
    });

    const [dateFrom, dateTo] = prefillDatesFromAlways(filters.from, filters.to);

    return (
        <>
            <TableFiltersWrapper>
                <MultipleChoiceSearch
                    label={commonLang.SEARCH}
                    initValue={filters.text || ''}
                    handleSearch={handleSearch}
                    onChange={handleSearchSelect}
                    searchBy={filters.searchBy || CardTransactionsSearchFields.transactionId}
                    options={searchOptions}
                />

                <Box sx={{ ...filterWrapperStyles }}>
                    <DateRangeDropDown
                        handleFilters={handleDateFilter}
                        from={dateFrom}
                        to={dateTo}
                    />
                    <FilterButton isFilterOn={Boolean(isPopFilterActive)} onClick={setOpen} />
                </Box>
            </TableFiltersWrapper>
            <CardTransactionsFilters
                isOpen={isOpen}
                handleClose={setClose}
                onApply={handlePopUpFilter(setOpen)}
                fields={{
                    status: filters.status || [],
                    sourceMethod: filters.sourceMethod || [],
                    minAmount: filters.minAmount || '',
                    maxAmount: filters.maxAmount || '',
                }}
            />
            <SimpleTable
                columns={transactionsOverviewColumns}
                RowComponent={TransactionsOverviewRow}
                isLoading={isFetching}
                rows={data}
                error={error}
                isLoadingNextPage={isFetchingNextPage}
                onReachingBottom={fetchNextPage}
                sorting={sorting}
                onSortClick={handleSortOrder}
            />
        </>
    );
}
