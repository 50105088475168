import { flow } from 'fp-ts/lib/function';
import type { A2ATransfersPayment } from '@dock/types-dock-partner';
import { handleErrors } from '../../../../errorService';
import { getData, requestFlow, RequestAndUnwrap, unwrapService } from '../../../../utilities';

export const a2aPaymentsHandler = (senderId: string) =>
    requestFlow(handleErrors)(`/v0/a2a_transfers/${senderId}/payments`);

const fetchAndHandle = (id: string) => a2aPaymentsHandler(id)<A2ATransfersPayment>(getData);

export const createA2APayment = (senderId: string): RequestAndUnwrap<'post', A2ATransfersPayment> =>
    flow(fetchAndHandle(senderId), unwrapService<A2ATransfersPayment>);
