import TableCell from '@mui/material/TableCell';
import {
    ActionMenuItemProps,
    ActionsMenu,
    AmountCell,
    TextWithCopyIcon,
    ViewDetailsLink,
    NavLink,
} from '@dock/react';
import { transformDate } from '@dock/react-util';
import { CardsAuthorizationEvent } from '@dock/types-dock-partner';
import {
    interpolateParams,
    ENV_VARS,
    authorizationEventStatusColorMap,
    authorizationEventStatusMap,
    CARD_AUTHORIZATION_DETAILS_ROUTE,
    CARD_DETAILS_TRANSACTIONS_ROUTE,
    authorizationEventKindMap,
    authorizationEventOutcomeReasonMap,
    LinkWithCopyIcon,
    TablesTypography,
    CustomVirtualisationWrapper,
    StatusChip,
    ChipColors,
} from '../../../../common';

export type CardAuthorizationsRowProps = Pick<
    CardsAuthorizationEvent,
    | 'id'
    | 'authorizationId'
    | 'cardId'
    | 'kind'
    | 'outcomeReason'
    | 'amounts'
    | 'datetimes'
    | 'status'
>;

export function AuthorizationsEventsOverviewRow({
    id,
    authorizationId,
    cardId,
    kind,
    amounts,
    outcomeReason,
    datetimes,
    status,
}: CardAuthorizationsRowProps) {
    const initiatedTime = transformDate(new Date(datetimes.initiated));

    const cardLink = interpolateParams(CARD_DETAILS_TRANSACTIONS_ROUTE, {
        id: cardId,
    });

    const cardAuthorizationLink = interpolateParams(CARD_AUTHORIZATION_DETAILS_ROUTE, {
        id: cardId,
        authorizationId,
    });

    const AuthorizationTableActions: ActionMenuItemProps[] = [
        {
            content: <NavLink to={cardAuthorizationLink} label={<ViewDetailsLink />} />,
            id: '0',
        },
    ];

    const type = authorizationEventKindMap[kind] || kind;

    const reasonValue = outcomeReason
        ? authorizationEventOutcomeReasonMap[outcomeReason] || outcomeReason
        : '';

    const statusValue = authorizationEventStatusMap[status] || status;

    const statusColor = authorizationEventStatusColorMap[status] || ChipColors.default;

    return (
        <CustomVirtualisationWrapper
            index={id}
            testId="authorizationEventRow"
            isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
        >
            <TableCell>
                <TextWithCopyIcon text={authorizationId}>
                    <LinkWithCopyIcon
                        href={cardAuthorizationLink}
                        ellipsis="start"
                        text={authorizationId}
                        testId="authorizationId"
                    />
                </TextWithCopyIcon>
            </TableCell>
            <TableCell>
                <TextWithCopyIcon text={cardId}>
                    <LinkWithCopyIcon
                        href={cardLink}
                        ellipsis="start"
                        text={cardId}
                        testId="cardId"
                    />
                </TextWithCopyIcon>
            </TableCell>
            <TableCell data-testid="status">
                <StatusChip label={statusValue} color={statusColor} />
            </TableCell>
            <TableCell data-testid="kindCell">
                <TablesTypography content={type} />
            </TableCell>
            <TableCell data-testid="outcomeReason">
                <TablesTypography content={reasonValue} />
            </TableCell>
            <AmountCell amount={amounts.requested.amount} />
            <TableCell data-testid="currency">
                <TablesTypography content={amounts.requested.currency} />
            </TableCell>
            <TableCell data-testid="initiatedTime">
                <TablesTypography content={initiatedTime} />
            </TableCell>
            <TableCell>
                <ActionsMenu menuItems={AuthorizationTableActions} />
            </TableCell>
        </CustomVirtualisationWrapper>
    );
}
