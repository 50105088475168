export default {
    BANK_TRANSFERS: 'Bank transfers',
    BANK_TRANSFER_HOLDERS: 'Bank transfer holders',
    BANK_TRANSFER_ID: 'Bank transfer ID',
    BIC: 'BIC',
    IBAN: 'IBAN',
    CREATED_TIME: 'Created time',
    ACCOUNT_ID: 'Account ID',
    SEARCH_FOR_BANK_TRANSFER_ID: 'Search for bank transfer holder ID',
    BANK_TRANSFER_HOLDER: 'Bank transfer holder',
    BANK_TRANSFER_HOLDER_ID: 'Bank transfer holder ID',
    BANK_TRANSFER_HOLDER_TENANT: 'Tenant ID',
    BANK_TRANSFER_HOLDER_LABEL: 'Bank transfer holder label',
    DEFINITION_ID: 'Product definition ID',
    HOLDER_ID: 'Bank transfer holder ID',
    CREATE_NEW: {
        TITLE: 'Create new bank transfer instrument',
        ACCOUNT_ID_TOOLTIP: 'Account to which payment instrument is associated.',
        DEFINITION_ID_TOOLTIP: 'Product ID defines the behaviour of the Bank transfer instrument.',
        HOLDER_ID_TOOLTIP: 'The holder ID associated with the bank transfer instrument.',
        FAILED: 'Bank transfer creation failed. Please try again.',
        SUCCESS_PRE: 'New Bank transfer ',
        SUCCESS_POST: ' was created successfully.',
        SUBMIT: 'Create bank transfer',
    },
    IDENTITY_ID: 'Identity ID',
    CREATE_HOLDER: {
        TITLE: 'Create new Bank transfer holder',
        IDENTITY_ID_DESCRIPTION:
            'To create a holder you need to have an Identity ID already created at Dock.',
        GET_FAILED: (name: string) =>
            `Bank transfer holder «${name}» creation failed. Please try again.`,
        SUCCESS_PRE: (name: string) => `New Bank transfer holder «${name}» (ID: `,
        SUCCESS_POST: ') was created successfully.',
        SUBMIT: 'Create',
    },
};
