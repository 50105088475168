import { FilterModalButtons, FilterModalWrapper } from '@dock/react';
import { FormControl } from '@mui/material';
import { AccountsPaymentMethod } from '@dock/types-dock-partner';
import {
    CheckItems,
    useCheckItems,
    useAmounts,
    SimpleDialogProps,
    AmountInputRange,
} from '../../../../common';
import { sourceMethodKeys } from '../sourceMethodKeys';
import { AccTxsPopUpFilterParams } from './useAccountTransactionsFilters';
import accountDetailsLang from '../../../../lang/accountDetails';
import commonLang from '../../../../lang/common';

export function AccountTransactionFilters({
    isOpen,
    handleClose,
    fields,
    onApply,
}: SimpleDialogProps<AccTxsPopUpFilterParams>) {
    const [{ minAmount, maxAmount }, setAmount, handleAmountChange] = useAmounts({
        minAmount: fields.minAmount,
        maxAmount: fields.maxAmount,
    });
    const [sourceMethod, setMethod, onMethodChange] = useCheckItems<AccountsPaymentMethod>(
        fields.sourceMethod || []
    );

    const handleApply = () => {
        onApply({
            ...fields,
            sourceMethod,
            minAmount: minAmount || '',
            maxAmount: maxAmount || '',
        });
        handleClose();
    };

    const handleReset = () => {
        setAmount({ minAmount: '', maxAmount: '' });
        setMethod([]);
        onApply({
            sourceMethod: [],
            minAmount: '',
            maxAmount: '',
        });
        handleClose();
    };

    return (
        <FilterModalWrapper
            handleClose={handleClose}
            isOpen={isOpen}
            title={commonLang.FILTER}
            testId="accountTransactionFilters"
        >
            <FormControl component="fieldset" variant="standard">
                <CheckItems
                    title={accountDetailsLang.METHOD}
                    items={sourceMethod}
                    keys={sourceMethodKeys}
                    handleChange={onMethodChange}
                />
            </FormControl>
            <AmountInputRange
                label={commonLang.AMOUNT}
                maxAmount={maxAmount}
                minAmount={minAmount}
                handleChange={handleAmountChange}
                helperText={commonLang.FILTERS.AMOUNT_TEXT}
            />
            <FilterModalButtons
                applyText={commonLang.APPLY}
                resetText={commonLang.RESET_FILTERS}
                onApplyClick={handleApply}
                onResetClick={handleReset}
            />
        </FilterModalWrapper>
    );
}
