import { useAnchor, useBoolean } from '@dock/react-hooks';
import { useLocation } from 'react-router-dom';
import { Collapse, List } from '@mui/material';
import { NavbarRoutes } from './types';
import { NavLinkItem } from './NavLinkItem';
import { SidebarButtonItem } from './SidebarButtonItem';
import { NestedItemsMenu } from './Menu/NestedItemsMenu';

type SidebarItemsProps = {
    isNavbarOpen: boolean;
    items: NavbarRoutes[];
};

export function SidebarItems({ isNavbarOpen, items }: SidebarItemsProps) {
    return (
        <>
            {items.map((item) => {
                if (item.isVisible && !item.isVisible()) {
                    return null;
                }

                const hasChildren = !!item.children?.length;

                if (hasChildren) {
                    const parentRoute = item?.route;
                    const location = useLocation();
                    const isIncludeParentRoute = parentRoute
                        ? location.pathname.includes(parentRoute)
                        : false;

                    return (
                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        <CollapsableItem
                            key={item.name}
                            item={item}
                            isNavbarOpen={isNavbarOpen}
                            isItemOpen={isIncludeParentRoute}
                        />
                    );
                }

                return <NavLinkItem key={item.name} item={item} isNavbarOpen={isNavbarOpen} />;
            })}
        </>
    );
}

type NestedItemsProps = {
    isNavbarOpen: boolean;
    item: NavbarRoutes;
    isOpen: boolean;
};

function NestedItems({ isNavbarOpen, item, isOpen }: NestedItemsProps) {
    if (!isNavbarOpen) {
        return null;
    }

    return (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <SidebarItems isNavbarOpen={isNavbarOpen} items={item.children || []} />
            </List>
        </Collapse>
    );
}

type CollapsableItemProps = {
    isNavbarOpen: boolean;
    item: NavbarRoutes;
    isItemOpen: boolean;
};

function CollapsableItem({ isNavbarOpen, item, isItemOpen }: CollapsableItemProps) {
    const [isOpen, toggle] = useBoolean(isItemOpen);
    const { pathname } = useLocation();
    const shouldHighlightParentItem = !isNavbarOpen && pathname.includes(item.route);

    const { anchorEl, isOpen: isMenuOpen, handleClick, handleClose } = useAnchor();

    return (
        <>
            <SidebarButtonItem
                item={item}
                isActive={shouldHighlightParentItem}
                isNavbarOpen={isNavbarOpen}
                isOpen={isOpen}
                toggle={toggle}
                onClick={handleClick}
            />
            <NestedItems item={item} isNavbarOpen={isNavbarOpen} isOpen={isOpen} />
            <NestedItemsMenu
                item={item}
                isNavbarOpen={isNavbarOpen}
                isMenuOpen={isMenuOpen}
                anchorEl={anchorEl}
                handleClose={handleClose}
            />
        </>
    );
}
