import {
    A2ATransfersTransaction,
    ListA2ATransactionsData,
    ListAccountsData,
} from '@dock/types-dock-partner';

export type ExtendedA2ATransaction = A2ATransfersTransaction & {
    label: string;
};

export const mergeA2ATransactionsAndAccountName = (
    a2aList: ListA2ATransactionsData,
    accounts: ListAccountsData
): ExtendedA2ATransaction[] =>
    a2aList.reduce((acc: ExtendedA2ATransaction[], transaction) => {
        const account = accounts.find((a) => a.id === transaction.counterparty.accountId);

        if (account) {
            acc.push({
                ...transaction,
                label: account.label,
            });
        }

        return acc;
    }, []);
