import {
    CARD_AUTHORIZATION_EVENT_KEY,
    CARD_AUTHORIZATION_KEY,
    CARD_HOLDERS_LIST_KEY,
    CARD_TRANSACTIONS_KEY,
    CARDS_LIST_KEY,
    VELOCITY_CONTROL_KEY,
} from '../../keys';
import {
    fetchCardsAuthorizationsEventsCommon,
    fetchCardsAuthorizations,
    fetchCardsList,
    fetchCardTransactions,
    fetchVelocityControlList,
    fetchCardHoldersList,
} from '../methods';
import { useInfiniteQueryWith } from './useInfiniteQueryWith';

export const useCardAuthorizationEvents = useInfiniteQueryWith({
    fetcher: fetchCardsAuthorizationsEventsCommon,
    queryKey: CARD_AUTHORIZATION_EVENT_KEY,
});

export const useVelocityControl = useInfiniteQueryWith({
    fetcher: fetchVelocityControlList,
    queryKey: VELOCITY_CONTROL_KEY,
});

export const useCardAuthorizations = useInfiniteQueryWith({
    fetcher: fetchCardsAuthorizations,
    queryKey: CARD_AUTHORIZATION_KEY,
});

export const useCardsList = useInfiniteQueryWith({
    fetcher: fetchCardsList,
    queryKey: CARDS_LIST_KEY,
});

export const useCardTransactions = useInfiniteQueryWith({
    fetcher: fetchCardTransactions,
    queryKey: CARD_TRANSACTIONS_KEY,
});

export const useCardHoldersList = useInfiniteQueryWith({
    fetcher: fetchCardHoldersList,
    queryKey: CARD_HOLDERS_LIST_KEY,
});
