import { TableRow, TableCell, Typography } from '@mui/material';
import { TextWithCopyIcon, TextWithCopyIconContent } from '@dock/react';

export type A2aTransferTxCollapsableRowProps = {
    instrumentId: string;
    paymentId: string | undefined | null;
    description: string;
};

export function A2aTransferTxCollapsableRow({
    instrumentId,
    paymentId,
    description,
}: A2aTransferTxCollapsableRowProps) {
    return (
        <TableRow data-testid="collapsedRow">
            <TableCell sx={{ width: '30%' }}>
                <TextWithCopyIcon text={instrumentId}>
                    <TextWithCopyIconContent text={instrumentId} />
                </TextWithCopyIcon>
            </TableCell>
            <TableCell sx={{ width: '30%' }}>
                {paymentId ? (
                    <TextWithCopyIcon text={paymentId}>
                        <TextWithCopyIconContent text={paymentId} />
                    </TextWithCopyIcon>
                ) : (
                    <Typography>-</Typography>
                )}
            </TableCell>
            <TableCell>
                <Typography data-testid="descriptionTypography" variant="subtitle1">
                    {description}
                </Typography>
            </TableCell>
        </TableRow>
    );
}
