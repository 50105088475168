import { useParams } from 'react-router-dom';
import { PageContainer } from '@dock/react';
import { CardAuthorizationEventsDetail } from './components/CardAuthorizationEventDetail';
import { CardAuthorizationDetailTable } from './components/CardAuthorizationDetailTable';
import { BreadcrumbsWrapper } from '../../common';

export function CardAuthorizationDetails() {
    const { id: cardId = '', authorizationId = '' } = useParams();

    return (
        <PageContainer testId="cardAuthorizationDetailsPage">
            <BreadcrumbsWrapper />
            <CardAuthorizationDetailTable cardId={cardId} authorizationID={authorizationId} />
            <CardAuthorizationEventsDetail cardId={cardId} authorizationID={authorizationId} />
        </PageContainer>
    );
}
