import { UpdateVelocityControlData, CardsUpdateVelocityControl } from '@dock/types-dock-partner';
import { GenericServiceError } from '@dock/common';

import { updateCardVC } from '../../../client';

export type UpdateCardVCParams = {
    id: string;
    payload: CardsUpdateVelocityControl;
    idempotencyKey: string;
};

export const updateCardVelocityControl = async ({
    id,
    payload,
    idempotencyKey,
}: UpdateCardVCParams): Promise<UpdateVelocityControlData> => {
    const headers = {
        'idempotency-key': idempotencyKey,
    };

    const errorOrResponse = await updateCardVC(id)({ payload, headers });

    if (errorOrResponse instanceof GenericServiceError) {
        throw errorOrResponse;
    }

    return errorOrResponse;
};
