import { StepType } from '@dock/react';
import commonLang from '../../../lang/common';
import createCardSC from '../../../lang/createCardSC';
import { Mode } from './types';

export const getStepsConfig = (mode: Mode = 'create'): StepType[] => [
    {
        label: commonLang.STEPS.BASIC_DETAILS,
        isOptional: false,
    },
    {
        label: createCardSC.SPENDING_CONFIGURATION,
        isOptional: false,
    },
    {
        label: createCardSC.SC_SUMMARY,
        isOptional: false,
        isHidden: mode === 'edit',
    },
];
