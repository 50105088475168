import { useCallback } from 'react';
import { IdempotencyKey } from '@dock/types-common';
import { Link } from '@mui/material';
import { ActionModalWrapper } from '@dock/react';
import {
    ExternalReferenceStep,
    generateErrorText,
    generateIdempotencyKey,
    interpolateParams,
    useCreateModal,
    useStatusModals,
    SuccessStatusMessage,
    ACCOUNT_DETAILS_TRANSACTIONS_ROUTE,
} from '../../../../common';
import { BasicDetailsStep } from './Form/BasicDetailsStep';
import { useCreateAccount } from '../../../../services';
import accountsLang from '../../../../lang/accounts';
import commonLang from '../../../../lang/common';
import { responseFieldsMap } from './responseFieldsMap';

type CreateAccountModalProps = {
    handleClose: () => void;
    isOpen: boolean;
    setOpenFormModal: () => void;
};

type CreateAccountPayloadType = {
    tenantId: string;
    accountHolderId: string;
    label: string;
    productId: string;
    externalReference: string;
};

export type CreateAccountFormType = CreateAccountPayloadType & IdempotencyKey;

const steps = [
    {
        label: commonLang.STEPS.BASIC_DETAILS,
        isOptional: false,
    },
    {
        label: commonLang.EXTERNAL_REFERENCE,
        isOptional: true,
    },
];

export function CreateAccountModal({
    handleClose,
    setOpenFormModal,
    isOpen,
}: CreateAccountModalProps) {
    const defaultValues: CreateAccountFormType = {
        tenantId: '',
        accountHolderId: '',
        label: '',
        productId: '',
        externalReference: '',
        idempotencyKey: generateIdempotencyKey(),
    };
    const { showSuccessModal, showErrorModal } = useStatusModals();
    const { mutateAsync, isLoading } = useCreateAccount();

    const {
        formValues,
        handleForm,
        stepper,
        handleErrorResponse,
        handleCloseModal,
        handleChangeData,
    } = useCreateModal<CreateAccountFormType>({
        defaultValues,
        closeFormModal: handleClose,
        openFormModal: setOpenFormModal,
    });

    const { activeStep, handleBack, handleNext } = stepper;

    const { tenantId, accountHolderId, label, productId, externalReference, idempotencyKey } =
        formValues;

    const handleChangeDataClick = () => {
        handleChangeData({ idempotencyKey: generateIdempotencyKey() });
    };

    const handleFormSubmit = async (extReference: string) => {
        try {
            const response = await mutateAsync({
                payload: {
                    tenantId,
                    accountHolderId,
                    label,
                    definitionId: productId,
                    externalReference: extReference,
                },
                idempotencyKey,
            });

            handleCloseModal();
            const href = interpolateParams(ACCOUNT_DETAILS_TRANSACTIONS_ROUTE, {
                id: response.id,
            });
            showSuccessModal({
                messages: [
                    <SuccessStatusMessage
                        preLabel={accountsLang.CREATE_NEW.SUCCESS_PRE}
                        postLabel={accountsLang.CREATE_NEW.SUCCESS_POST}
                    >
                        <Link href={href}>{response.id}</Link>
                    </SuccessStatusMessage>,
                ],
            });
        } catch (e) {
            handleErrorResponse();

            showErrorModal({
                messages: [accountsLang.CREATE_NEW.FAILED, generateErrorText(e, responseFieldsMap)],
                onChangeClick: handleChangeDataClick,
                onSubmitClick: () => handleFormSubmit(externalReference || ''),
            });
        }
    };

    const handleOnSubmitClick = async (value: string) => {
        handleForm({ externalReference: value });
        await handleFormSubmit(value);
    };

    const renderCorrectStep = useCallback(() => {
        if (activeStep === 0) {
            return (
                <BasicDetailsStep
                    handleNextStep={handleNext}
                    handleForm={handleForm}
                    defaultValues={{
                        tenantId,
                        accountHolderId,
                        label,
                        productId,
                    }}
                    handleCloseModal={handleCloseModal}
                />
            );
        }

        return (
            <ExternalReferenceStep
                handleBackStep={handleBack}
                defaultValues={{ externalReference }}
                handleFormSubmit={handleOnSubmitClick}
                handleCloseModal={handleCloseModal}
                isLoading={isLoading}
                submitButtonText={accountsLang.CREATE_NEW.CREATE_ACCOUNT}
            />
        );
    }, [activeStep, isLoading]);

    return (
        <ActionModalWrapper
            activeStep={activeStep}
            steps={steps}
            handleClose={handleCloseModal}
            isOpen={isOpen}
            title={accountsLang.CREATE_NEW.CREATE_NEW}
            testId="createAccountModal"
        >
            {renderCorrectStep()}
        </ActionModalWrapper>
    );
}
