export default {
    CREATE_NEW: 'Create new',
    CREATE: 'Create',
    EDIT: 'Edit',
    NEXT: 'Next',
    STATUS: 'Status',
    WINDOW: 'Window',
    SUBMIT: 'Submit',
    SEARCH: 'Search',
    AMOUNT: 'Amount',
    FILTER: 'Filters',
    APPLY: 'Apply',
    RESET_FILTERS: 'Reset filters',
    SAVE_CHANGES: 'Save changes',
    NAME: 'Name',
    CURRENCY: 'Currency',
    FILTERS: {
        AMOUNT_TEXT: 'Minimal amount is bigger than maximum',
    },
    AVAILABLE_BALANCE: 'Available balance',
    GET_ERROR: (value: string) => `Wrong value provided for: «${value}»`,
    STEPS: {
        BASIC_DETAILS: 'Basic details',
        PERSONALISATION_DETAILS: 'Personalisation details',
        ADDITIONAL_DETAILS: 'Additional details',
    },
    EXTERNAL_REFERENCE: 'External reference',
    PREVIOUS_STEP: 'Previous step',
    BACK_TO_EDITING: 'Back to editing',
    CANCEL: 'Cancel',
    COMING_SOON: 'Coming soon',
    SUPPORT: 'Support',
    TOOLTIP: {
        EXTERNAL_REFERENCE: 'Use to insert specific data to Dock Platform.',
    },
    FORMS: {
        EXTERNAL_REFERENCE: 'External reference',
        EXTERNAL_REFERENCE_HELPER_TEXT:
            'Only 0-9, a-z, A-Z, - and _ (example: external_reference-1234)',
    },
    LEGAL: 'Legal',
    PRIVACY_NOTICE: 'Privacy Notice',
    LEGAL_NOTICE: 'Legal Notice',
    ROUTES_NAME: {
        OVERVIEW: 'Overview',
        CARD_TXS_OVERVIEW: 'Card transactions overview',
        CARD_TXS_AUTH: 'Card Authorizations events',
        TENANTS: 'Tenants',
        ACCOUNTS: 'Accounts',
        BANK_TRANSFERS: 'Bank transfers',
        CARDS: 'Cards',
        SPENDING_CONTROLS: 'Spending controls',
        PRODUCT_DEFINITIONS: 'Product Definitions',
        IDENTITIES: 'Identities',
        USERS: 'Users',
        AUDIT: 'Audit',
        PRODUCT_DEFINITIONS_MOBILE: 'Prd Definitions',
        A2A: 'A2A transfers',
        LIST_AND_HOLDERS: 'List',
    },
    BREADCRUMBS: {
        ACCOUNTS: 'Accounts',
        ACCOUNT_DETAILS: 'Account details',
        CARDS: 'Cards',
        CARDS_SPENDING_CONTROL: 'Spending controls',
        CARDS_SPENDING_CONTROL_DETAILS: 'Spending controls details',
        CARD_DETAILS: 'Card details',
        TRANSACTION_DETAILS: 'Transaction details',
        A2A_PAYMENTS_DETAILS_TITLE: 'A2A transfer payment details',
        AUTHORIZATION_DETAILS: 'Authorization details',
        A2A_DETAILS: 'A2A transfer details',
        A2A_TRANSFER: 'A2A transfer',
        BANK_TRANSFERS: 'Bank transfers',
        BANK_TRANSFER: 'Bank transfer',
        BANK_TRANSFER_PAYMENT_DETAILS: 'Bank transfer payment details',
        BANK_TRANSFER_DETAILS: 'Bank transfer details',
        CARD_TXS_OVERVIEW: 'Card transactions overview',
        CREATE_SPENDING_CONTROL: 'Create new spending control',
        EDIT_SPENDING_CONTROL: 'Edit spending control',
    },
    STATUS_MODALS: {
        ARIA_TITLE: 'Status modal',
        ERROR_TITLE: 'Error!',
        SUCCESS_TITLE: 'Success!',
        CHANGE_DATA: 'Change provided data',
        SUBMIT_AGAIN: 'Submit again',
        OK: 'Ok',
    },
    DATE_TOOLTIP: 'All times displayed in the application are matching your local time.',
    DATE_LOCALTIME: 'Local time:',
    DATE_UTC_TOOLTIP: 'All times displayed in the API are UTC time',
    DATE_UTC: 'UTC:',
    CHANGE_STATUS: 'Change status',
    GET_CHANGE_STATUS_WARNING_MESSAGE: (status: string) =>
        `Once you select «${status}» status, you won't be able to switch to another status later.`,
    ACCOUNT_ID: 'Account ID',
    PRODUCT_ID: 'Product ID',
    TENANT_ID: 'Tenant ID',
    NOTES: 'Notes',
    RECIPIENT_ID: 'Recipient ID',
    SENDER_ID: 'Sender ID',
    LABEL: 'Label',
    LABEL_DESCRIPTION: 'Provide human readable label, e.g.: Dock Financial GmbH.',
    TYPE: 'Type',
    REASON: 'Reason',
    INITIATED_TIME: 'Initiated time',
    COMPLETED_TIME: 'Completed time',
    CREATED_TIME: 'Created time',
    PROCEED: 'Proceed',
    DATETIMES: 'Date times',
    ID: 'ID',
    FIELD_REQUIRED: 'Field is required',
    GET_MAX_LENGTH: (length: number) => `Max length is ${length} characters`,
};
