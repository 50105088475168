import {
    ListBankTransferInstrumentParams,
    GetCardsParams,
    ListInstrumentsParams as A2AListInstrumentsParams,
} from '@dock/types-dock-partner';
import { AccountPaymentMethodFiltersURLParams } from './useAccountPaymentMethodFilters';
import { filterUrlParamsExtractor } from '../../../../common';

type TransformParams =
    | Pick<A2AListInstrumentsParams, 'account_id' | '_order_by'>
    | Pick<GetCardsParams, 'account_id' | '_order_by'>
    | Pick<ListBankTransferInstrumentParams, 'account_id' | '_order_by'>;

const customKeyUrlParamsExtractor = filterUrlParamsExtractor({})([
    { key: 'account_id', value: 'accountId' },
]);

type ExtendedAccountPaymentMethodFiltersURLParams = AccountPaymentMethodFiltersURLParams & {
    accountId: string;
};

export const transformPaymentMethodsURLParams = (
    urlParams: AccountPaymentMethodFiltersURLParams,
    accountId: string
): TransformParams =>
    customKeyUrlParamsExtractor<ExtendedAccountPaymentMethodFiltersURLParams>()({
        ...urlParams,
        accountId,
    });
