import { TransactionsTable } from './components/TransactionTable/TransactionsTable';
import { BankTransferDetails } from './BankTransferDetails';

export function BankTransferDetailsTransactionsTable() {
    return (
        <BankTransferDetails>
            <TransactionsTable />
        </BankTransferDetails>
    );
}
