import TableCell from '@mui/material/TableCell';
import {
    TextWithCopyIcon,
    useBoolean,
    ActionsMenu,
    ActionMenuItemProps,
    Column,
    ExpandRow,
    CollapsableTableElement,
    AmountCell,
    NavLink,
    ViewDetailsLink,
    EditItem,
} from '@dock/react';
import {
    CardsVelocityControlListEntry,
    CardsVelocityControlTargetKind,
} from '@dock/types-dock-partner';
import { transformDate } from '@dock/react-util';
import { Typography } from '@mui/material';
import { DASH } from '@dock/common';
import {
    TablesTypography,
    CustomVirtualisationWrapper,
    cardsVCWindowKindMap,
    ENV_VARS,
    CARD_SPENDING_CONTROL_DETAILS_ROUTE,
    interpolateParams,
    LinkWithCopyIcon,
    CARD_SPENDING_CONTROL_EDIT_ROUTE,
} from '../../../../../common';
import lang from '../../../../../lang/spendingControl';
import { SpendingControlCollapsableRow } from './SpendingControlCollapsableRow';

export const collapsableRowColumns = (targetKind: CardsVelocityControlTargetKind): Column[] => [
    {
        key: 'name',
        name: lang.NAME,
    },
    {
        key: 'category',
        name: lang.CATEGORY,
    },
    {
        key: 'cardId',
        name:
            targetKind === CardsVelocityControlTargetKind.CARD
                ? lang.CARD_ID
                : lang.CARD_PRODUCT_DEFINITION_ID,
    },
    {
        key: 'startAt',
        name: lang.CREATED,
    },
];

export type A2ATableRowProps = Pick<
    CardsVelocityControlListEntry,
    'id' | 'label' | 'target' | 'datetimes' | 'velocity' | 'notes' | 'tenantId' | 'window'
>;

export function SpendingControlTableRow({
    label,
    target,
    velocity: { spendingLimit },
    window: { kind },
    datetimes,
    id,
}: A2ATableRowProps) {
    const { amount, currency } = spendingLimit;
    const [isOpen, handleRow] = useBoolean(false);

    const { effectiveFrom, effectiveUntil } = datetimes;
    const from = transformDate(new Date(effectiveFrom));
    const until = effectiveUntil ? transformDate(new Date(effectiveUntil)) : DASH;

    const detailsLink = interpolateParams(CARD_SPENDING_CONTROL_DETAILS_ROUTE, {
        id,
    });

    const editLink = interpolateParams(CARD_SPENDING_CONTROL_EDIT_ROUTE, {
        id,
    });

    const actions: ActionMenuItemProps[] = [
        {
            content: <NavLink to={detailsLink} label={<ViewDetailsLink />} />,
            id: '0',
        },
        {
            content: <NavLink to={editLink} label={<EditItem />} />,
            id: '1',
        },
    ];

    return (
        <>
            <CustomVirtualisationWrapper
                index={id}
                testId="scRow"
                isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
            >
                <TableCell>
                    <ExpandRow isOpen={isOpen} onClick={handleRow} />
                </TableCell>
                <TableCell data-testid="idCell">
                    <TextWithCopyIcon text={id}>
                        <LinkWithCopyIcon href={detailsLink} text={id} ellipsis="start" />
                    </TextWithCopyIcon>
                </TableCell>
                <AmountCell amount={amount} />
                <TableCell data-testid="currencyCell">
                    <TablesTypography content={currency} />
                </TableCell>
                <TableCell data-testid="frequencyCell">
                    <TablesTypography content={cardsVCWindowKindMap[kind] || kind} />
                </TableCell>
                <TableCell>
                    <Typography data-testid="startedDateCell" variant="subtitle1">
                        {from}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography data-testid="startedDateCell" variant="subtitle1">
                        {until}
                    </Typography>
                </TableCell>
                <TableCell data-testid="actionsCell">
                    <ActionsMenu menuItems={actions} />
                </TableCell>
            </CustomVirtualisationWrapper>
            <CollapsableTableElement
                isOpen={isOpen}
                columns={collapsableRowColumns(target.kind)}
                RowComponent={
                    <SpendingControlCollapsableRow
                        datetimes={datetimes}
                        target={target}
                        label={label ?? DASH}
                    />
                }
            />
        </>
    );
}
