import { useState } from 'react';
import {
    ACCESS_TOKEN,
    AUTH_ACCESS_TOKEN,
    parseLoginToken,
    USER,
    DockError,
    REFRESH_TOKEN,
} from '@dock/common';
import { signJwt } from '@dock/jwt';
import { useLocalStorage } from '@dock/react-hooks';
import { AuthCreateChallenge, AuthToken } from '@dock/types-dock-partner';
import { useAlertNotifications } from '@dock/react';
import { useCryptokeyChallengeMutation, useVerifyCryptokeyChallengeMutation } from '../services';
import { AuthUser, generateErrorText, loginBL } from '../common';
import loginLang from '../lang/login';

const getNoErrorValue = <T, R>(value: T | Error | undefined, defaultValue: R): T | R =>
    value instanceof Error || value === undefined ? defaultValue : value;

const getPayload = ({
    actorId,
    cryptokeyId,
}: {
    actorId: string | null | undefined;
    cryptokeyId: string;
}): AuthCreateChallenge => ({
    ...(actorId ? { actorId, cryptokeyId } : { cryptokeyId }),
});

export type UseProvideAuthType = {
    user: AuthUser;
    login: (value: string) => void;
    logout: () => void;
    validationError: boolean;
    isLoading: boolean;
    isLoggedIn: boolean;
};

const defaultUserValue: AuthUser = { id: '', name: '', email: '' };

const defaultTokenValue: AuthToken = {
    id: '',
    token: '',
    datetimes: { expires: '' },
};

export function useProvideAuth(): UseProvideAuthType {
    const { showErrorNotification } = useAlertNotifications();

    const { value: userFromStorage, setValue: setUserToStorage } = useLocalStorage<AuthUser>(USER);

    const { value: authAccessTokenFromStorage, setValue: setAuthAccessTokenToStorage } =
        useLocalStorage<string>(AUTH_ACCESS_TOKEN);

    const { value: accessTokenFromStorage, setValue: setAccessTokenToStorage } =
        useLocalStorage<AuthToken>(ACCESS_TOKEN);

    // TODO: uncomment after BE fix
    // const { value: capabilities, setValue: setCapabilitiesToStorage } =
    //     useLocalStorage<UsersUserCapability[]>(CAPABILITIES);

    const { value: refreshTokenFromStorage, setValue: setRefreshTokenToStorage } =
        useLocalStorage<AuthToken>(REFRESH_TOKEN);

    const [validationError, setValidationError] = useState<boolean>(false);

    const isLoggedIn = loginBL.isUserLoggedIn(
        userFromStorage,
        accessTokenFromStorage,
        refreshTokenFromStorage,
        authAccessTokenFromStorage
    );

    const { mutateAsync: createCryptokeyChallenge, isLoading: cryptokeyChallengeLoading } =
        useCryptokeyChallengeMutation();

    const { mutateAsync: verifyCryptokeyChallenge, isLoading: verifyCryptokeyChallengeLoading } =
        useVerifyCryptokeyChallengeMutation();

    // TODO: uncomment after BE fix
    // const { data: capabilitiesData } = useCapabilities({
    //     enabledFetch: loginBL.shouldFetchCapabilities(
    //         accessTokenFromStorage,
    //         refreshTokenFromStorage,
    //         authAccessTokenFromStorage
    //     ),
    // });

    const isLoading = cryptokeyChallengeLoading || verifyCryptokeyChallengeLoading;

    const handleSetUser = (value: AuthUser) => {
        setUserToStorage(value);
    };

    const handleSetPairOfTokens = (accessToken: AuthToken, refreshToken: AuthToken) => {
        setAccessTokenToStorage(accessToken);
        setRefreshTokenToStorage(refreshToken);
    };

    const login = async (password: string) => {
        try {
            // 1. Parse login token, grab the auth access token
            const passwordObj = parseLoginToken(password);
            if (passwordObj instanceof DockError) {
                // eslint-disable-next-line no-console
                console.error(passwordObj.message);
                setValidationError(true);
                return;
            }
            const { actorId, cryptokeyId, email, name, cryptokey, authToken } = passwordObj;

            // 2. Set auth access token to the storage
            setAuthAccessTokenToStorage(authToken);

            // 3. Call create cryptokey challenge API
            const challengeResponse = await createCryptokeyChallenge(
                getPayload({ actorId, cryptokeyId })
            );

            // 4. Sign challenge response with cryptokey
            const jwtToken = await signJwt(cryptokey, {
                ...challengeResponse.claims,
                exp: Number(challengeResponse.claims.exp),
                iat: Number(challengeResponse.claims.iat),
            });
            if (jwtToken instanceof Error) {
                // eslint-disable-next-line no-console
                console.error('error signing token', jwtToken);
                setValidationError(true);
                return;
            }

            // 5. Call verify cryptokey challenge API if jwtToken has been signed
            const verifyResponse = await verifyCryptokeyChallenge({
                id: challengeResponse.id,
                body: { token: jwtToken },
            });

            // 6. Set user and tokens to storage
            handleSetUser({
                name,
                email,
                id: challengeResponse.userId,
            });
            handleSetPairOfTokens(verifyResponse.accessToken, verifyResponse.refreshToken);

            setValidationError(false);
        } catch (e) {
            showErrorNotification({
                title: loginLang.ERROR_MESSAGE,
                description: generateErrorText(e),
            });
        }
    };

    const logout = () => {
        setAccessTokenToStorage(defaultTokenValue);
        setRefreshTokenToStorage(defaultTokenValue);
        handleSetUser(defaultUserValue);
        setAuthAccessTokenToStorage('');
    };

    // TODO: uncomment after BE fix
    // useEffect(() => {
    //     if (capabilitiesData?.capabilities) {
    //         setCapabilitiesToStorage(capabilitiesData?.capabilities);
    //     }
    // }, [capabilitiesData?.capabilities]);

    return {
        user: getNoErrorValue(userFromStorage, defaultUserValue),
        login,
        logout,
        validationError,
        isLoading,
        isLoggedIn,
    };
}
