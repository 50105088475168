import { SimpleTooltip } from '@dock/react';
import { useTimer } from '@dock/react-hooks';
import { transformDate, DATE_HOURS_AND_MINUTES, formatUTCDate } from '@dock/react-util';
import { Box, Typography } from '@mui/material';
import lang from '../../../lang/common';

const localAndUtcBoxStyles = {
    backgroundColor: 'secondary.light',
    color: 'primary.dark',
    boxShadow: 'none',
    px: 3,
    py: 1,
};

export function LocalAndUtcDate() {
    const dateNow = useTimer();

    return (
        <Box data-testid="localAndUtcDate" sx={{ display: 'flex', gap: '16px' }}>
            <SimpleTooltip title={lang.DATE_TOOLTIP}>
                <Typography
                    sx={{
                        ...localAndUtcBoxStyles,
                        borderRadius: '25px',
                    }}
                >
                    {`${lang.DATE_LOCALTIME} ${transformDate(dateNow, DATE_HOURS_AND_MINUTES)}`}
                </Typography>
            </SimpleTooltip>
            <SimpleTooltip title={lang.DATE_UTC_TOOLTIP}>
                <Typography
                    sx={{
                        ...localAndUtcBoxStyles,
                        borderRadius: '25px',
                    }}
                >
                    {`${lang.DATE_UTC} ${formatUTCDate(dateNow)}`}
                </Typography>
            </SimpleTooltip>
        </Box>
    );
}
