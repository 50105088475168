import { useEffect } from 'react';
import { useSearchParams, URLSearchParamsInit } from 'react-router-dom';

export const useWriteSearchParams = (values: URLSearchParamsInit) => {
    const [, setSearchParams] = useSearchParams();

    useEffect(() => {
        setSearchParams(values, { replace: true });
    }, [values]);
};
