import { useMutation } from '@tanstack/react-query';
import { UpdateVelocityControlData } from '@dock/types-dock-partner';
import { queryClient } from '../../client';
import { VELOCITY_CONTROL_KEY } from '../../keys';
import { updateTokensOnMutate } from '../updateTokensOnMutate';
import { updateCardVelocityControl, UpdateCardVCParams } from '../methods/cards/updateCardVC';
import { PartnerServiceError } from '../../../common/PartnerServiceError';

export function useUpdateCardVC() {
    return useMutation<UpdateVelocityControlData, PartnerServiceError, UpdateCardVCParams>(
        ({ id, payload, idempotencyKey }) =>
            updateCardVelocityControl({
                id,
                payload,
                idempotencyKey,
            }),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: [VELOCITY_CONTROL_KEY],
                });
            },
            onMutate: updateTokensOnMutate,
        }
    );
}
