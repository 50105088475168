import { Column, ColumnAlign } from '@dock/react';
import cardsLang from '../../../../lang/cardDetails';

export const cardAuthorizationsTableColumns: Column[] = [
    { key: 'expand', name: '' },
    {
        key: 'authorizationID',
        name: cardsLang.CARD_AUTHORIZATIONS_TABLE.AUTH_ID,
    },
    { key: 'status', name: cardsLang.CARD_AUTHORIZATIONS_TABLE.STATUS },
    {
        key: 'amount',
        name: cardsLang.CARD_AUTHORIZATIONS_TABLE.AMOUNT,
        align: ColumnAlign.RIGHT,
    },
    { key: 'currency', name: cardsLang.CARD_AUTHORIZATIONS_TABLE.CURRENCY },
    {
        key: 'initiatedTime',
        name: cardsLang.CARD_AUTHORIZATIONS_TABLE.INITIATED_TIME,
        sortable: true,
    },
    {
        key: 'paymentSource',
        name: cardsLang.CARD_AUTHORIZATIONS_TABLE.PAYMENT_SOURCE,
    },
    {
        key: 'merchantName',
        name: cardsLang.CARD_AUTHORIZATIONS_TABLE.MERCHANT_NAME,
    },
    { key: 'actions', name: '' },
];
