import React from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { ErrorSvg, PrimaryTextButton, SecondaryButton } from '@dock/react';
import commonLang from '../../../lang/common';

type ErrorStatusContentProps = {
    statusMessages: string[] | React.ReactElement[];
    onChangeClick: () => void;
    onSubmitAgainClick: () => void;
    buttonStyles?: SxProps<Theme>;
    isLoading?: boolean;
};

export function ErrorStatusContent({
    statusMessages,
    onChangeClick,
    onSubmitAgainClick,
    buttonStyles = {},
    isLoading = false,
}: ErrorStatusContentProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <ErrorSvg />

            <Typography component="h2" variant="h2">
                {commonLang.STATUS_MODALS.ERROR_TITLE}
            </Typography>

            {statusMessages.map((message, index) => (
                <Typography
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    sx={{
                        width: '80%',
                        textAlign: 'center',
                    }}
                >
                    {message}
                </Typography>
            ))}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 3,
                    width: '100%',
                    ...buttonStyles,
                }}
            >
                <PrimaryTextButton onClick={onChangeClick} testId="changeData">
                    {commonLang.STATUS_MODALS.CHANGE_DATA}
                </PrimaryTextButton>
                <SecondaryButton
                    onClick={onSubmitAgainClick}
                    testId="errorButton"
                    isLoading={isLoading}
                >
                    {commonLang.STATUS_MODALS.SUBMIT_AGAIN}
                </SecondaryButton>
            </Box>
        </Box>
    );
}
