import { transformDate } from '@dock/react-util';
import {
    BankTransferBankTransferTransaction,
    BankTransferBankTransferTransactionSepaSpecific,
    BankTransferBankTransferTransactionPartySepa,
    BankTransferTransactionKind,
    BankTransferBankTransferTransactionSepaKind,
} from '@dock/types-dock-partner';

// TODO: consider to use type from generated ones
export type BankTransferDetails = {
    id: string;
    kind: BankTransferTransactionKind;
    amount: string;
    currency: string;
    dateTime: string;
    bankTransferId: string;
    paymentDetails: string;
    paymentType: BankTransferBankTransferTransactionSepaKind;
    paymentSystemSpecific: {
        counterParty: ReturnType<typeof partyMaker>;
        party: ReturnType<typeof partyMaker>;
    };
};

const partyMaker = (
    sepaOrSwift?: BankTransferBankTransferTransactionPartySepa // | BankTransferTransactionPartySwift
) => ({
    name: sepaOrSwift?.name ?? '-',
    address: sepaOrSwift?.addressLine1 ?? sepaOrSwift?.addressLine2 ?? '-',
    country: sepaOrSwift?.country ?? '-',
    bic: sepaOrSwift?.bic ?? '-',
    iban: sepaOrSwift?.iban ?? '-',
    state: '-',
    city: '-',
});

export const bankTransfersDetailMapper = ({
    id,
    kind,
    amount,
    datetimes,
    bankTransferId,
    paymentDetails,
    paymentSystemSpecific,
}: BankTransferBankTransferTransaction): BankTransferDetails => {
    let partyData = { party: partyMaker(), counterParty: partyMaker() };

    if ('Sepa' in paymentSystemSpecific) {
        const { Sepa } = paymentSystemSpecific ?? {
            Sepa: undefined,
        };
        const { party, counterparty } = Sepa as BankTransferBankTransferTransactionSepaSpecific;
        partyData = {
            party: partyMaker(party),
            counterParty: partyMaker(counterparty),
        };
    }
    return {
        id,
        kind,
        amount: amount.amount,
        currency: amount.currency,
        dateTime: datetimes.created ? transformDate(new Date(datetimes.created)) : '-',
        bankTransferId: bankTransferId ?? '-',
        paymentDetails,
        paymentType: paymentSystemSpecific.Sepa.kind,
        paymentSystemSpecific: partyData,
    };
};
