import { createContext } from 'react';
import { UseProvideAuthType } from './useProvideAuth';

const defaultValue: UseProvideAuthType = {
    user: { id: '', name: '', email: '' },
    login: () => {},
    logout: () => {},
    validationError: false,
    isLoading: false,
    isLoggedIn: false,
};

export const authContext = createContext<UseProvideAuthType>(defaultValue);
