import { Column, ColumnAlign } from '@dock/react';
import cardOverviewLang from '../../../../lang/cardTransactionsOverview';

export const transactionsOverviewColumns: Column[] = [
    {
        key: 'id',
        name: cardOverviewLang.CARD_TRANSACTIONS_TABLE.TRANSACTION_ID,
    },
    { key: 'cardId', name: cardOverviewLang.CARD_TRANSACTIONS_TABLE.CARD_ID },
    { key: 'status', name: cardOverviewLang.CARD_TRANSACTIONS_TABLE.STATUS },
    { key: 'type', name: cardOverviewLang.CARD_TRANSACTIONS_TABLE.TYPE },
    {
        key: 'amount',
        name: cardOverviewLang.CARD_TRANSACTIONS_TABLE.AMOUNT,
        align: ColumnAlign.RIGHT,
    },
    {
        key: 'currency',
        name: cardOverviewLang.CARD_TRANSACTIONS_TABLE.CURRENCY,
    },
    {
        key: 'paymentSource',
        name: cardOverviewLang.CARD_TRANSACTIONS_TABLE.PAYMENT_SOURCE,
    },
    {
        key: 'merchantName',
        name: cardOverviewLang.CARD_TRANSACTIONS_TABLE.MERCHANT_NAME,
    },
    {
        key: 'completedTime',
        name: cardOverviewLang.CARD_TRANSACTIONS_TABLE.COMPLETED_TIME,
        sortable: true,
    },
    { key: 'actions', name: '' },
];
