import { getAccessTokenByURL, InternalRequestConfig } from '@dock/common';

export const dockPartnerAppAuthTokenInterceptorRequest = async (
    config: InternalRequestConfig
): Promise<InternalRequestConfig> => {
    const newConfig = { ...config };

    if (newConfig.headers && newConfig.url) {
        const accessToken = getAccessTokenByURL(newConfig.url);
        newConfig.headers.Authorization = `Bearer ${accessToken}`;
    }

    return newConfig;
};
