import { Column } from '@dock/react';
import accountDetailsLang from '../../../../lang/accountDetails';

export const accountsPaymentsColumns: Column[] = [
    {
        key: 'instrumentID',
        name: accountDetailsLang.PAYMENT_METHOD_TABLE.COLUMNS.INSTRUMENT_ID,
    },
    {
        key: 'externalReference',
        name: accountDetailsLang.PAYMENT_METHOD_TABLE.COLUMNS.EXTERNAL_REFERENCE,
    },
    {
        key: 'createdTime',
        name: accountDetailsLang.PAYMENT_METHOD_TABLE.COLUMNS.CREATION_TIME,
        sortable: true,
    },
];
