import {
    ContentTitle,
    Accordion,
    AccordionTitle,
    TextWithCopyIcon,
    TextWithCopyIconContent,
} from '@dock/react';
import { Box, Grid } from '@mui/material';
import { BankTransferBankTransferInstrument } from '@dock/types-dock-partner';
import lang from '../../../../lang/bankTransferDetails';
import {
    InformationBox,
    ACCOUNT_DETAILS_TRANSACTIONS_ROUTE,
    interpolateParams,
    LinkWithCopyIcon,
} from '../../../../common';
import { accordionStyles } from './styles';

type BankTransferDetailsBoxProps = {
    transfer: BankTransferBankTransferInstrument | undefined;
    isFetching: boolean;
};

export function BankTransferDetailsBox({ transfer, isFetching }: BankTransferDetailsBoxProps) {
    const accountDetailsLink = interpolateParams(ACCOUNT_DETAILS_TRANSACTIONS_ROUTE, {
        id: transfer?.accountId || '',
    });

    const accountId = transfer?.accountId || '';

    return (
        <>
            <Box sx={{ mb: '40px' }}>
                <InformationBox>
                    <Box sx={{ display: 'flex', gap: '40px' }}>
                        <Box>
                            <ContentTitle
                                title={lang.TRANSFER_ID}
                                content={
                                    <TextWithCopyIcon text={transfer?.id ?? ''}>
                                        <TextWithCopyIconContent
                                            testId="transferId"
                                            text={transfer?.id ?? ''}
                                        />
                                    </TextWithCopyIcon>
                                }
                                isLoading={isFetching}
                            />
                        </Box>
                        <Box>
                            <ContentTitle
                                title={lang.ACCOUNT_ID}
                                content={
                                    <TextWithCopyIcon text={accountId}>
                                        <LinkWithCopyIcon
                                            href={accountDetailsLink}
                                            text={accountId}
                                        />
                                    </TextWithCopyIcon>
                                }
                                isLoading={isFetching}
                            />
                        </Box>
                        <Box>
                            <ContentTitle
                                title={lang.EXTERNAL_REFERENCE}
                                content={transfer?.externalReference || ''}
                                isLoading={isFetching}
                            />
                        </Box>
                    </Box>
                </InformationBox>
            </Box>
            {transfer?.routingInfos && (
                <Box sx={{ mb: '40px' }}>
                    <Accordion
                        isWithIcon
                        title={lang.ACCORDION.TITLE_IBAN}
                        helpText={lang.ACCORDION.HELPTEXT_IBAN}
                        styles={accordionStyles}
                    >
                        <Grid container rowSpacing="20px">
                            {transfer?.routingInfos?.map(({ iban }, i) => (
                                <Grid item xs={12} md={6} lg={3} key={iban}>
                                    <AccordionTitle content={`IBAN ${i + 1}`} />
                                    <TextWithCopyIcon text={iban}>
                                        <TextWithCopyIconContent text={iban} variant="h4" />
                                    </TextWithCopyIcon>
                                </Grid>
                            ))}
                        </Grid>
                    </Accordion>
                    <Accordion
                        isWithIcon
                        title={lang.ACCORDION.TITLE_BIC}
                        helpText={lang.ACCORDION.HELPTEXT_BIC}
                        styles={accordionStyles}
                    >
                        <Grid container rowSpacing="20px">
                            {transfer?.routingInfos?.map(({ bic }, i) => (
                                <Grid item xs={6} sm={3} key={bic}>
                                    <AccordionTitle content={`BIC ${i + 1}`} />
                                    <TextWithCopyIcon text={bic}>
                                        <TextWithCopyIconContent text={bic} variant="h4" />
                                    </TextWithCopyIcon>
                                </Grid>
                            ))}
                        </Grid>
                    </Accordion>
                </Box>
            )}
        </>
    );
}
