import TableCell from '@mui/material/TableCell';
import {
    ActionMenuItemProps,
    ActionsMenu,
    TextWithCopyIcon,
    TextWithCopyIconContent,
    ViewDetailsLink,
    NavLink,
} from '@dock/react';
import type { A2ATransfersInstrument } from '@dock/types-dock-partner';
import { transformDate } from '@dock/react-util';
import {
    interpolateParams,
    ENV_VARS,
    A2A_DETAILS_TRANSACTIONS_ROUTE,
    StatusChip,
    TablesTypography,
    CustomVirtualisationWrapper,
    LinkWithCopyIcon,
} from '../../../../common';
import { a2AStatusMap } from './a2AStatusMap';
import { a2aStatusColorMap } from './a2aStatusColorMap';

export type A2ATableRowProps = Pick<
    A2ATransfersInstrument,
    'id' | 'status' | 'externalReference' | 'holderId' | 'datetimes'
>;

export function A2ATableRow({
    id,
    status,
    externalReference,
    holderId,
    datetimes,
}: A2ATableRowProps) {
    const detailsLink = interpolateParams(A2A_DETAILS_TRANSACTIONS_ROUTE, {
        id,
    });
    const createdDate = transformDate(new Date(datetimes.created));

    const actions: ActionMenuItemProps[] = [
        {
            content: <NavLink to={detailsLink} label={<ViewDetailsLink />} />,
            id: '0',
        },
    ];

    return (
        <CustomVirtualisationWrapper
            index={id}
            testId="a2aRow"
            isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
        >
            <TableCell data-testid="a2aIdCell">
                <TextWithCopyIcon text={id}>
                    <LinkWithCopyIcon href={detailsLink} text={id} ellipsis="start" />
                </TextWithCopyIcon>
            </TableCell>
            <TableCell data-testid="a2aHolderIdCell">
                <TextWithCopyIcon text={holderId}>
                    <TextWithCopyIconContent text={holderId} ellipsis="start" />
                </TextWithCopyIcon>
            </TableCell>
            <TableCell data-testid="a2aStatusCell">
                <StatusChip
                    color={a2aStatusColorMap[status]}
                    label={a2AStatusMap[status] || status}
                />
            </TableCell>
            <TableCell data-testid="externalReferenceCell">
                <TablesTypography content={externalReference || ''} />
            </TableCell>
            <TableCell data-testid="createdTimeCell">
                <TablesTypography content={createdDate} />
            </TableCell>
            <TableCell data-testid="actionsCell">
                <ActionsMenu menuItems={actions} />
            </TableCell>
        </CustomVirtualisationWrapper>
    );
}
