import { SingleSelect, SimpleTable, OptionType } from '@dock/react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { AccountsPaymentMethod } from '@dock/types-dock-partner';
import { AccountPaymentMethodRow } from './AccountPaymentMethodsRow';
import { accountsPaymentsColumns } from './accountsPaymentsColumns';
import { useAccountPaymentMethodFilters } from './useAccountPaymentMethodFilters';
import accountDetailsLang from '../../../../lang/accountDetails';
import { useAccountPaymentMethods } from './useAccountPaymentMethods';

const wrapperStyles = {
    display: 'flex',
    gap: 2,
    justifyContent: 'space-between',
    alignItems: 'center',
    mx: 3,
    my: 4,
};

const methodOptions: OptionType<AccountsPaymentMethod>[] = [
    {
        label: accountDetailsLang.PAYMENT_INSTRUMENT_METHODS.CARD,
        value: AccountsPaymentMethod.CARD,
    },
    {
        label: accountDetailsLang.PAYMENT_INSTRUMENT_METHODS.A2A_TRANSFER,
        value: AccountsPaymentMethod.A2ATRANSFER,
    },
    {
        label: accountDetailsLang.PAYMENT_INSTRUMENT_METHODS.BANK_TRANSFER,
        value: AccountsPaymentMethod.BANK_TRANSFER,
    },
];

export function AccountPaymentMethodsTable() {
    const { id: accountId = '' } = useParams();

    const { filters, handleSelect, sorting, handleSortOrder } = useAccountPaymentMethodFilters(
        AccountsPaymentMethod.CARD
    );

    const { data, error, fetchNextPage, isFetching, isFetchingNextPage, sourceMethod } =
        useAccountPaymentMethods({ filters, accountId });

    return (
        <>
            <Box sx={{ ...wrapperStyles, minWidth: '250px', maxWidth: '20%' }}>
                <SingleSelect<AccountsPaymentMethod>
                    id="method-option-select"
                    label={accountDetailsLang.SELECT_LABEL}
                    onChange={handleSelect}
                    defaultOption={sourceMethod}
                    options={methodOptions}
                />
            </Box>

            <SimpleTable
                columns={accountsPaymentsColumns}
                RowComponent={AccountPaymentMethodRow}
                isLoading={isFetching}
                isLoadingNextPage={isFetchingNextPage}
                onReachingBottom={fetchNextPage}
                error={error}
                rows={data}
                sorting={sorting}
                onSortClick={handleSortOrder}
            />
        </>
    );
}
