import { Box, Skeleton, Typography } from '@mui/material';
import { AccountsAccount } from '@dock/types-dock-partner';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import lang from '../../../lang/accountDetails';
import { ErrorBox } from './ErrorBox';
import { mapDataDoughnut, optDoughnut } from './chartDoughnut';
import { getBoxStyles } from '../../../common';

type AnalyticsProps = Pick<AccountsAccount, 'balances'> & {
    accountId: string;
    isError: boolean;
    isLoading: boolean;
};

ChartJS.register(ArcElement, Tooltip, Legend);

const emptyAmount = '0.00';

export function Analytics({ accountId, isError, isLoading, balances }: AnalyticsProps) {
    if (isError) {
        return <ErrorBox id={accountId} />;
    }

    const isNegativeBalance = parseFloat(balances.available.amount) < parseFloat(emptyAmount);

    return (
        <Box
            sx={({ palette }) => ({
                ...getBoxStyles(palette),
                display: 'block',
                pl: '20px',
                py: '25px',
                height: '100%',
            })}
            data-testid="analyticsBox"
        >
            <Typography
                variant="h3"
                sx={{
                    marginLeft: '23px',
                    position: 'absolute',
                }}
            >
                {lang.ANALYTICS}
            </Typography>
            <Box
                sx={{
                    height: '120px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '30px',
                }}
            >
                {isLoading && (
                    <Skeleton
                        animation="wave"
                        aria-label="progressbar"
                        data-testid="contentTitleSkeleton"
                        variant="rounded"
                        height={100}
                    />
                )}
                {!isLoading && !isNegativeBalance && balances.total.amount !== emptyAmount && (
                    <Doughnut data={mapDataDoughnut(balances)} options={optDoughnut} />
                )}
                {!isLoading && balances.total.amount === emptyAmount && (
                    <Typography>{lang.ANALYTICS_NOT_DATA}</Typography>
                )}
                {!isLoading && isNegativeBalance && (
                    <Typography>{lang.ANALYTICS_NEGATIVE_DATA}</Typography>
                )}
            </Box>
        </Box>
    );
}
