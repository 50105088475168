import { Column } from '@dock/react';
import a2aDetailsLang from '../../../../lang/a2aTransactionsDetails';

export const a2aPaymentEventsTableColumns: Column[] = [
    {
        key: 'paymentEventId',
        name: a2aDetailsLang.EVENTS_TABLE.PAYMENT_EVENT_ID,
    },
    {
        key: 'eventType',
        name: a2aDetailsLang.EVENTS_TABLE.EVENT_TYPE,
    },
    {
        key: 'createdTime',
        name: a2aDetailsLang.EVENTS_TABLE.CREATED_DATE,
        sortable: true,
    },

    { key: 'action', name: '' },
];
