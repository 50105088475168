import { ChangeEvent, Dispatch, useState } from 'react';

export const useCheckItems = <T>(
    defaultItems?: Array<T>
): [Array<T>, Dispatch<Array<T>>, (htmlCheck: ChangeEvent<HTMLInputElement>) => void] => {
    const [items, setItems] = useState<T[]>(defaultItems || []);

    const handleCheck = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const item = target.value as T;
        let newItems = [...items];
        if (target.checked) {
            newItems = [...items, item];
        } else {
            newItems.splice(items.indexOf(item), 1);
        }
        setItems(newItems);
    };
    return [items, setItems, handleCheck];
};
