import { useIdleTimer } from 'react-idle-timer';
import { FIFTEEN_MINUTES, THIRTY_SECONDS } from '@dock/common';
import { useModal } from '@dock/react-hooks';
import { SessionExpiredModal } from './SessionExpiredModal';
import { useAuth } from '../../../../auth';
import { ENV_VARS } from '../../../../common';

export function SessionExpired() {
    const { isOpen: isShowExpiredModal, setClose, setOpen } = useModal();
    const { logout } = useAuth();

    const onPrompt = () => {
        setOpen();
    };

    const onIdle = () => {
        logout();
        setClose();
    };

    const onActive = () => {
        setClose();
    };

    const { start } = useIdleTimer({
        timeout: ENV_VARS.IS_DEVELOPMENT_BUILD ? FIFTEEN_MINUTES * 3 : FIFTEEN_MINUTES,
        promptBeforeIdle: THIRTY_SECONDS,
        crossTab: true,
        syncTimers: 500,
        throttle: 500,
        onPrompt,
        onIdle,
        onActive,
    });

    const onContinue = () => {
        start();
        setClose();
    };

    return (
        <SessionExpiredModal
            isOpen={isShowExpiredModal}
            onCancel={onIdle}
            onContinue={onContinue}
        />
    );
}
