export default {
    A2A_TRANSFER_ID: 'A2A transfer ID',
    ACCOUNT_ID: 'Account ID',
    EXTERNAL_REFERENCE: 'External reference',
    TAB: {
        TRANSACTIONS: 'Transactions',
        SCHEDULE: 'Payments',
    },
    TRANSACTIONS_TABLE: {
        TRANSACTION_ID: 'Transaction ID',
        KIND: 'Kind',
        AVAILABLE_AMOUNT: 'Amount',
        CURRENCY: 'Currency',
        CREATED_DATE: 'Created time',
        EXTERNAL_REFERENCE: 'External reference',
        INSTRUMENT_ID: 'Instrument ID',
        PAYMENT_ID: 'Payment ID',
        DESCRIPTION: 'Description',
    },
    SCHEDULE_PAYMENTS_TABLE: {
        PAYMENT_EVENT_ID: 'Payment ID',
        RECIPIENT_ID: 'Recipient ID',
        AMOUNT: 'Amount',
        CURRENCY: 'Currency',
        CREATED_DATE: 'Created time',
        EXTERNAL_REFERENCE: 'External reference',
        EVENT_TYPE: 'Instrument ID',
        PAYMENT_ID: 'Payment ID',
        DESCRIPTION: 'Description',
    },
    SEARCH_OPTION: {
        TRANSACTION_ID: 'Transaction ID',
        RECIPIENT_ID: 'Recipient ID',
        PAYMENT_ID: 'Payment ID',
        EXTERNAL_REFERENCE: 'External reference',
    },
    TRANSFER_TRANSACTION_KIND: {
        PUSH: 'Push',
        PULL: 'Pull',
    },
    SEARCH_FOR_EVENT_ID: 'Search for payment event ID',
    PAY: {
        BUTTON: 'Pay',
        TITLE: 'Pay to another A2A instrument',
        AMOUNT: 'Amount',
        CURRENCY: 'Currency',
        RECIPIENT_ID: 'Recipient ID',
        RECIPIENT_ID_TOOLTIP: 'ID of the A2A transfer instrument to receive the payment amount.',
        SUBMIT_BUTTON: 'Complete payment',
        FAILED: 'Your submission for payment failed. Please try again.',
        SUCCESS_PRE: 'Your payment ',
        SUCCESS_POST: ' has been scheduled.',
    },
    CHANGE_STATUS: {
        TITLE: 'Change status',
    },
    STATUS_CHANGED: 'A2A status was successfully changed.',
    STATUS_CHANGE_FAILED: "A2A status wasn't changed. Please, try again.",
};
