import React from 'react';
import { ListItemIcon, SxProps } from '@mui/material';

type SidebarItemIconProps = {
    Icon: React.ReactNode;
    isNavbarOpen?: boolean | undefined;
    iconStyles?: SxProps;
};

export function SidebarItemIcon({ isNavbarOpen = true, Icon, iconStyles }: SidebarItemIconProps) {
    return (
        <ListItemIcon
            sx={{
                minWidth: 4,
                mr: isNavbarOpen ? 3 : 0,
                color: 'background.default',
                ...iconStyles,
            }}
        >
            {Icon}
        </ListItemIcon>
    );
}
