import { useState } from 'react';
import { Box, Link } from '@mui/material';
import { Stepper, WhiteBackgroundWrapper, useHandleStepper } from '@dock/react';
import {
    CardsVelocityControlWindowKind,
    CardsVelocityControlListEntry,
    CardsVelocityControlTargetKind,
    CardsVelocityControlScope,
} from '@dock/types-dock-partner';
import { Currency } from '@dock/types-common';
import {
    CARD_SPENDING_CONTROL_DETAILS_ROUTE,
    generateIdempotencyKey,
    interpolateParams,
} from '../../../common';
import { EditCardVCFormType } from './types';
import { useUpdateCardVC } from '../../../services';
import { BasicDetailsStep } from '../CardSpendingControlCreate/components/BasicDetailsStep';
import { getStepsConfig } from '../CardSpendingControlCreate/getStepsConfig';
import { SpendingConfigurationStep } from './components/SpendingConfigurationStep';
import { SuccessMessage } from '../CardSpendingControlCreate/components/SuccessMessage';
import { ErrorMessage } from '../CardSpendingControlCreate/components/ErrorMessage';
import { SpendingConfigurationStepFormValues } from '../CardSpendingControlCreate/types';
import editSCLang from '../../../lang/editSpendingControl';

const getDefaultValues = (
    controlDetails: CardsVelocityControlListEntry | undefined
): EditCardVCFormType => ({
    label: controlDetails?.label || null,
    tenantId: controlDetails?.tenantId || '',
    target: {
        id: controlDetails?.target.id || '',
        kind: controlDetails?.target.kind || CardsVelocityControlTargetKind.CARD,
    },
    scope: controlDetails?.scope || CardsVelocityControlScope.INDIVIDUAL,
    velocity: {
        spendingLimit: {
            amount: controlDetails?.velocity.spendingLimit.amount || '',
            currency: controlDetails?.velocity.spendingLimit.currency || Currency.EUR,
        },
    },
    window: {
        kind: controlDetails?.window.kind || (null as unknown as CardsVelocityControlWindowKind),
        months: controlDetails?.window.months || null,
        days: controlDetails?.window.days || null,
    },
    datetimes: {
        effectiveFrom: controlDetails?.datetimes.effectiveFrom || null,
        effectiveUntil: controlDetails?.datetimes.effectiveUntil || null,
    },
    idempotencyKey: generateIdempotencyKey(),
});

type EditCardSpendingControlFormProps = {
    controlDetails: CardsVelocityControlListEntry;
    id: string;
};

export function EditCardSpendingControlForm({
    controlDetails,
    id,
}: EditCardSpendingControlFormProps) {
    const steps = getStepsConfig('edit');
    const defaultValues = getDefaultValues(controlDetails);
    const { activeStep, handleBack, handleNext, handleReset } = useHandleStepper();
    const [formValues, setFormValues] = useState<EditCardVCFormType>(defaultValues);
    const handleForm = (values: Partial<EditCardVCFormType>) => {
        setFormValues((prevState) => ({ ...prevState, ...values }));
    };
    const { mutateAsync, error, isLoading, isSuccess, reset } = useUpdateCardVC();

    const { label, tenantId, target, velocity, window, datetimes, scope, idempotencyKey } =
        formValues;

    const handleFormSubmit = async (value: SpendingConfigurationStepFormValues) => {
        try {
            await mutateAsync({
                id,
                payload: {
                    label: label || '',
                    velocity: {
                        spendingLimit: {
                            amount: value.amount,
                            currency: value.currency,
                        },
                    },
                    window: {
                        kind: value.windowKind,
                        days: value.windowDays ? Number(value.windowDays) : null,
                        months: value.windowMonths ? Number(value.windowMonths) : null,
                    },
                    datetimes: {
                        effectiveFrom: value.effectiveFrom
                            ? new Date(value.effectiveFrom).toISOString()
                            : null,
                        effectiveUntil: value.effectiveUntil
                            ? new Date(value.effectiveUntil).toISOString()
                            : null,
                    },
                },
                idempotencyKey,
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    };

    const handleChangeClick = () => {
        reset();
        handleReset();
        // idempotency key should be renewed on change data click
        handleForm({ idempotencyKey: generateIdempotencyKey() });
    };

    const handleOnSubmitClick = async (value: SpendingConfigurationStepFormValues) => {
        handleForm({
            velocity: {
                spendingLimit: {
                    amount: value.amount,
                    currency: value.currency,
                },
            },
            window: {
                kind: value.windowKind,
                days: value.windowDays ? Number(value.windowDays) : null,
                months: value.windowMonths ? Number(value.windowMonths) : null,
            },
            datetimes: {
                effectiveFrom: value.effectiveFrom
                    ? new Date(value.effectiveFrom).toISOString()
                    : '',
                effectiveUntil: value.effectiveUntil
                    ? new Date(value.effectiveUntil).toISOString()
                    : '',
            },
        });

        await await handleFormSubmit(value);
    };

    const handleSubmitAgainClick = async () => {
        await handleFormSubmit({
            amount: velocity.spendingLimit.amount,
            currency: velocity.spendingLimit.currency,
            windowKind: window.kind,
            windowMonths: window.months ? String(window.months) : null,
            windowDays: window.days ? String(window.days) : null,
            effectiveFrom: datetimes?.effectiveFrom ? new Date(datetimes?.effectiveFrom) : null,
            effectiveUntil: datetimes?.effectiveUntil ? new Date(datetimes?.effectiveUntil) : null,
        });
    };

    const href = interpolateParams(CARD_SPENDING_CONTROL_DETAILS_ROUTE, { id });

    const errorMessage = editSCLang.GET_FAILED(label || '');

    return (
        <>
            {isSuccess && (
                <SuccessMessage
                    pre={editSCLang.GET_SUCCESS_PRE(label || '')}
                    post={editSCLang.SUCCESS_POST}
                    content={<Link href={href}>{id}</Link>}
                />
            )}
            {!!error && (
                <ErrorMessage
                    error={error}
                    message={errorMessage}
                    isLoading={isLoading}
                    onFormSubmit={handleSubmitAgainClick}
                    onChangeClick={handleChangeClick}
                />
            )}
            {!isSuccess && !error && (
                <>
                    <Box sx={{ mb: '40px' }}>
                        <Stepper activeStep={activeStep} steps={steps} />
                    </Box>
                    <WhiteBackgroundWrapper sx={{ p: '24px' }}>
                        {activeStep === 0 && (
                            <BasicDetailsStep
                                mode="edit"
                                handleNextStep={handleNext}
                                handleForm={handleForm}
                                defaultValues={{
                                    label: label || '',
                                    targetId: target.id,
                                    targetKind: target.kind,
                                    scope,
                                    tenantId,
                                }}
                            />
                        )}
                        {activeStep === 1 && (
                            <SpendingConfigurationStep
                                defaultValues={{
                                    amount: velocity.spendingLimit.amount,
                                    currency: velocity.spendingLimit.currency,
                                    windowKind: window.kind,
                                    effectiveFrom: datetimes?.effectiveFrom
                                        ? new Date(datetimes?.effectiveFrom)
                                        : null,
                                    effectiveUntil: datetimes?.effectiveUntil
                                        ? new Date(datetimes?.effectiveUntil)
                                        : null,
                                    windowDays: window.days ? String(window.days) : '',
                                    windowMonths: window.months ? String(window.months) : '',
                                }}
                                handleBack={handleBack}
                                onSubmitClick={handleOnSubmitClick}
                            />
                        )}
                    </WhiteBackgroundWrapper>
                </>
            )}
        </>
    );
}
