import { Accordion, AccordionTitle, TextWithCopyIcon, TextWithCopyIconContent } from '@dock/react';
import { Box, Grid, Typography } from '@mui/material';
import type { GetCardData } from '@dock/types-dock-partner';
import cardDetailsLang from '../../../lang/cardDetails';

type PersonalisationAccordionProps = Pick<GetCardData, 'personalization'>;

export function PersonalisationAccordion({ personalization }: PersonalisationAccordionProps) {
    return (
        <Box sx={{ position: 'relative' }}>
            <Accordion
                title={cardDetailsLang.CARD_ACCORDION.PERSONALISATION_AND_OTHER_DETAILS}
                testId="personalisationAccordion"
            >
                <Grid container rowSpacing="20px">
                    <Grid item xs={4}>
                        <AccordionTitle content={cardDetailsLang.CARD_ACCORDION.LINE_2} />
                        <Typography variant="h3" data-testid="line2Value">
                            {personalization.line2 || '-'}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <AccordionTitle content={cardDetailsLang.CARD_ACCORDION.LINE_3} />
                        <Typography variant="h3" data-testid="line3Value">
                            {personalization.line3 || '-'}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <AccordionTitle content={cardDetailsLang.CARD_ACCORDION.QR_CODE_CONTENT} />

                        {personalization.qrCodeContent ? (
                            <TextWithCopyIcon text={personalization.qrCodeContent}>
                                <TextWithCopyIconContent
                                    text={personalization.qrCodeContent}
                                    ellipsis="end"
                                    variant="h4"
                                />
                            </TextWithCopyIcon>
                        ) : (
                            <Typography variant="h3" data-testid="qrCodeContentValue">
                                -
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Accordion>
        </Box>
    );
}
