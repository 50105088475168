import {
    ACCOUNTS_LIST_KEY,
    ACCOUNT_TXS_DETAILS_KEY,
    ACCOUNT_TXS_DETAILS_EXPORT_KEY,
} from '../../keys';
import { useInfiniteQueryWith } from './useInfiniteQueryWith';
import { fetchAccountTransactionsWithCounterparty } from '../methods/fetchAccountTransactionsWithCounterparty';
import { fetchAccountsList } from '../../api';

export const useAccountsList = useInfiniteQueryWith({
    fetcher: fetchAccountsList,
    queryKey: ACCOUNTS_LIST_KEY,
});

const useAccountDetailsTxsMain = (key: string) =>
    useInfiniteQueryWith({
        fetcher: fetchAccountTransactionsWithCounterparty,
        queryKey: key,
    });

export const useAccountDetailsTxs = useAccountDetailsTxsMain(ACCOUNT_TXS_DETAILS_KEY);

export const useExportAccountDetailsTxs = useAccountDetailsTxsMain(ACCOUNT_TXS_DETAILS_EXPORT_KEY);
