import type { BankTransferHolder } from '@dock/types-dock-partner';
import { flow } from 'fp-ts/lib/function';
import { getData, RequestAndUnwrap, requestFlow, unwrapService } from '../../../../utilities';
import { handleErrors } from '../../../../errorService';

export const createBankTransferHolderHandler =
    requestFlow(handleErrors)(`v0/bank_transfers/holders`);

const fetchAndHandle = createBankTransferHolderHandler<BankTransferHolder>(getData);

export const createBankTransferAccountHolder: RequestAndUnwrap<'post', BankTransferHolder> = flow(
    fetchAndHandle,
    unwrapService<BankTransferHolder>
);
