import { useMutation } from '@tanstack/react-query';
import {
    A2ATransfersUpdateInstrumentStatus,
    UpdateInstrumentStatusData,
} from '@dock/types-dock-partner';

import { queryClient } from '../../client';
import { A2A_LIST_KEY } from '../../keys';
import { updateTokensOnMutate } from '../updateTokensOnMutate';
import { changeA2AStatus } from '../methods/a2a/changeA2AStatus';
import { PartnerServiceError } from '../../../common/PartnerServiceError';

export function useChangeA2AStatus(id: string) {
    const { isLoading, data, isError, error, mutateAsync } = useMutation<
        UpdateInstrumentStatusData,
        PartnerServiceError,
        A2ATransfersUpdateInstrumentStatus
    >((payload) => changeA2AStatus({ payload, id }), {
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [A2A_LIST_KEY] });
        },
        onMutate: updateTokensOnMutate,
    });

    return {
        data,
        isLoading,
        isError,
        error,
        mutateAsync,
    };
}
