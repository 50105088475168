import { ListItemText, Typography } from '@mui/material';

type SidebarItemTextProps = {
    label: string;
    shouldHideText?: boolean;
};

export function SidebarItemText({ label, shouldHideText = false }: SidebarItemTextProps) {
    return (
        <ListItemText
            sx={{
                color: 'background.default',
                display: shouldHideText ? 'none' : 'block',
            }}
            primary={<Typography variant="body1">{label}</Typography>}
        />
    );
}
