import { MenuItem as MuiMenuItem } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { NavbarRoutes } from '../types';
import { SidebarButtonItem } from '../SidebarButtonItem';

type MenuItemProps = {
    item: NavbarRoutes;
    handleClose: () => void;
};

export function MenuItem({ handleClose, item }: MenuItemProps) {
    if (item.isVisible && !item.isVisible()) {
        return null;
    }

    const { pathname } = useLocation();

    const isCurrentItem = pathname === item.route;

    return (
        <MuiMenuItem
            onClick={(e) => (item.isDisabled ? e.preventDefault() : handleClose)}
            sx={{
                padding: '0',
                pointerEvents: item.isDisabled ? 'none' : 'auto',
            }}
        >
            <NavLink
                key={item.name}
                to={item.route}
                onClick={(e) => item.isDisabled && e.preventDefault()}
                style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    pointerEvents: item.isDisabled ? 'none' : 'auto',
                    width: '100%',
                }}
            >
                {({ isActive }) => (
                    <SidebarButtonItem item={item} isActive={isCurrentItem || isActive} />
                )}
            </NavLink>
        </MuiMenuItem>
    );
}
