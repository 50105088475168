import React from 'react';
import { Box } from '@mui/material';
import { filterWrapperStyles } from './styles';

type TableFiltersWrapperProps = {
    children: React.ReactNode;
};

export function TableFiltersWrapper({ children }: TableFiltersWrapperProps) {
    return <Box sx={{ ...filterWrapperStyles, mx: 3, my: 4 }}>{children}</Box>;
}
