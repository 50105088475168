import TableCell from '@mui/material/TableCell';
import { transformDate } from '@dock/react-util';
import type { A2ATransfersPayment } from '@dock/types-dock-partner';
import {
    ActionMenuItemProps,
    ActionsMenu,
    AmountCell,
    CollapsableTableElement,
    NavLink,
    TextWithCopyIcon,
    useBoolean,
    ViewDetailsLink,
    ExpandRow,
} from '@dock/react';
import { useParams } from 'react-router-dom';
import {
    CustomVirtualisationWrapper,
    TablesTypography,
    LinkWithCopyIcon,
    interpolateParams,
    A2A_DETAILS_SCHEDULE_PAYMENTS_DETAILS_ROUTE,
    A2A_DETAILS_TRANSACTIONS_ROUTE,
    ENV_VARS,
} from '../../../../common';
import { collapsableRowColumns } from './a2aSchedulePaymentTableColumns';
import { A2aTransferTxScheduleCollapsableRow } from './A2aSchedulePaymentCollapsableRow';

export type A2ATransactionsTableRowProps = Pick<
    A2ATransfersPayment,
    'id' | 'amount' | 'datetimes' | 'externalReference' | 'description' | 'recipientId'
>;

export function A2ATransactionsScheduleTableRow({
    id,
    amount,
    datetimes,
    externalReference,
    recipientId,
    description,
}: A2ATransactionsTableRowProps) {
    const [isOpen, handleRow] = useBoolean(false);

    const createdDate = transformDate(new Date(datetimes.created));

    const { id: a2aId = '' } = useParams();
    const paymentDetailsLink = interpolateParams(A2A_DETAILS_SCHEDULE_PAYMENTS_DETAILS_ROUTE, {
        id: a2aId,
        transactionId: id,
    });

    const TableActions: ActionMenuItemProps[] = [
        {
            content: <NavLink to={paymentDetailsLink} label={<ViewDetailsLink />} />,
            id: '0',
        },
    ];

    const recipientLink = interpolateParams(A2A_DETAILS_TRANSACTIONS_ROUTE, {
        id: recipientId,
    });

    return (
        <>
            <CustomVirtualisationWrapper
                index={id}
                testId="a2aSchedulePaymentTableRow"
                isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
            >
                <TableCell>
                    <ExpandRow isOpen={isOpen} onClick={handleRow} />
                </TableCell>
                <TableCell data-testid="a2aSchedulePaymentId">
                    <TextWithCopyIcon text={id}>
                        <LinkWithCopyIcon
                            testId="contentCopyText"
                            href={paymentDetailsLink}
                            ellipsis="start"
                            text={id}
                        />
                    </TextWithCopyIcon>
                </TableCell>
                <TableCell data-testid="a2aRecipient">
                    <TextWithCopyIcon text={recipientId}>
                        <LinkWithCopyIcon
                            testId="contentCopyText"
                            href={recipientLink}
                            ellipsis="start"
                            text={recipientId}
                        />
                    </TextWithCopyIcon>
                </TableCell>
                <AmountCell amount={amount.amount} />
                <TableCell>
                    <TablesTypography content={amount.currency} />
                </TableCell>
                <TableCell data-testid="createdDate">
                    <TablesTypography content={createdDate} />
                </TableCell>
                <TableCell>
                    <ActionsMenu menuItems={TableActions} />
                </TableCell>
            </CustomVirtualisationWrapper>

            <CollapsableTableElement
                isOpen={isOpen}
                columns={collapsableRowColumns}
                RowComponent={
                    <A2aTransferTxScheduleCollapsableRow
                        externalRef={externalReference}
                        description={description || ''}
                    />
                }
            />
        </>
    );
}
