import { Box, Link } from '@mui/material';
import { Stepper, WhiteBackgroundWrapper, PageContainer } from '@dock/react';
import {
    CardsCreateVelocityControl,
    CardsVelocityControlScope,
    CardsVelocityControlTargetKind,
    CardsVelocityControlWindowKind,
} from '@dock/types-dock-partner';
import { Currency } from '@dock/types-common';
import { getStepsConfig } from './getStepsConfig';
import {
    useCreatePage,
    BreadcrumbsWrapper,
    generateIdempotencyKey,
    interpolateParams,
    CARD_SPENDING_CONTROL_DETAILS_ROUTE,
} from '../../../common';
import { SpendingConfigurationStep } from './components/SpendingConfigurationStep';
import { BasicDetailsStep } from './components/BasicDetailsStep';
import { SCSummaryStep } from './components/SCSummaryStep';
import { useCreateCardVC } from '../../../services';
import { SuccessMessage } from './components/SuccessMessage';
import { CreateCardVCFormType } from './types';
import { ErrorMessage } from './components/ErrorMessage';
import createCardSCLang from '../../../lang/createCardSC';

export function CreateCardSpendingControlPage() {
    const defaultValues: CreateCardVCFormType = {
        label: null,
        tenantId: '',
        target: {
            kind: CardsVelocityControlTargetKind.CARD,
            id: '',
        },
        scope: CardsVelocityControlScope.INDIVIDUAL,
        velocity: {
            spendingLimit: {
                amount: '',
                currency: Currency.EUR,
            },
        },
        window: {
            kind: null as unknown as CardsVelocityControlWindowKind,
            months: null,
            days: null,
        },
        datetimes: {
            effectiveFrom: null,
            effectiveUntil: null,
        },
        idempotencyKey: generateIdempotencyKey(),
    };

    const steps = getStepsConfig();
    const { formValues, handleForm, stepper } = useCreatePage<CreateCardVCFormType>({
        defaultValues,
    });
    const { activeStep, handleBack, handleNext, handleReset } = stepper;
    const { mutateAsync, error, reset, isLoading, isSuccess, data } = useCreateCardVC();
    const { label, tenantId, target, velocity, window, datetimes, scope, idempotencyKey } =
        formValues;

    const getPayload = (): CardsCreateVelocityControl => ({
        label: label || '',
        tenantId,
        target,
        velocity,
        window,
        scope,
        datetimes: {
            effectiveFrom: datetimes?.effectiveFrom || null,
            effectiveUntil: datetimes?.effectiveUntil || null,
        },
    });

    const handleFormSubmit = async () => {
        try {
            await mutateAsync({
                payload: getPayload(),
                idempotencyKey,
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    };

    const handleChangeClick = () => {
        reset();
        handleReset();
        // idempotency key should be renewed on change data click
        handleForm({ idempotencyKey: generateIdempotencyKey() });
    };

    const href = interpolateParams(CARD_SPENDING_CONTROL_DETAILS_ROUTE, {
        id: data?.id || '',
    });

    const errorMessage = createCardSCLang.GET_FAILED(label || '');

    return (
        <PageContainer testId="createSCPage">
            <BreadcrumbsWrapper />
            {isSuccess && (
                <SuccessMessage
                    pre={createCardSCLang.GET_SUCCESS_PRE(label || '')}
                    post={createCardSCLang.SUCCESS_POST}
                    content={<Link href={href}>{data?.id || ''}</Link>}
                />
            )}
            {!!error && (
                <ErrorMessage
                    error={error}
                    message={errorMessage}
                    isLoading={isLoading}
                    onFormSubmit={handleFormSubmit}
                    onChangeClick={handleChangeClick}
                />
            )}
            {!isSuccess && !error && (
                <>
                    <Box sx={{ mb: '40px' }}>
                        <Stepper activeStep={activeStep} steps={steps} />
                    </Box>
                    <WhiteBackgroundWrapper sx={{ p: '24px' }}>
                        {activeStep === 0 && (
                            <BasicDetailsStep
                                handleNextStep={handleNext}
                                handleForm={handleForm}
                                defaultValues={{
                                    targetKind: target.kind,
                                    targetId: target.id,
                                    tenantId,
                                    label: label || '',
                                    scope,
                                }}
                            />
                        )}
                        {activeStep === 1 && (
                            <SpendingConfigurationStep
                                defaultValues={{
                                    amount: velocity.spendingLimit.amount,
                                    currency: velocity.spendingLimit.currency,
                                    windowKind: window.kind,
                                    effectiveFrom: datetimes?.effectiveFrom
                                        ? new Date(datetimes?.effectiveFrom)
                                        : null,
                                    effectiveUntil: datetimes?.effectiveUntil
                                        ? new Date(datetimes?.effectiveUntil)
                                        : null,
                                    windowDays: window.days ? String(window.days) : '',
                                    windowMonths: window.months ? String(window.months) : '',
                                }}
                                handleBack={handleBack}
                                handleNextStep={handleNext}
                                handleForm={handleForm}
                            />
                        )}
                        {activeStep === 2 && (
                            <SCSummaryStep
                                handleBack={handleBack}
                                handleFormSubmit={handleFormSubmit}
                                formValues={formValues}
                                isLoading={isLoading}
                            />
                        )}
                    </WhiteBackgroundWrapper>
                </>
            )}
        </PageContainer>
    );
}
