import { FilterModalButtons, FilterModalWrapper } from '@dock/react';
import type {
    CardsMethod,
    CardsTransactionStatus,
    CardsTransactionKind,
} from '@dock/types-dock-partner';
import { FormControl } from '@mui/material';
import { CardTxsPopUpFilterParams } from './useCardTransactionsFilters';
import { transactionMethodsMap, transactionStatusesMap, transactionKindMap } from '../../mappers';
import commonLang from '../../../lang/common';
import cardsLang from '../../../lang/cards';
import { AmountInputRange } from './AmountInputRange';
import { CheckItems, generateKeys, useCheckItems } from '../CheckBoxItems';
import { SimpleDialogProps } from './filterModels';
import { useAmounts } from './useAmounts';

const transactionMethodKeys = generateKeys(transactionMethodsMap);

const transactionStatusKeys = generateKeys(transactionStatusesMap);

const transactionKindKeys = generateKeys(transactionKindMap);

export function CardTransactionsFilters({
    isOpen,
    handleClose,
    fields,
    onApply,
}: SimpleDialogProps<CardTxsPopUpFilterParams>) {
    const [{ minAmount, maxAmount }, setAmount, handleAmountChange] = useAmounts({
        minAmount: fields.minAmount,
        maxAmount: fields.maxAmount,
    });

    const [status, setStatus, onStatusCheck] = useCheckItems<CardsTransactionStatus>(
        fields.status || []
    );

    const [sourceMethod, setSource, onSourceCheck] = useCheckItems<CardsMethod>(
        fields.sourceMethod || []
    );

    const [kind, setKind, onKindCheck] = useCheckItems<CardsTransactionKind>(fields.kind || []);

    const handleApply = () => {
        onApply({
            ...fields,
            status,
            kind,
            sourceMethod,
            minAmount: minAmount || '',
            maxAmount: maxAmount || '',
        });
        handleClose();
    };

    const handleReset = () => {
        setStatus([]);
        setKind([]);
        setSource([]);
        setAmount({ minAmount: '', maxAmount: '' });
        onApply({
            status: [],
            kind: [],
            sourceMethod: [],
            minAmount: '',
            maxAmount: '',
        });
        handleClose();
    };

    return (
        <FilterModalWrapper
            handleClose={handleClose}
            isOpen={isOpen}
            title={commonLang.FILTER}
            testId="cardTransactionsFilters"
        >
            <FormControl component="fieldset" variant="standard">
                <CheckItems
                    title={commonLang.STATUS}
                    items={status}
                    keys={transactionStatusKeys}
                    handleChange={onStatusCheck}
                />
            </FormControl>
            <FormControl component="fieldset" variant="standard">
                <CheckItems
                    title={cardsLang.SOURCE}
                    items={sourceMethod}
                    keys={transactionMethodKeys}
                    handleChange={onSourceCheck}
                />
            </FormControl>
            <FormControl component="fieldset" variant="standard">
                <CheckItems
                    title={cardsLang.TYPE}
                    items={kind}
                    keys={transactionKindKeys}
                    handleChange={onKindCheck}
                />
            </FormControl>
            <AmountInputRange
                label={commonLang.AMOUNT}
                maxAmount={maxAmount}
                minAmount={minAmount}
                handleChange={handleAmountChange}
                helperText={commonLang.FILTERS.AMOUNT_TEXT}
            />
            <FilterModalButtons
                applyText={commonLang.APPLY}
                resetText={commonLang.RESET_FILTERS}
                onApplyClick={handleApply}
                onResetClick={handleReset}
            />
        </FilterModalWrapper>
    );
}
