import { A2ADetails } from './A2ADetails';
import { TransactionsTable } from './components/TransactionTable/TransactionsTable';

export function A2ADetailsTransactionsTable() {
    return (
        <A2ADetails>
            <TransactionsTable />
        </A2ADetails>
    );
}
