import { Box } from '@mui/material';
import { DockLogoIcon, DockIconSmall } from '@dock/react';

type SidebarLogoProps = {
    isOpen: boolean;
};

export function SidebarLogo({ isOpen }: SidebarLogoProps) {
    return (
        <Box sx={{ marginBottom: '48px' }}>
            {isOpen ? (
                <DockLogoIcon />
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                    }}
                >
                    <DockIconSmall />
                </Box>
            )}
        </Box>
    );
}
