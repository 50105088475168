import React from 'react';
import { statusModalsContext } from './statusModalsContext';
import { StatusModalType, useProvideStatusModals } from './useProvideStatusModals';
import { SuccessStatusModal } from '../Modals/SuccessStatusModal';
import { ErrorStatusModal } from '../Modals/ErrorStatusModal';

type ProvideAuthProps = {
    children: React.ReactNode;
};

export function ProvideStatusModals({ children }: ProvideAuthProps) {
    const modals = useProvideStatusModals();
    const { notification, isOpen, closeModal, handleChange, handleSubmit } = modals;

    return (
        <statusModalsContext.Provider value={modals}>
            {notification?.type === StatusModalType.SUCCESS && (
                <SuccessStatusModal
                    statusMessages={notification.messages}
                    isOpen={isOpen}
                    handleClose={closeModal}
                />
            )}
            {notification?.type === StatusModalType.ERROR && (
                <ErrorStatusModal
                    statusMessages={notification.messages}
                    isOpen={isOpen}
                    handleClose={closeModal}
                    onChangeClick={handleChange}
                    onSubmitAgainClick={handleSubmit}
                />
            )}
            {children}
        </statusModalsContext.Provider>
    );
}
