import { useMutation } from '@tanstack/react-query';
import type { DockError } from '@dock/common';
import type {
    CardsChangeCardStatusResponse,
    CardsChangeCardStatusRequest,
} from '@dock/types-dock-partner';
import { changeCardStatus } from '../methods/cards';
import { queryClient } from '../../client';
import { CARD_DETAILS_KEY } from '../../keys';
import { updateTokensOnMutate } from '../updateTokensOnMutate';

export function useChangeCardStatus(cardId: string | undefined) {
    const { isLoading, data, isError, error, mutateAsync } = useMutation<
        CardsChangeCardStatusResponse,
        DockError,
        CardsChangeCardStatusRequest
    >((payload) => changeCardStatus(cardId, payload), {
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [CARD_DETAILS_KEY],
            });
        },
        onMutate: updateTokensOnMutate,
    });

    return {
        data,
        isLoading,
        isError,
        error,
        changeCardStatusMutation: mutateAsync,
    };
}
