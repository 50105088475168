import {
    SimpleRow,
    CollapsableElement,
    useBoolean,
    TextWithCopyIcon,
    AmountSimpleRow,
} from '@dock/react';
import { Grid, Paper, Typography } from '@mui/material';
import { transformDate } from '@dock/react-util';
import { CardTransactionDetailTableProps, useA2ATransactionsDetails } from '../../../services';
import {
    transactionDetailColumns,
    transactionCounterPartyColumns,
} from './A2ATransactionDetailColumns';
import {
    StatusChip,
    LinkWithCopyIcon,
    A2A_DETAILS_SCHEDULE_PAYMENTS_DETAILS_ROUTE,
    A2A_DETAILS_TRANSACTIONS_ROUTE,
    ACCOUNT_DETAILS_TRANSACTIONS_ROUTE,
    interpolateParams,
} from '../../../common';
import lang from '../../../lang/a2aTransactionsDetails';
import { a2aTransferTXKindColorMap } from './a2aTransferTXKindColorMap';
import { a2aTransferTransactionKindMap } from './a2aTransferTransactionKindMap';
import { transactionColumns } from '../../A2APaymentsDetails/components/A2APaymentsDetailColumns';

const detailColumnsKeys = Object.keys(transactionDetailColumns);
const counterPartyColumnsKeys = Object.keys(transactionCounterPartyColumns);

export function A2ATransactionDetailTable({
    transactionId,
    a2aId,
}: CardTransactionDetailTableProps & { a2aId: string }) {
    //* hooks
    const [isDetailCollapsed, flipDetailCollapsable] = useBoolean(true);
    const [isCounterPartyCollapsed, flipCounterPartyCollapsable] = useBoolean(true);
    const [isPartyCollapsed, flipPartyCollapsable] = useBoolean(true);
    //* Data
    const { data, isFetching, accountCounterPartyName, accountPartyName } =
        useA2ATransactionsDetails({
            transactionId,
        });

    const { counterparty, party, amount, datetimes, ...details } = data;
    const a2aIdLInk = interpolateParams(A2A_DETAILS_SCHEDULE_PAYMENTS_DETAILS_ROUTE, {
        id: a2aId,
        transactionId: details?.paymentId,
    });
    const partyAccountLInk = interpolateParams(ACCOUNT_DETAILS_TRANSACTIONS_ROUTE, {
        id: party.accountId,
    });
    const partyInstrumentLInk = interpolateParams(A2A_DETAILS_TRANSACTIONS_ROUTE, {
        id: party.instrumentId,
    });
    const counterPartyAccountLInk = interpolateParams(ACCOUNT_DETAILS_TRANSACTIONS_ROUTE, {
        id: counterparty.accountId,
    });
    const counterPartyInstrumentLInk = interpolateParams(A2A_DETAILS_TRANSACTIONS_ROUTE, {
        id: counterparty.instrumentId,
    });
    return (
        <>
            <Paper sx={{ mb: '40px' }}>
                <CollapsableElement
                    isCollapsed={isDetailCollapsed}
                    title={lang.TRANSACTIONS_TABLE.PAYMENT_DETAILS}
                    flip={flipDetailCollapsable}
                    columns={detailColumnsKeys.length}
                    isLoading={isFetching}
                >
                    <SimpleRow
                        label={transactionDetailColumns.id}
                        value={
                            <TextWithCopyIcon text={details?.id}>
                                <Typography>{details?.id}</Typography>
                            </TextWithCopyIcon>
                        }
                    />
                    {details?.kind && (
                        <SimpleRow
                            label={transactionDetailColumns.kind}
                            value={
                                <StatusChip
                                    color={a2aTransferTXKindColorMap[details?.kind]}
                                    label={
                                        a2aTransferTransactionKindMap[details?.kind] ||
                                        details?.kind
                                    }
                                />
                            }
                        />
                    )}
                    <AmountSimpleRow
                        label={transactionDetailColumns.amount}
                        amount={amount.amount}
                        currency={amount.currency}
                    />
                    <SimpleRow
                        label={transactionDetailColumns.datetimes}
                        value={
                            datetimes?.created === '-'
                                ? '-'
                                : transformDate(new Date(datetimes?.created))
                        }
                    />
                    <SimpleRow
                        label={transactionDetailColumns.externalReference}
                        value={details?.externalReference ?? '-'}
                    />
                    <SimpleRow
                        label={transactionDetailColumns.paymentId}
                        value={
                            <TextWithCopyIcon text={details?.paymentId}>
                                <LinkWithCopyIcon href={a2aIdLInk} text={details?.paymentId} />
                            </TextWithCopyIcon>
                        }
                    />
                    <SimpleRow
                        label={transactionDetailColumns.description}
                        value={details?.description ?? '-'}
                    />
                </CollapsableElement>
            </Paper>
            <Grid sx={{ mb: '40px' }} container spacing="16px">
                <Grid item xs={6}>
                    <Paper sx={{ my: '16px' }}>
                        <CollapsableElement
                            isCollapsed={isCounterPartyCollapsed}
                            title={lang.TRANSACTIONS_TABLE.COUNTER_PARTY}
                            flip={flipCounterPartyCollapsable}
                            columns={counterPartyColumnsKeys.length}
                            isLoading={isFetching}
                        >
                            <SimpleRow
                                label={transactionCounterPartyColumns.accountCounterPartyName}
                                value={accountCounterPartyName ?? '-'}
                            />
                            <SimpleRow
                                label={transactionColumns.accountId}
                                value={
                                    <TextWithCopyIcon text={counterparty.accountId}>
                                        <LinkWithCopyIcon
                                            href={counterPartyAccountLInk}
                                            text={counterparty.accountId}
                                        />
                                    </TextWithCopyIcon>
                                }
                            />
                            <SimpleRow
                                label={transactionColumns.instrumentId}
                                value={
                                    <TextWithCopyIcon text={counterparty.instrumentId}>
                                        <LinkWithCopyIcon
                                            href={counterPartyInstrumentLInk}
                                            text={counterparty.instrumentId}
                                        />
                                    </TextWithCopyIcon>
                                }
                            />
                        </CollapsableElement>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={{ my: '16px' }}>
                        <CollapsableElement
                            isCollapsed={isPartyCollapsed}
                            title={lang.TRANSACTIONS_TABLE.PARTY}
                            flip={flipPartyCollapsable}
                            columns={counterPartyColumnsKeys.length}
                            isLoading={isFetching}
                        >
                            <SimpleRow
                                label={transactionCounterPartyColumns.accountPartyName}
                                value={accountPartyName ?? '-'}
                            />
                            <SimpleRow
                                label={transactionCounterPartyColumns.accountId}
                                value={
                                    <TextWithCopyIcon text={party.accountId}>
                                        <LinkWithCopyIcon
                                            href={partyAccountLInk}
                                            text={party.accountId}
                                        />
                                    </TextWithCopyIcon>
                                }
                            />
                            <SimpleRow
                                label={transactionColumns.instrumentId}
                                value={
                                    <TextWithCopyIcon text={party.instrumentId}>
                                        <LinkWithCopyIcon
                                            href={partyInstrumentLInk}
                                            text={party.instrumentId}
                                        />
                                    </TextWithCopyIcon>
                                }
                            />
                        </CollapsableElement>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
