import { createContext } from 'react';
import { UseProvideStatusModalsType } from './useProvideStatusModals';

const defaultValue: UseProvideStatusModalsType = {
    notification: { type: null, messages: [''] },
    isOpen: false,
    showSuccessModal: () => {},
    showErrorModal: () => {},
    closeModal: () => {},
    handleSubmit: () => {},
    handleChange: () => {},
};

export const statusModalsContext = createContext<UseProvideStatusModalsType>(defaultValue);
