import { OptionType } from '@dock/react';
import { CardsVelocityControlScope } from '@dock/types-dock-partner';
import { cardsVCScopeMap } from '../../../../common';

export const scopeOptions: OptionType<CardsVelocityControlScope>[] = [
    {
        label: cardsVCScopeMap[CardsVelocityControlScope.INDIVIDUAL],
        value: CardsVelocityControlScope.INDIVIDUAL,
    },
];
