import { useMutation } from '@tanstack/react-query';

import type { PayloadWithIdempotencyKey } from '@dock/types-common';
import type { CreateInstrumentData, A2ATransfersCreateInstrument } from '@dock/types-dock-partner';
import { createA2A } from '../methods/a2a';
import { queryClient } from '../../client';
import { A2A_LIST_KEY } from '../../keys';
import { updateTokensOnMutate } from '../updateTokensOnMutate';
import { PartnerServiceError } from '../../../common/PartnerServiceError';

export function useCreateA2ATransfer() {
    const { isLoading, data, isError, mutateAsync, isSuccess, error } = useMutation<
        CreateInstrumentData,
        PartnerServiceError,
        PayloadWithIdempotencyKey<A2ATransfersCreateInstrument>
    >((variables) => createA2A(variables.payload, variables.idempotencyKey), {
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [A2A_LIST_KEY],
            });
        },
        onMutate: updateTokensOnMutate,
    });

    return {
        data,
        isLoading,
        error,
        mutateAsync,
        isSuccess,
        isError,
    };
}
